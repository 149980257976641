import styles from "./SideMenu.module.css";
import { Paper } from "@mui/material";

interface SideMenuProps {
  title?: string;
  children?: React.ReactNode;
}

export default function SideMenu(props: SideMenuProps) {
  return (
    <Paper className={styles.sideMenuPaper}>
      {props.title && <div className={styles.title}>{props.title}</div>}
      {props.children && <div className={styles.content}>{props.children}</div>}
    </Paper>
  );
}
