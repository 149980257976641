import { useDispatch, useSelector } from "react-redux";
import {
  closeDialog,
  openDialog,
  showRequiredFields,
} from "../../../../Store/Appearance/Actions";
import { CustomRaisedButton } from "../../../../Components/CustomComponents/CustomButtons";
import { shouldSaveNewOrder } from "./ShouldSaveNewOrderDecider";
import {
  getSelectedOrganization,
  isSelectedOrganization,
} from "../../../../Store/SelectedOrganization/Selectors";
import { isOrderMissingFields } from "../../../../Store/Appearance/RequiredFields/RequiredFieldsSelectors";
import { saveNewOrganization } from "../../../../Store/SelectedOrganization/Actions";
import { saveNewOrder } from "../../../../Store/SelectedOrder/Actions";
import { getOrderPath } from "../../../../Pages/Path";
import { useNavigate } from "react-router";
import SaveIcon from "@mui/icons-material/Save";
import { selectedOrderHasUnsavedChanges } from "../../../../Store/SelectedOrder/Selectors";

export default function SaveOrderContainer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const organizationSelectd = useSelector(isSelectedOrganization);
  const selectedOrganization = useSelector(getSelectedOrganization);
  const orderMissingFields = useSelector(isOrderMissingFields);
  const orderHasUnsavedChanges = useSelector(selectedOrderHasUnsavedChanges);

  const saveOrder = () =>
    dispatch(saveNewOrder((id) => navigate(getOrderPath(id))));

  const onClick = () => {
    const shouldSave = shouldSaveNewOrder(
      organizationSelectd,
      selectedOrganization,
      (title: string, content: string, actions?: React.ReactNode[]) =>
        dispatch(openDialog(title, content, actions)),
      () => dispatch(closeDialog()),
      orderMissingFields,
      () => dispatch(showRequiredFields()),
      () => dispatch(saveNewOrganization()),
      saveOrder
    );

    if (!shouldSave) return;

    saveOrder();
  };

  return (
    <CustomRaisedButton
      onClick={onClick}
      label="שמור"
      icon={<SaveIcon />}
      variant={orderHasUnsavedChanges ? "contained" : "outlined"}
      fullWidth
    />
  );
}
