import { connect } from "react-redux";
import { updateSelectedOrder } from "../../../../Store/SelectedOrder/Actions";
import { getSelectedOrganization } from "../../../../Store/SelectedOrganization/Selectors";
import ProformaInvoiceDate from "./ProformaInvoiceDate";
import calculatePayDate from "./ProformaInvoiceDateCalculator";
import { IDispatch, IState } from "../../../../Interfaces/ReduxInterfaces";
import { OrderField } from "../../../../Store/Appearance/RequiredFields/FieldNames";

function mapStateToProps(state: IState) {
  return {
    selectedPaymentConditions: getSelectedOrganization(state).paymentConditions,
  };
}

function mapDispatchToProps(dispatch: IDispatch) {
  return {
    onChangeProformaInvoiceDate: (value: string) =>
      dispatch(updateSelectedOrder(OrderField.proformaInvoiceDate, value)),
    updateSelectedOrder: (key: OrderField, value: any) =>
      dispatch(updateSelectedOrder(key, value)),
    calculatePayDate: function (
      proformaInvoiceValue: string,
      selectedPaymentConditions: string
    ) {
      const payDate = calculatePayDate(
        proformaInvoiceValue,
        selectedPaymentConditions
      );
      if (payDate !== null)
        dispatch(updateSelectedOrder(OrderField.expectedPayDate, payDate));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProformaInvoiceDate);
