import { useSelector } from "react-redux";
import { getActionRequiredOrders } from "../Store/Orders/Selectors";
import CustomPaperTable from "../Components/Table/CustomPaperTable";
import Screen from "../Components/Screen";
import SideMenu from "../Components/SideMenu";
import { getOrderPath, Path } from "./Path";
import {
  ActionRequiredCase,
  ALL_ACTION_REQUIRED_CASES,
  getActionRequiredCaseLabel,
  IActionRequiredOrder,
} from "../Store/Orders/ActionRequiredOrderes";
import { useNavigate, useParams } from "react-router";
import Checkbox from "../Components/Checkbox";

export default function ActionRequiredPage() {
  const navigate = useNavigate();
  const { filters } = useParams<{ filters?: string }>();

  const selectedCasesArray = !filters
    ? []
    : (filters
        .split(",")
        .map((numberString) => {
          const res = ALL_ACTION_REQUIRED_CASES.filter(
            (x) => x === numberString
          );
          if (res.length > 0) {
            return res[0] as ActionRequiredCase;
          }
          return null;
        })
        .filter((x) => x !== null) as ActionRequiredCase[]);

  function CaseCheckbox(props: { case: ActionRequiredCase }) {
    return (
      <Checkbox
        label={getActionRequiredCaseLabel(props.case)}
        value={selectedCasesArray.includes(props.case)}
        onChange={(selected) => {
          let path: string;
          if (selected) {
            path = filters ? filters + "," + props.case : props.case;
          } else {
            path = selectedCasesArray.filter((c) => c !== props.case).join(",");
          }
          navigate(Path.ActionRequired + "/" + path);
        }}
      />
    );
  }

  const actionRequiredOrders = useSelector(getActionRequiredOrders).filter(
    (summary) => selectedCasesArray.includes(summary.issueCase)
  );

  const sideMenu = (
    <SideMenu title="סינון לפי התראה:">
      {ALL_ACTION_REQUIRED_CASES.map((actionCase) => (
        <CaseCheckbox case={actionCase} key={actionCase} />
      ))}
      <div style={{ marginTop: 20 }}>
        {"הזמנות לאחר סינון: " + actionRequiredOrders.length}
      </div>
    </SideMenu>
  );

  return (
    <Screen sideMenu={sideMenu}>
      <CustomPaperTable
        title="התראות"
        elements={actionRequiredOrders}
        tableHeaders={[
          { id: "מספר הזמנה" },
          { createdDate: "תאריך יצירת הזמנה" },
          { organizationName: "שם הארגון" },
          { status: "סטאטוס הזמנה" },
          { lectureDate: "תאריך הרצאה" },
          { issue: "התראה" },
        ]}
        onEditButton={(orderSummary: IActionRequiredOrder) => {
          navigate(getOrderPath(orderSummary.id));
        }}
      />
    </Screen>
  );
}
