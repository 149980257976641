import { useDispatch, useSelector } from "react-redux";
import CustomCheckbox from "../../../../Components/CustomComponents/CustomCheckbox";
import { OrderField } from "../../../../Store/Appearance/RequiredFields/FieldNames";
import { getOrderRequiredFields } from "../../../../Store/Appearance/RequiredFields/RequiredFieldsSelectors";
import { updateSelectedOrder } from "../../../../Store/SelectedOrder/Actions";
import { getSelectedOrder } from "../../../../Store/SelectedOrder/Selectors";

interface OrderCheckboxProps {
  field: OrderField;
  forceErrorColor?: boolean;
}
export default function OrderCheckbox(props: OrderCheckboxProps) {
  const dispatch = useDispatch();
  const selectedOrder = useSelector(getSelectedOrder);
  const requiredFields = useSelector(getOrderRequiredFields);
  return (
    <CustomCheckbox
      field={props.field}
      valueObject={selectedOrder}
      onChange={(newValue) =>
        dispatch(updateSelectedOrder(props.field, newValue))
      }
      requiredFields={requiredFields}
      forceErrorColor={props.forceErrorColor}
    />
  );
}
