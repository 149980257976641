import { updateSelectedOrder } from "../../../../../Store/SelectedOrder/Actions";
import * as _ from "lodash";
import { IOrder, IPublicCourseParticipant } from "@violet/common";
import { CustomRaisedButton } from "../../../../../Components/CustomComponents/CustomButtons";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch } from "react-redux";

export default function AddParticipantButton(props: { selectedOrder: IOrder }) {
  const dispatch = useDispatch();

  const onClick = () => {
    addNewParticipant(props.selectedOrder, (value: any) =>
      dispatch(updateSelectedOrder("publicCourseParticipants", value))
    );
  };

  return (
    <CustomRaisedButton
      label="הוסף משתתף"
      onClick={onClick}
      icon={<AddIcon />}
      variant="outlined"
      disabled={!props.selectedOrder.publicCourseId}
    />
  );
}

export function addNewParticipant(
  selectedOrder: IOrder,
  updateSelectedOrder: (value: any) => void
) {
  const publicCourseParticipants = _.hasIn(
    selectedOrder,
    "publicCourseParticipants"
  )
    ? selectedOrder.publicCourseParticipants
    : [];
  publicCourseParticipants.push({} as IPublicCourseParticipant);

  updateSelectedOrder(publicCourseParticipants);
}
