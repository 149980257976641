import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { prefixer } from "stylis";
import Colors from "./Util/Constants/Colors";

const theme = createTheme({
  direction: "rtl",
  typography: { fontFamily: ["Arimo"].join(",") },
  palette: {
    primary: {
      main: Colors.primary,
      dark: "#9D49B0",
      contrastText: "#ffffff",
    },
  },
});

const cacheRtl = createCache({
  key: "muirtl",
  // prefixer is the only stylis plugin by default, so when
  // overriding the plugins you need to include it explicitly
  // if you want to retain the auto-prefixing behavior.
  stylisPlugins: [prefixer, rtlPlugin],
});

export default function WithGlobalTheme(props: any) {
  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </CacheProvider>
  );
}
