import { useSelector } from "react-redux";
import CustomPaperTable from "../../Components/Table/CustomPaperTable";
import {
  getOrdersByOrganization,
  getOrdersSummary,
} from "../../Store/Orders/Selectors";
import { getOrderPath } from "../Path";
import { IState } from "../../Interfaces/ReduxInterfaces";
import { useNavigate } from "react-router";

export default function OrganizationsOrderTable() {
  const navigate = useNavigate();

  const elements = useSelector((state: IState) =>
    getOrdersSummary(state, getOrdersByOrganization)
  ).reverse();

  return (
    <CustomPaperTable
      title="הזמנות"
      elements={elements}
      tableHeaders={[
        { id: "מספר הזמנה" },
        { date: "תאריך הרצאה" },
        { topic: "נושא" },
        { lecturesCount: "מספר הרצאות" },
        { status: "סטאטוס" },
        { proformaInvoiceNumber: "חשבונית עסקה" },
      ]}
      onEditButton={(summary) => {
        navigate(getOrderPath(summary.id));
      }}
    />
  );
}
