import { Size } from "../../../../../Util/Constants/Size";
import _ from "lodash";
import DeleteIcon from "@mui/icons-material/Delete";
import { CustomIconButton } from "../../../../../Components/CustomComponents/CustomButtons";
import { IOrder, toPrintableDateFormat } from "@violet/common";
import CustomCheckbox from "../../../../../Components/CustomComponents/CustomCheckbox";
import { useDispatch, useSelector } from "react-redux";
import { getRequiredFieldsObject } from "../../../../../Store/Appearance/RequiredFields/RequiredFieldsSelectors";
import { getSelectedPublicCourseLectures } from "../../../../../Store/SelectedPublicCourse/Selectors";
import {
  removeParticipant,
  updatePublicCourseLectureParticipating,
  updatePublicCourseParticipant,
} from "../../../../../Store/SelectedOrder/Actions";
import IndexAvatar from "../../../../../Components/IndexAvatar";
import styles from "./PublicCourseParticipant.module.css";
import classNames from "classnames";
import { PublicCourseParticipantField } from "../../../../../Store/Appearance/RequiredFields/FieldNames";
import Checkbox from "../../../../../Components/Checkbox";
import CustomTextField from "../../../../../Components/CustomComponents/CustomTextField";
import CalculateIcon from "@mui/icons-material/LocalAtm";

interface PublicCourseParticipantProps {
  selectedOrder: IOrder;
  participantId: number;
  calculateCostOfParitipant: () => void;
}

export default function PublicCourseParticipant(
  props: PublicCourseParticipantProps
) {
  const dispatch = useDispatch();
  const participant =
    props.selectedOrder.publicCourseParticipants[props.participantId];

  const requiredFields = useSelector(getRequiredFieldsObject).publicCourse;
  const selectedPublicCourseLectures = useSelector(
    getSelectedPublicCourseLectures
  );

  const onChange =
    (key: PublicCourseParticipantField) => (value: string | boolean) =>
      dispatch(updatePublicCourseParticipant(key, value, props.participantId));

  const onLectureCheck = (lectureId: number, isAttending: boolean) =>
    dispatch(
      updatePublicCourseLectureParticipating(
        lectureId,
        isAttending,
        props.participantId
      )
    );

  const onDelete = () => dispatch(removeParticipant(props.participantId));

  return (
    <div
      className={classNames({
        [styles.participant]: true,
        [styles.borderTop]: props.participantId > 0,
      })}
    >
      <div className={styles.control}>
        <IndexAvatar index={props.participantId + 1} />

        <CustomIconButton onClick={onDelete} tooltip="מחק משתתף">
          <DeleteIcon />
        </CustomIconButton>
      </div>
      <div className={styles.participantContent}>
        <div className={styles.participantFields}>
          <CustomTextField
            field={PublicCourseParticipantField.participantFirstName}
            valueObject={participant}
            requiredFields={requiredFields}
            onChange={onChange(
              PublicCourseParticipantField.participantFirstName
            )}
          />
          <CustomTextField
            field={PublicCourseParticipantField.participantLastName}
            valueObject={participant}
            requiredFields={requiredFields}
            onChange={onChange(
              PublicCourseParticipantField.participantLastName
            )}
          />
          <CustomTextField
            field={PublicCourseParticipantField.participantEnglishFirstName}
            valueObject={participant}
            requiredFields={requiredFields}
            onChange={onChange(
              PublicCourseParticipantField.participantEnglishFirstName
            )}
          />
          <CustomTextField
            field={PublicCourseParticipantField.participantEnglishLastName}
            valueObject={participant}
            requiredFields={requiredFields}
            onChange={onChange(
              PublicCourseParticipantField.participantEnglishLastName
            )}
          />
          <CustomTextField
            field={PublicCourseParticipantField.idNumber}
            valueObject={participant}
            requiredFields={requiredFields}
            onChange={onChange(PublicCourseParticipantField.idNumber)}
          />
          <CustomTextField
            field={PublicCourseParticipantField.phone}
            valueObject={participant}
            requiredFields={requiredFields}
            onChange={onChange(PublicCourseParticipantField.phone)}
          />
          <CustomTextField
            field={PublicCourseParticipantField.email}
            valueObject={participant}
            requiredFields={requiredFields}
            onChange={onChange(PublicCourseParticipantField.email)}
            size={Size.XXL}
          />
          <CustomTextField
            field={PublicCourseParticipantField.job}
            valueObject={participant}
            requiredFields={requiredFields}
            onChange={onChange(PublicCourseParticipantField.job)}
            size={Size.XL}
          />
          <CustomIconButton
            onClick={props.calculateCostOfParitipant}
            tooltip="חשב עלות משתתף"
            style={{ marginBottom: 10, marginLeft: 10 }}
          >
            <CalculateIcon />
          </CustomIconButton>
          <CustomTextField
            field={PublicCourseParticipantField.participantCost}
            valueObject={participant}
            requiredFields={requiredFields}
            onChange={onChange(PublicCourseParticipantField.participantCost)}
          />
          <CustomCheckbox
            field={PublicCourseParticipantField.isqMember}
            valueObject={participant}
            requiredFields={requiredFields}
            onChange={onChange(PublicCourseParticipantField.isqMember)}
          />
        </div>
        <div className={styles.lectures}>
          {_.map(selectedPublicCourseLectures, (lecture) => {
            const isAttending = participant.lecturesAttending
              ? _.includes(participant.lecturesAttending, lecture.id)
              : false;
            return (
              <Checkbox
                key={props.selectedOrder.publicCourseId + "-" + lecture.id}
                value={isAttending}
                label={
                  toPrintableDateFormat(lecture.date) + " - " + lecture.topic
                }
                onChange={(value: boolean) => onLectureCheck(lecture.id, value)}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
