import OrganizationSection from "./OrganizationSection/OrganizationSectionContainer";
import ContactsSection from "./ContactSection/ContactSection";
import PaymentSection from "./Payment/PaymentSectionContainer";
import InvoiceSection from "./InvoiceSection/InvoiceSection";
import NotesSection from "./Notes/NotesSection";
import LecturesDetailsSection from "./LectureDetailsSections/LecturesDetailsSection";
import { useContext } from "react";
import MobileViewContext from "../../../Navigation/MobileViewContext";
import MobileOrderSection from "./OrganizationSection/MobileOrderSection";

export default function OrderForm() {
  const [mobile] = useContext(MobileViewContext);
  return (
    <div>
      {mobile ? (
        <MobileOrderSection />
      ) : (
        <OrganizationSection organizationPage={false} />
      )}

      <ContactsSection />

      <LecturesDetailsSection />

      <PaymentSection />

      {!mobile && <InvoiceSection />}

      <NotesSection />
    </div>
  );
}
