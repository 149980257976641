import { EntityType } from "../../Util/Constants/EntityType";
import SearchResultCard, {
  OrderSearchResult,
  OrganizationSearchResult,
  ParticipantSearchResult,
} from "./SearchResultCard";
import styles from "./SearchPage.module.css";
import { useSelector } from "react-redux";
import {
  getOrders,
  getPublicCourseParticipantsSummary,
} from "../../Store/Orders/Selectors";
import { getOrganizations } from "../../Store/Organizations/Selectors";
import TextField from "../../Components/TextField";
import { Size } from "../../Util/Constants/Size";
import CustomSection from "../../Components/CustomComponents/CustomSection";
import _ from "lodash";
import { useNavigate, useParams } from "react-router";
import { getSearchPath } from "../Path";

export default function SearchPage() {
  const navigate = useNavigate();
  const { q } = useParams();
  const searchText = decodeURI(q?.toLowerCase() || "");
  const termToSearch = searchText.trim();
  const organizations = useSelector(getOrganizations);
  const orders = useSelector(getOrders);
  const participants = useSelector(getPublicCourseParticipantsSummary);

  const filteredOrganizationIds = new Set<number>();
  const organizationNames: { [id: number]: string } = {};
  const organizationResults: OrganizationSearchResult[] = [];
  organizations.forEach((organization) => {
    organizationNames[organization.id] = organization.organizationName;

    if (
      organization.organizationName.toLowerCase().includes(termToSearch) ||
      organization.companyId?.toLowerCase().includes(termToSearch)
    ) {
      filteredOrganizationIds.add(organization.id);
      organizationResults.push({
        entityType: EntityType.organization,
        id: organization.id,
        companyId: organization.companyId,
        organizationName: organization.organizationName,
      });
    }
  });

  const orderResults = _.reverse(
    orders
      .filter(
        (order) =>
          order.id.toString().includes(termToSearch) ||
          filteredOrganizationIds.has(order.organizationId)
      )
      .map<OrderSearchResult>((order) => ({
        entityType: EntityType.order,
        id: order.id,
        organizationName: organizationNames[order.organizationId],
        status: order.status,
        firstLectureDate: "",
      }))
  );

  const participantsResult = participants
    .filter((participant) =>
      (participant.participantFirstName + " " + participant.participantLastName)
        .toLowerCase()
        .includes(termToSearch)
    )
    .map<ParticipantSearchResult>((participant) => ({
      entityType: EntityType.publicCourseParticipant,
      id: participant.orderId,
      name:
        participant.participantFirstName +
        " " +
        participant.participantLastName,
      courseName: participant.publicCourseName,
      organizationName: organizationNames[participant.organizationId],
    }));

  return (
    <div className={styles.container}>
      <CustomSection title="חיפוש מתקדם">
        <TextField
          value={searchText}
          onChange={(newSearchText) => navigate(getSearchPath(newSearchText))}
          title="חיפוש"
          singelLine
          size={Size.XXXL}
        />
      </CustomSection>

      {termToSearch && (
        <>
          <div className={styles.groupTitle}>ארגונים</div>
          <div className={styles.searchResultGroup}>
            {organizationResults.slice(0, 10).map((org) => (
              <SearchResultCard {...org} key={"org." + org.id} />
            ))}
          </div>

          <div className={styles.groupTitle}>הזמנות</div>
          <div className={styles.searchResultGroup}>
            {orderResults.slice(0, 15).map((order) => (
              <SearchResultCard {...order} key={"order." + order.id} />
            ))}
          </div>

          <div className={styles.groupTitle}>משתתפים בקורס ציבורי</div>
          <div className={styles.searchResultGroup}>
            {participantsResult.slice(0, 15).map((participant) => (
              <SearchResultCard
                {...participant}
                key={"order." + participant.id + ".par." + participant.name}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
}
