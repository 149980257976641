import React from "react";
import Paper from "@mui/material/Paper";
import styles from "./CustomPaper.module.css";
import classNames from "classnames";

export default function CustomPaper(props: CustomPaperProps) {
  return (
    <Paper className={styles.paper} onClick={props.onClick}>
      {props.title && <div className={styles.title}>{props.title}</div>}
      <div className={classNames(styles.paperContent, props.className)}>
        {props.children}
      </div>
    </Paper>
  );
}

interface CustomPaperProps {
  onClick?: () => void;
  title?: string;
  children?: React.ReactNode;
  className?: string;
}

export const flexStyle = {
  display: "flex" as "flex",
  flexDirection: "row" as "row",
  flexWrap: "wrap" as "wrap",
  alignItems: "flex-end" as "flex-end",
};
