import CustomPaperTable from "../Components/Table/CustomPaperTable";
import Screen from "../Components/Screen";
import { getPublicCoursesSummary } from "../Store/PublicCourses/Selectors";
import { getPublicCoursePath } from "./Path";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

export default function AllPublicCoursesPage() {
  const navigate = useNavigate();
  const elements = useSelector(getPublicCoursesSummary);

  return (
    <Screen>
      <CustomPaperTable
        title="כל הקורסים הציבוריים"
        elements={elements}
        limit={30}
        tableHeaders={[
          { courseName: "שם הקורס" },
          { courseLocation: "מיקום הקורס" },
          { participatnsCount: "מספר משתתפים" },
          { startDate: "תאריך התחלה" },
          { endDate: "תאריך סיום" },
          { courseIncome: "הכנסות" },
        ]}
        onEditButton={(courseSummary) => {
          navigate(getPublicCoursePath(courseSummary.id));
        }}
      />
    </Screen>
  );
}
