import { useSelector } from "react-redux";
import CustomSection from "../../../../Components/CustomComponents/CustomSection";
import PhoneField from "../../../../Components/CustomComponents/PhoneField";
import {
  OrderField,
  OrganizationField,
} from "../../../../Store/Appearance/RequiredFields/FieldNames";
import { getSelectedOrder } from "../../../../Store/SelectedOrder/Selectors";
import { getSelectedOrganization } from "../../../../Store/SelectedOrganization/Selectors";

export default function MobileOrderSection() {
  const order = useSelector(getSelectedOrder);
  const organization = useSelector(getSelectedOrganization);

  return (
    <CustomSection title={"הזמנה מספר " + order.id}>
      <div className={""}>
        <PhoneField valueObject={order} field={OrderField.status} />
        <PhoneField
          valueObject={organization}
          field={OrganizationField.organizationName}
        />
      </div>
    </CustomSection>
  );
}
