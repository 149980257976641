export * from "./types";
export * from "./Interfaces/IOrder";
export * from "./Interfaces/Status";
export * from "./Interfaces/IOrganization";
export * from "./Interfaces/IPublicCourse";
export * from "./Interfaces/IStringObject";
export * from "./Interfaces/ILectureTime";
export * from "./Interfaces/IPublicCourseParticipant";
export * from "./Util/TimeUtil";
export * from "./OrderStatus/OrderStatusCalculator";
export * from "./OrderStatus/OrderStatusUtils";

export const TRAVEL_COST_PRICE = 5.87;

export const LOGO_URL =
  "https://res.cloudinary.com/warrantykeeper/image/upload/v1620571242/logo_pu4riq.png";
