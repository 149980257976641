import { useDispatch, useSelector } from "react-redux";
import {
  sendSelectedOrganizationToDatabase,
  setIsSelectedOrganization,
} from "../../Store/SelectedOrganization/Actions";
import { openDialog, openSnackbar } from "../../Store/Appearance/Actions";
import {
  getSelectedOrganization,
  isSelectedOrganization,
} from "../../Store/SelectedOrganization/Selectors";
import SaveIcon from "@mui/icons-material/Save";
import { CustomRaisedButton } from "../../Components/CustomComponents/CustomButtons";

export default function OrganizationSaveButton() {
  const selectedOrganizationName = useSelector(
    getSelectedOrganization
  ).organizationName;
  const isOrganizationSelected = useSelector(isSelectedOrganization);

  const dispatch = useDispatch();

  const onClick = () =>
    saveExistingOrganization(
      selectedOrganizationName,
      isOrganizationSelected,
      (title: string, content: string) => dispatch(openDialog(title, content)),
      (message: string) => dispatch(openSnackbar(message)),
      () => dispatch(setIsSelectedOrganization()),
      () => dispatch(sendSelectedOrganizationToDatabase())
    );

  return (
    <CustomRaisedButton
      onClick={onClick}
      label="שמור"
      icon={<SaveIcon />}
      variant="contained"
      fullWidth
    />
  );
}

function saveExistingOrganization(
  selectedOrganizationName: string,
  isSelectedOrganization: boolean,
  openDialog: (title: string, content: string) => void,
  openSnackbar: (message: string) => void,
  setIsSelectedOrganization: () => void,
  sendSelectedOrganizationToDatabase: () => void
) {
  if (!isSelectedOrganization) {
    openDialog(
      "לא נבחר ארגון",
      "עדכון פרטי ארגון אפשרי רק עבור ארגונים קיימים, נסה לשמור ארגון חדש"
    );
    return;
  }
  sendSelectedOrganizationToDatabase();
  const snackbarMessage = `ארגון "${selectedOrganizationName}" נשמר בהצלחה`;
  openSnackbar(snackbarMessage);
  setIsSelectedOrganization();
}
