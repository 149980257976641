import _ from "lodash";
import CustomSection from "../../../../../Components/CustomComponents/CustomSection";
import AddParticipantButton from "./AddParticipantButton";
import PublicCourseParticipant from "./PublicCourseParticipant";
import CourseSelector from "./CourseSelector";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedOrder } from "../../../../../Store/SelectedOrder/Selectors";
import styles from "./PublicCourseLectureDetails.module.css";
import { calculateCostOfParitipant } from "../../../../../Store/SelectedOrder/Actions";

export default function PublicCourseLectureDetails() {
  const selectedOrder = useSelector(getSelectedOrder);
  const dispatch = useDispatch();
  const numberOfParticipants = selectedOrder.publicCourseParticipants
    ? selectedOrder.publicCourseParticipants.length
    : 0;

  return (
    <CustomSection title="הרשמה לקורס ציבורי">
      <div className={styles.courseSelector}>
        <CourseSelector />

        <AddParticipantButton selectedOrder={selectedOrder} />
      </div>

      {_.map(_.range(numberOfParticipants), (participantId) => (
        <PublicCourseParticipant
          key={participantId}
          participantId={participantId}
          selectedOrder={selectedOrder}
          calculateCostOfParitipant={() =>
            dispatch(calculateCostOfParitipant(participantId))
          }
        />
      ))}
    </CustomSection>
  );
}
