import { useContext, useState } from "react";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ImportContactsDialogContainer from "./ImportContactDialogContainer";
import { Size } from "../../../../Util/Constants/Size";
import { CustomIconButton } from "../../../../Components/CustomComponents/CustomButtons";
import { useDispatch, useSelector } from "react-redux";
import { openDialog } from "../../../../Store/Appearance/Actions";
import { isSelectedOrganization } from "../../../../Store/SelectedOrganization/Selectors";
import OrderTextField from "../ConnectedCustomComponents/OrderTextField";
import {
  getContactField,
  OrderField,
} from "../../../../Store/Appearance/RequiredFields/FieldNames";
import MobileViewContext from "../../../../Navigation/MobileViewContext";

interface ContactRowProps {
  isFinancialContacts: boolean;
}

export default function ContactRow(props: ContactRowProps) {
  const dispatch = useDispatch();
  const organizationSelected = useSelector(isSelectedOrganization);
  const [dialogOpen, setDialogOpen] = useState(false);
  const isFinancial = props.isFinancialContacts;
  const [mobile] = useContext(MobileViewContext);
  const openContactImportDialog = () => {
    if (!organizationSelected) {
      dispatch(
        openDialog("לא נבחר ארגון", "כדי לייבא אנשי קשר יש לבחור ארגון")
      );
      return;
    }

    setDialogOpen(true);
  };
  return (
    <div>
      <ImportContactsDialogContainer
        dialogOpen={dialogOpen}
        isFinancialContacts={isFinancial}
        onRequestClose={() => setDialogOpen(false)}
      />

      {!mobile && (
        <CustomIconButton
          onClick={openContactImportDialog}
          tooltip="יבא איש קשר"
          style={{ marginBottom: 10, marginLeft: 10 }}
        >
          <PersonAddIcon />
        </CustomIconButton>
      )}

      <OrderTextField
        field={getContactField(OrderField.contactFirstName, isFinancial)}
        size={Size.M}
      />
      <OrderTextField
        field={getContactField(OrderField.contactLastName, isFinancial)}
        size={Size.M}
      />
      <OrderTextField
        field={getContactField(OrderField.contactJob, isFinancial)}
        size={Size.M}
      />
      <OrderTextField
        field={getContactField(OrderField.contactPhone1, isFinancial)}
        size={Size.M}
      />
      <OrderTextField
        field={getContactField(OrderField.contactEmail, isFinancial)}
        size={Size.XXL}
      />
      <OrderTextField
        field={getContactField(OrderField.contactPhone2, isFinancial)}
        size={Size.M}
      />
      <OrderTextField
        field={getContactField(OrderField.contactPhoneExtension, isFinancial)}
        size={Size.S}
      />
    </div>
  );
}
