export enum Path {
  Organization = "/org",
  Home = "/",
  FutureLectures = "/futureLectures",
  ExpectedIncome = "/expectedIncome",
  WaitingPayment = "/payment",
  AllOrders = "/allOrders",
  AllPublicCourses = "/allPublicCourses",
  Order = "/order",
  PublicCourse = "/publicCourse",
  ActionRequired = "/actionRequired",
  Bi = "/bi",
  Yaron = "/yaron",
  OfferTemplates = "/offerTemplates",
  UploadFiles = "/uploadFiles",
  Search = "/search",
  Phone = "/phone",
}

export function getOrderPath(orderId: number | string) {
  return Path.Order + "/" + orderId;
}

export function getOrganizationPath(organizationid: number | string) {
  return Path.Organization + "/" + organizationid;
}

export function getPublicCoursePath(courseId: number | string) {
  return Path.PublicCourse + "/" + courseId;
}

export function getSearchPath(q: string) {
  return Path.Search + "/" + encodeURI(q);
}
