import * as actionTypes from "./ActionTypes";
import { LOGGED_OUT } from "../Firebase/ActionTypes";
import { createImmutable, mergeImmutable } from "../../Util/ObjectUpdater";
import { IOrder } from "@violet/common";

const initialState: {
  isSelectedOrder: boolean;
  order: IOrder;
  hasUnsavedChanges: boolean;
} = {
  isSelectedOrder: false,
  order: {} as IOrder,
  hasUnsavedChanges: false,
};

export default (state = createImmutable(initialState), action: any = {}) => {
  switch (action.type) {
    case actionTypes.SELECT_ORDER:
      return createImmutable({
        order: action.payload,
        isSelectedOrder: true,
        hasUnsavedChanges: false,
      });

    case actionTypes.DUPLICATE_ORDER:
      return createImmutable({
        order: action.payload,
        isSelectedOrder: false,
        hasUnsavedChanges: true,
      });

    case actionTypes.UPDATE_SELECTED_ORDER:
      return mergeImmutable(state, {
        order: action.payload,
        hasUnsavedChanges: true,
      });

    case actionTypes.ORDER_SAVED:
      return mergeImmutable(state, {
        isSelectedOrder: true,
        hasUnsavedChanges: false,
      });

    case LOGGED_OUT:
    case actionTypes.CLEAR_SELECTED_ORDER:
      return createImmutable(initialState);

    default:
      return state;
  }
};
