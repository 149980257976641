import CustomPaperTable from "../Components/Table/CustomPaperTable";
import Screen from "../Components/Screen";
import { getOrders, getOrdersSummary } from "../Store/Orders/Selectors";
import { getOrderPath } from "./Path";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { IState } from "../Interfaces/ReduxInterfaces";
import { isInternalCourse } from "@violet/common";
import _ from "lodash";

export default function YaronLecturesPage() {
  const navigate = useNavigate();
  const orderSelector = (state: IState) =>
    getOrders(state).filter(
      (order) =>
        isInternalCourse(order) &&
        _.find(order.lectureTimes, (lecture) => lecture.topic?.includes("ירון"))
    );
  const yaronOrders = _.reverse(
    useSelector((state: IState) => getOrdersSummary(state, orderSelector))
  );

  return (
    <Screen>
      <CustomPaperTable
        title="הרצאות של ירון"
        elements={yaronOrders}
        tableHeaders={[
          { id: "מספר הזמנה" },
          { date: "תאריך הרצאה" },
          { organizationName: "שם הארגון" },
          { topic: "נושא" },
          { status: "סטאטוס" },
        ]}
        onEditButton={(summary) => navigate(getOrderPath(summary.id))}
      />
    </Screen>
  );
}
