import { Field } from "./FieldNames";
import _ from "lodash";

// TODO add tests
export function shouldMarkWithError(
  field: Field,
  requiredFields?: Field[],
  value?: any
) {
  return requiredFields && !value && _.includes(requiredFields, field);
}
