import CustomSection from "../../../../Components/CustomComponents/CustomSection";
import CalculateIcon from "@mui/icons-material/LocalAtm";
import { Size } from "../../../../Util/Constants/Size";
import { CustomIconButton } from "../../../../Components/CustomComponents/CustomButtons";
import ContactRow from "../ContactSection/ContactRow";
import OrderTextField from "../ConnectedCustomComponents/OrderTextField";
import { OrderField } from "../../../../Store/Appearance/RequiredFields/FieldNames";
import MobileViewContext from "../../../../Navigation/MobileViewContext";
import { useContext } from "react";

export default function PaymentSection(props: PaymentSectionProps) {
  const [mobile] = useContext(MobileViewContext);
  return (
    <CustomSection title="תשלום">
      {!mobile && (
        <>
          <ContactRow isFinancialContacts={true} />
          <div style={{ height: 20, width: "100%" }} />
          <CustomIconButton
            onClick={props.calculateSum}
            style={{ marginBottom: 10, marginLeft: 10 }}
          >
            <CalculateIcon />
          </CustomIconButton>
        </>
      )}

      <OrderTextField field={OrderField.cost} />
      <OrderTextField field={OrderField.oneWayDistance} size={Size.M} />
      <OrderTextField field={OrderField.travelExpenses} size={Size.M} />
      <OrderTextField field={OrderField.extraCosts} size={Size.M} />
      <OrderTextField field={OrderField.sum} />
      <OrderTextField field={OrderField.vat} size={Size.M} />
      <OrderTextField field={OrderField.totalSum} />
    </CustomSection>
  );
}

interface PaymentSectionProps {
  calculateSum: () => void;
}
