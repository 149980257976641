import { useState } from "react";
import TextField from "../Components/TextField";
import { Size } from "../Util/Constants/Size";
import { CustomRaisedButton } from "../Components/CustomComponents/CustomButtons";
import Screen from "../Components/Screen";
import CustomSection from "../Components/CustomComponents/CustomSection";
import { uploadFile } from "../firebase/Storage";
import { StorageFolder } from "@violet/common";
import { saveContentUrlToDb } from "../firebase/Firestore";
import UploadFileButton from "../Components/CustomComponents/UploadFileButton";
import styles from "./UploadContentPage.module.css";

export default function UploadContentPage() {
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  return (
    <Screen>
      <CustomSection title="העלאת קבצים">
        <div className={styles.content}>
          <TextField
            title="סיסמה"
            value={password}
            onChange={setPassword}
            size={Size.XXL}
          />

          <UploadFileButton
            onUploadButtonClicked={(file) => {
              uploadFile(
                file,
                StorageFolder.CONTENT,
                file?.name,
                setMessage,
                setError,
                async (url) => await saveContentUrlToDb(password.trim(), url)
              );
            }}
            message={message}
            setMessage={setMessage}
            error={error}
            setError={setError}
          />
        </div>

        <PasswordCheck />
      </CustomSection>
    </Screen>
  );
}

let checkEnabled = false;
function PasswordCheck() {
  const [password, setPassword] = useState("");
  const [result, setResult] = useState("");

  if (!checkEnabled) {
    return null;
  }

  return (
    <div style={{ marginTop: 50 }}>
      <div>בדיקת סיסמה:</div>
      <TextField
        title="סיסמה לבדיקה"
        value={password}
        onChange={setPassword}
        size={Size.XXL}
      />

      <CustomRaisedButton
        disabled={!password}
        label={"בדוק"}
        onClick={() => {
          setResult("");
          fetch(
            "https://us-central1-violet-36bed.cloudfunctions.net/getContent?password=" +
              password
          ).then((res) => res.text().then(setResult));
        }}
      />

      <div>{result}</div>
    </div>
  );
}
