import _ from "lodash";
import CourseLecturesInstance from "./CourseLecturesInstance";
import { IPublicCourse } from "@violet/common";
import CustomSection from "../../../Components/CustomComponents/CustomSection";
import { CustomRaisedButton } from "../../../Components/CustomComponents/CustomButtons";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch } from "react-redux";
import { addLectureToSelectedPublicCourse } from "../../../Store/SelectedPublicCourse/Actions";

export default function CourseLecturesSection(
  props: CourseLecturesSectionProps
) {
  const lectures = _.filter(
    props.selectedPublicCourse.lectures,
    (x) => x.active
  );
  const datesAndIds = _.map(lectures, (lecture) => {
    return {
      id: lecture.id,
      date: lecture.date,
    };
  });
  const orderedDatesAndIds = _.sortBy(datesAndIds, (x) => x.date);
  const lectureIds = _.map(orderedDatesAndIds, (x) => x.id);

  const dispatch = useDispatch();

  return (
    <CustomSection title="הרצאות">
      <CustomRaisedButton
        label="הוסף הרצאה"
        icon={<AddIcon />}
        onClick={() => dispatch(addLectureToSelectedPublicCourse())}
        variant="outlined"
        style={{ marginBottom: 20 }}
      />

      {_.map(lectureIds, (lectureId, index) => (
        <CourseLecturesInstance
          key={lectureId}
          index={index + 1}
          lectureId={lectureId}
          selectedPublicCourse={props.selectedPublicCourse}
        />
      ))}
    </CustomSection>
  );
}

interface CourseLecturesSectionProps {
  selectedPublicCourse: IPublicCourse;
}
