import { useSelector } from "react-redux";
import CustomDatePicker from "../../../../Components/CustomComponents/CustomDatePicker";
import { OrderField } from "../../../../Store/Appearance/RequiredFields/FieldNames";
import { getSelectedOrder } from "../../../../Store/SelectedOrder/Selectors";

export default function ProformaInvoiceDate(props: ProformaInvoiceDateProps) {
  const order = useSelector(getSelectedOrder);
  return (
    <CustomDatePicker
      field={OrderField.proformaInvoiceDate}
      valueObject={order}
      onChange={(value: string) => {
        props.onChangeProformaInvoiceDate(value);

        props.calculatePayDate(value, props.selectedPaymentConditions);
      }}
    />
  );
}

interface ProformaInvoiceDateProps {
  onChangeProformaInvoiceDate: (value: string) => void;
  calculatePayDate: (
    proformaInvoiceValue: string,
    selectedPaymentConditions: string
  ) => void;
  selectedPaymentConditions: string;
}
