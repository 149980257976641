import { isMatchingStatus, Status } from "@violet/common";
import { getSelectedOrder } from "../../Store/SelectedOrder/Selectors";
import { useSelector } from "react-redux";
import { createOptions } from "../../Components/CustomComponents/CustomSelectField";
import {
  getCancellationReasons,
  getRejectionReasons,
} from "../../Store/Lists/Selectors";
import { OrderField } from "../../Store/Appearance/RequiredFields/FieldNames";
import OrderCheckbox from "./Sections/ConnectedCustomComponents/OrderCheckbox";
import OrderSelectField from "./Sections/ConnectedCustomComponents/OrderSelectField";
import OrderTextField from "./Sections/ConnectedCustomComponents/OrderTextField";

export default function OrderApprovalBox() {
  const selectedOrder = useSelector(getSelectedOrder);
  const rejectionReasons = createOptions(useSelector(getRejectionReasons));
  const cancellationReasons = createOptions(
    useSelector(getCancellationReasons)
  );
  const showCancelledCheckBox = isMatchingStatus(selectedOrder, [
    Status.approvedOrder,
    Status.isExecuting,
    Status.cancelled,
  ]);

  return (
    <div>
      <OrderCheckbox field={OrderField.orderApproved} />
      <OrderCheckbox field={OrderField.rejected} forceErrorColor />

      {selectedOrder.rejected && (
        <>
          <OrderSelectField
            field={OrderField.rejectionReason}
            fullWidth
            options={rejectionReasons}
          />
          <OrderTextField field={OrderField.rejectionDetails} fullWidth />
        </>
      )}

      {showCancelledCheckBox && (
        <OrderCheckbox field={OrderField.cancelled} forceErrorColor />
      )}

      {selectedOrder.cancelled && (
        <>
          <OrderSelectField
            field={OrderField.cancellationReason}
            fullWidth
            options={cancellationReasons}
          />
          <OrderTextField field={OrderField.cancellationDetails} fullWidth />
        </>
      )}
    </div>
  );
}
