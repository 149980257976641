import { IState } from "../../Interfaces/ReduxInterfaces";
import { toMutable } from "../../Util/ObjectUpdater";
import { TabKey } from "@violet/common";

export function getSelectedOrder(state: IState) {
  return toMutable(state.selectedOrder).order;
}

export function isSelectedOrder(state: IState) {
  return state.selectedOrder.isSelectedOrder;
}

export function getSelectedTab(state: IState) {
  return getSelectedOrder(state).lectureDetailsTabKey || TabKey.internalTabKey;
}

export function selectedOrderHasUnsavedChanges(state: IState) {
  return state.selectedOrder.hasUnsavedChanges;
}
