import { connect } from "react-redux";
import { ILectureTime, IOrder } from "@violet/common";
import { getSelectedOrder } from "../../../../../../Store/SelectedOrder/Selectors";
import {
  IDispatch,
  IState,
} from "../../../../../../Interfaces/ReduxInterfaces";
import { updateLectureTime } from "../../../../../../Store/SelectedOrder/Actions";
import OfferDetails from "./OfferDetails";
import { openSnackbar } from "../../../../../../Store/Appearance/Actions";
import { getOfferTemplate } from "../../../../../../Store/Lists/Selectors";
import { getSelectedOrganization } from "../../../../../../Store/SelectedOrganization/Selectors";

function getLectureTime(
  selectedOrder: IOrder,
  lectureTimeIndex: number
): ILectureTime {
  if (lectureTimeIndex === null || selectedOrder.lectureTimes === undefined)
    return {} as ILectureTime;

  return selectedOrder.lectureTimes[lectureTimeIndex];
}

function mapStateToProps(state: IState, ownProps: OfferDetailsContainerProps) {
  const selectedOrder = getSelectedOrder(state);

  return {
    show: ownProps.show,
    lectureTime: getLectureTime(selectedOrder, ownProps.lectureTimeIndex),
    orderId: selectedOrder.id,
    contactFirstName: selectedOrder.contactFirstName,
    contactEmail: selectedOrder.contactEmail,
    template: getOfferTemplate(state, ownProps.topic),
    paymentConditions: getSelectedOrganization(state).paymentConditions,
  };
}

function mapDispatchToProps(
  dispatch: IDispatch,
  ownProps: OfferDetailsContainerProps
) {
  return {
    onChange: (key: string) => (newValue: string | boolean) =>
      dispatch(updateLectureTime(key, newValue, ownProps.lectureTimeIndex)),
    onLinkCopied: () => dispatch(openSnackbar("הקישור הועתק בהצלחה")),
  };
}

interface OfferDetailsContainerProps {
  lectureTimeIndex: number;
  index: number;
  topic: string;
  show: boolean;
}

export default connect(mapStateToProps, mapDispatchToProps)(OfferDetails);
