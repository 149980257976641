import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  clearSelectedPublicCourse,
  selectPublicCourse,
} from "../../Store/SelectedPublicCourse/Actions";
import { useEffect } from "react";
import SideMenu from "../../Components/SideMenu";
import SavePublicCourseButton from "./SavePublicCourseButton";
import Screen from "../../Components/Screen";
import { getSelectedPublicCourse } from "../../Store/SelectedPublicCourse/Selectors";
import CourseDetailsSection from "./Sections/CourseDetailsSection";
import CourseLecturesSection from "./Sections/CourseLecturesSection";
import CourseParticipantsTable from "./Sections/CourseParticipantsTable";
import CourseLecturesDetailsTable from "./Sections/CourseLecturesDetailsTable";

export default function PublicCoursePage() {
  const { courseId } = useParams<{ courseId?: string }>();
  const dispatch = useDispatch();

  const selectedPublicCourse = useSelector(getSelectedPublicCourse);

  useEffect(() => {
    if (!courseId) {
      dispatch(clearSelectedPublicCourse());
      return;
    }

    dispatch(selectPublicCourse(courseId));
  }, [courseId, dispatch]);

  const title = selectedPublicCourse?.courseName
    ? "קורס ציבורי: " + selectedPublicCourse.courseName
    : "קורס ציבורי חדש";

  const sideMenu = (
    <SideMenu title={title}>
      <SavePublicCourseButton selectedPublicCourse={selectedPublicCourse} />
    </SideMenu>
  );

  return (
    <Screen sideMenu={sideMenu}>
      <CourseDetailsSection selectedPublicCourse={selectedPublicCourse} />

      <CourseLecturesSection selectedPublicCourse={selectedPublicCourse} />

      <CourseParticipantsTable approved />
      <CourseParticipantsTable approved={false} />
      <CourseLecturesDetailsTable />
    </Screen>
  );
}
