import { ILectureTime, toDateFormat } from "@violet/common";
import {
  CustomIconButton,
  CustomRaisedButton,
} from "../../../../../../Components/CustomComponents/CustomButtons";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import LinkIcon from "@mui/icons-material/Link";
import { OfferTemplate } from "../../../../../../Interfaces/ILists";
import styles from "./OfferDetails.module.css";
import CustomDatePicker from "../../../../../../Components/CustomComponents/CustomDatePicker";
import { LectureTimeField } from "../../../../../../Store/Appearance/RequiredFields/FieldNames";
import CustomTextField from "../../../../../../Components/CustomComponents/CustomTextField";
import CustomCheckbox from "../../../../../../Components/CustomComponents/CustomCheckbox";

export default function OfferDetails(props: OfferDetailsProps) {
  const resetTemplate = () => {
    if (!props.template) {
      return;
    }
    props.onChange(LectureTimeField.offerDate)(toDateFormat(new Date()));
    props.onChange(LectureTimeField.subject)(props.template.subject);
    props.onChange(LectureTimeField.content)(props.template.content);
    props.onChange(LectureTimeField.offerCost)(props.template.cost);
    props.onChange(LectureTimeField.paymentConditionInfo)(
      convertPaymentConditionToOfferInfo(props.paymentConditions)
    );
  };

  const onCopy = () => {
    const baseUrl =
      process.env.NODE_ENV === "production"
        ? "https://offer.c-point.co.il"
        : "http://localhost:3000";

    const url = `${baseUrl}/v1?o=${props.orderId}&l=${props.lectureTimeIndex}&t=${props.lectureTime.offerToken}`;

    navigator.clipboard.writeText(url).then(props.onLinkCopied);
  };

  if (!props.show) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.firstRow}>
          <CustomRaisedButton
            style={{ marginInlineEnd: 20 }}
            onClick={resetTemplate}
            label={"מילוי מתבנית"}
            icon={<RotateLeftIcon />}
            variant="outlined"
          />

          <CustomDatePicker
            field={LectureTimeField.offerDate}
            valueObject={props.lectureTime}
            onChange={props.onChange(LectureTimeField.offerDate)}
          />

          <div className={styles.subjectField}>
            <CustomTextField
              field={LectureTimeField.subject}
              valueObject={props.lectureTime}
              onChange={props.onChange(LectureTimeField.subject)}
              fullWidth
            />
          </div>
        </div>

        <div className={styles.contentRow}>
          <CustomTextField
            field={LectureTimeField.content}
            valueObject={props.lectureTime}
            onChange={props.onChange(LectureTimeField.content)}
            fullWidth
          />
        </div>

        <div className={styles.row}>
          <CustomTextField
            field={LectureTimeField.offerCost}
            valueObject={props.lectureTime}
            onChange={props.onChange(LectureTimeField.offerCost)}
            fullWidth
          />
          <CustomTextField
            field={LectureTimeField.paymentConditionInfo}
            valueObject={props.lectureTime}
            onChange={props.onChange(LectureTimeField.paymentConditionInfo)}
            fullWidth
          />
          <div className={styles.noTravetCostCheckbox}>
            <CustomCheckbox
              field={LectureTimeField.noTravelCost}
              valueObject={props.lectureTime}
              onChange={props.onChange(LectureTimeField.noTravelCost)}
            />
          </div>

          <div className={styles.copyLinkButton}>
            <CustomIconButton tooltip={"העתק קישור"} onClick={onCopy}>
              <LinkIcon />
            </CustomIconButton>
          </div>
        </div>
      </div>
    </div>
  );
}

interface OfferDetailsProps {
  show: boolean;
  contactFirstName: string;
  contactEmail: string;
  lectureTime: ILectureTime;
  orderId: number;
  lectureTimeIndex: number;
  onChange: (key: LectureTimeField) => (value: string | boolean) => void;
  onLinkCopied: () => void;
  template?: OfferTemplate;
  paymentConditions: string;
}

function convertPaymentConditionToOfferInfo(conditions: string) {
  switch (conditions) {
    case "תשלום מיידי":
      return "תשלום מיידי.";
    case "שוטף + 0":
      return "ראשון לחודש שלאחר משלוח חשבונית עסקה.";
    case "שוטף + 30":
    default:
      return "שוטף + 30 ממועד משלוח חשבונית עסקה.";
    case "שוטף + 45":
      return "שוטף + 45 ממועד משלוח חשבונית עסקה.";
    case "שוטף + 60":
      return "שוטף + 60 ממועד משלוח חשבונית עסקה.";
    case "שוטף + 75":
      return "שוטף + 70 ממועד משלוח חשבונית עסקה.";
    case "שוטף + 30 + 7 לחודש":
      return "שוטף + 37 ממועד משלוח חשבונית עסקה.";
    case "שוטף + 30 + 22 לחודש":
      return "שוטף + 52 ממועד משלוח חשבונית עסקה.";
  }
}
