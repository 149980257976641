import { StorageFolder } from "@violet/common";
import {
  getStorage,
  ref,
  getDownloadURL,
  uploadBytes,
  deleteObject,
} from "firebase/storage";

export async function uploadFile(
  file: File | null,
  folder: StorageFolder | string,
  fileName: string | undefined,
  setMessage: (error: string) => void,
  setError: (error: string) => void,
  onUploadSuccess: (url: string) => Promise<void>
) {
  setMessage("מעלה את הקובץ...");
  if (!file || !fileName) {
    setError("קלט לא תקין");
    return;
  }

  const storage = getStorage();
  const fileRef = ref(storage, `/${folder}/${fileName}`);

  // Verify file does not already exists.
  try {
    await getDownloadURL(fileRef);
    setError("קובץ עם שם זהה כבר קיים: " + fileName);
    return;
  } catch {
    // Continue
  }

  uploadBytes(fileRef, file, {})
    .then(async () => {
      try {
        const url = await getDownloadURL(fileRef);
        await onUploadSuccess(url);
        setMessage("הוקבץ הועלה בהצלחה");
      } catch (e) {
        setError("שגיאה בהעלאת הקובץ:\n " + e);
      }
    })
    .catch((e) => setError(e.message));
}

export async function deleteFile(
  folder: StorageFolder | string,
  fileName: string | undefined
) {
  const storage = getStorage();
  const fileRef = ref(storage, `/${folder}/${fileName}`);
  return deleteObject(fileRef);
}
