import { useDispatch, useSelector } from "react-redux";
import { Size } from "../../../../../Util/Constants/Size";
import {
  getPublicCourseById,
  getPublicCourses,
  isPublicCourseActive,
} from "../../../../../Store/PublicCourses/Selectors";
import _ from "lodash";
import { selectPublicCourse } from "../../../../../Store/SelectedPublicCourse/Actions";
import { getSelectedOrder } from "../../../../../Store/SelectedOrder/Selectors";
import { isEmptyValue } from "../../../../../Util/StringUtil";
import {
  removeParticipantsFromAllLectures,
  updateSelectedOrder,
} from "../../../../../Store/SelectedOrder/Actions";
import { IDispatch } from "../../../../../Interfaces/ReduxInterfaces";
import { IPublicCourse } from "@violet/common";
import { IOption } from "../../../../../Components/CustomComponents/CustomSelectField";
import SelectField from "../../../../../Components/SelectField";
import {
  getFieldLabel,
  OrderField,
} from "../../../../../Store/Appearance/RequiredFields/FieldNames";

export default function CourseSelector() {
  const selectedOrder = useSelector(getSelectedOrder);
  const publicCourses = useSelector(getPublicCourses);
  const publicCourseById = useSelector(getPublicCourseById);
  const dispatch = useDispatch();

  const selectedPublicCourse = publicCourseById(
    selectedOrder.publicCourseId + ""
  );
  const value =
    isEmptyValue(selectedOrder, OrderField.publicCourseId) ||
    !selectedPublicCourse
      ? ""
      : selectedPublicCourse.id + "";

  // If the selected public course is no longer active,
  // we want to show it but make the select field disabled
  const isSelectedCourseNotActiveAnymore =
    selectedPublicCourse !== undefined &&
    !isPublicCourseActive(selectedPublicCourse);

  const options = _.chain(publicCourses)
    .filter(isPublicCourseActive)
    .map(getOption)
    .reverse()
    .value();

  // We want to show the course, so we have to add it to the list of active courses
  if (isSelectedCourseNotActiveAnymore) {
    options.push(getOption(selectedPublicCourse));
  }

  return (
    <SelectField
      label={getFieldLabel(OrderField.publicCourseId)}
      size={Size.XL}
      options={options}
      value={value}
      onChange={(value: string) => selectFieldUpdateAction(dispatch, value)}
      disabled={isSelectedCourseNotActiveAnymore}
    />
  );
}

function getOption(course: IPublicCourse): IOption {
  return {
    key: course.id,
    label: course.courseName,
  };
}

export function selectFieldUpdateAction(dispatch: IDispatch, newValue: string) {
  dispatch(selectPublicCourse(newValue.toString()));
  dispatch(updateSelectedOrder(OrderField.publicCourseId, newValue));
  dispatch(removeParticipantsFromAllLectures());
}
