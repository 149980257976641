import CustomSection from "../../../../../Components/CustomComponents/CustomSection";
import { Size } from "../../../../../Util/Constants/Size";
import LectureTimesDetails from "./LectureTimes/LectureTimesDetails";
import styles from "./InternalLectureDetails.module.css";
import { OrderField } from "../../../../../Store/Appearance/RequiredFields/FieldNames";
import OrderCheckbox from "../../ConnectedCustomComponents/OrderCheckbox";
import OrderTextField from "../../ConnectedCustomComponents/OrderTextField";
import { useContext } from "react";
import MobileViewContext from "../../../../../Navigation/MobileViewContext";
import { useSelector } from "react-redux";
import { getSelectedOrder } from "../../../../../Store/SelectedOrder/Selectors";
import CustomDivider from "../../../../../Components/CustomComponents/CustomDivider";

export default function InternalLectureDetails() {
  const [mobile] = useContext(MobileViewContext);
  const order = useSelector(getSelectedOrder);
  return (
    <CustomSection title="פרטי ההרצאה">
      <div className={styles.detailsSection}>
        <div className={mobile ? "" : styles.lectureDetailsFields}>
          <OrderTextField field={OrderField.street} />
          <OrderTextField field={OrderField.streetNumber} size={Size.S} />
          <OrderTextField field={OrderField.city} />
          <OrderTextField field={OrderField.location} size={Size.XL} />
          <OrderTextField field={OrderField.audienceType} />
          <OrderTextField field={OrderField.daySchedule} />
        </div>
        {mobile ? (
          <>
            <a
              href={`https://waze.com/ul?q=${order.street}${
                " " + order.streetNumber
              } ${order.city}`}
            >
              קישור לוויז
            </a>
            <CustomDivider />
          </>
        ) : (
          <div className={styles.lectureDetailsFields}>
            <OrderCheckbox field={OrderField.sameAudience} />
            <OrderCheckbox field={OrderField.projector} />
            <OrderCheckbox field={OrderField.soundSystem} />
            <OrderCheckbox field={OrderField.microphone} />
            <OrderCheckbox field={OrderField.parking} />
          </div>
        )}
        <LectureTimesDetails />
      </div>
    </CustomSection>
  );
}
