import styles from "./OrderPageSideMenu.module.css";
import {
  getOrderStatusLabel,
  IOrder,
  toPrintableDateFormat,
} from "@violet/common";
import SendIcon from "@mui/icons-material/Mail";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import SaveOrderContainer from "./ActionButtons/SaveOrderButton/SaveOrderContainer";
import ButtonGroup from "@mui/material/ButtonGroup";
import { Alert, Divider } from "@mui/material";
import OrderApprovalBox from "./OrderApprovalBox";
import FollowUpSection from "./Sections/FollowUp/FollowUpSection";
import {
  getActionRequiredCase,
  getActionRequiredCaseLabel,
  isCriticalCase,
} from "../../Store/Orders/ActionRequiredOrderes";
import { useDispatch, useSelector } from "react-redux";
import { getPublicCourseByOrder } from "../../Store/PublicCourses/Selectors";
import { CustomRaisedButton } from "../../Components/CustomComponents/CustomButtons";
import SideMenu from "../../Components/SideMenu";
import {
  getFieldLabel,
  OrderField,
} from "../../Store/Appearance/RequiredFields/FieldNames";
import { duplicateOrder } from "../../Store/SelectedOrder/Actions";
import {
  getSelectedOrder,
  isSelectedOrder,
  selectedOrderHasUnsavedChanges,
} from "../../Store/SelectedOrder/Selectors";

export default function OrderPageSideMenu() {
  const selectedOrder = useSelector(getSelectedOrder);
  const isOrderSelected = useSelector(isSelectedOrder);
  const title = isOrderSelected
    ? getFieldLabel(OrderField.id) + " " + selectedOrder.id
    : "הזמנה חדשה";
  return (
    <SideMenu title={title}>
      <OrderStatus selectedOrder={selectedOrder} />
      <MenuDivider />

      <OrderApprovalBox />
      <MenuDivider />

      <FollowUpSection />
      <MenuDivider />

      <OrderTimes
        selectedOrder={selectedOrder}
        isOrderSelected={isOrderSelected}
      />

      <SaveOrderContainer />

      <br />
      <br />
      <ButtonGroup fullWidth>
        <EmailCustomerButton
          selectedOrder={selectedOrder}
          isOrderSelected={isOrderSelected}
        />
        <DuplicateOrderButton isOrderSelected={isOrderSelected} />
      </ButtonGroup>
    </SideMenu>
  );
}

function OrderStatus(props: { selectedOrder: IOrder }) {
  const statusLabel = getOrderStatusLabel(props.selectedOrder);

  const publicCourse = useSelector(getPublicCourseByOrder)(props.selectedOrder);
  const actionRequiredCase = getActionRequiredCase(
    props.selectedOrder,
    publicCourse
  );

  let severity: "error" | "warning" = "error";
  if (actionRequiredCase && !isCriticalCase(actionRequiredCase)) {
    severity = "warning";
  }
  return (
    <div>
      <div className={styles.status}>סטאטוס: {statusLabel}</div>
      <br />

      {actionRequiredCase && (
        <Alert severity={severity}>
          {getActionRequiredCaseLabel(actionRequiredCase)}
        </Alert>
      )}
    </div>
  );
}

function EmailCustomerButton(props: {
  isOrderSelected: boolean;
  selectedOrder: IOrder;
}) {
  if (!props.isOrderSelected) {
    return null;
  }

  let href = "mailto:";
  href += props.selectedOrder.contactEmail;
  href += "?subject=חנן מלין - הצעת מחיר מספר " + props.selectedOrder.id;

  return (
    <CustomRaisedButton
      label="שלח"
      icon={<SendIcon />}
      onClick={() => window.open(href)}
    />
  );
}

function DuplicateOrderButton(props: { isOrderSelected: boolean }) {
  const dispatch = useDispatch();
  const orderHasUnsavedFields = useSelector(selectedOrderHasUnsavedChanges);

  if (!props.isOrderSelected) {
    return null;
  }

  return (
    <CustomRaisedButton
      label="שכפל"
      icon={<FileCopyIcon />}
      onClick={() => dispatch(duplicateOrder())}
      disabled={orderHasUnsavedFields}
    />
  );
}

function OrderTimes(props: {
  isOrderSelected: boolean;
  selectedOrder: IOrder;
}) {
  if (!props.isOrderSelected) {
    return null;
  }

  const createdDate = props.selectedOrder.createdDate;
  const changedDate = props.selectedOrder.changedDate;

  return (
    <>
      <div className={styles.times}>
        <span>{getFieldLabel(OrderField.createdDate)}</span>
        <span>-</span>
        <span>{toPrintableDateFormat(createdDate)}</span>
        <span>{getFieldLabel(OrderField.changedDate)}</span>
        <span>-</span>
        <span>{toPrintableDateFormat(changedDate)}</span>
      </div>
      <MenuDivider />
    </>
  );
}

function MenuDivider() {
  return (
    <div className={styles.divider}>
      <Divider />
    </div>
  );
}
