import { getSelectedOrganization } from "../../SelectedOrganization/Selectors";
import * as _ from "lodash";
import requiredFields from "./RequiredFieldsByStatus";
import { IRequiredFields, mergerRequiredFields } from "./Util";
import { shouldShowRequiredFields } from "../Selectors";
import {
  getSelectedOrder,
  getSelectedTab,
} from "../../SelectedOrder/Selectors";
import { IState } from "../../../Interfaces/ReduxInterfaces";
import {
  IOrder,
  IOrganization,
  isInternalCourse,
  isPublicCourseOrder,
  isOnlineCourse,
  TabKey,
} from "@violet/common";
import { Field, OrderField } from "./FieldNames";

// TODO add tests.
export function getOrderRequiredFields(state: IState) {
  const allRequiredFields = getRequiredFieldsObject(state);
  const selectedTab = getSelectedTab(state);
  switch (selectedTab) {
    case TabKey.internalTabKey:
      return [...allRequiredFields.order, ...allRequiredFields.internalOrder];

    case TabKey.onlineCourseTabKey:
      return allRequiredFields.order;

    case TabKey.publicCourseTabKey:
      return [
        ...allRequiredFields.order,
        ...allRequiredFields.onlineCourseLectureTime,
      ];

    default:
      throw Error("Unknown tab key");
  }
}

export function getRequiredFieldsObject(state: IState) {
  return getRequiredFieldsStateObject(state, shouldShowRequiredFields(state));
}

export function isOrderMissingFields(state: IState): Field[] {
  const requiredFieldsObject = getRequiredFieldsStateObject(state, true);
  const selectedOrder = getSelectedOrder(state);

  let missingFields: Field[];
  missingFields = getMissingFields(selectedOrder, requiredFieldsObject.order);
  if (missingFields.length > 0) {
    return missingFields;
  }

  missingFields = getMissingFields(
    getSelectedOrganization(state),
    requiredFieldsObject.organization
  );
  if (missingFields.length > 0) {
    return missingFields;
  }

  if (isInternalCourse(selectedOrder)) {
    missingFields = getElementInArrayMissingFields(
      selectedOrder.lectureTimes,
      requiredFieldsObject.internalOrderLectureTime
    );
    if (missingFields.length > 0) {
      return missingFields;
    }

    missingFields = getMissingFields(
      selectedOrder,
      requiredFieldsObject.internalOrder
    );
    if (missingFields.length > 0) {
      return missingFields;
    }
  }

  if (isOnlineCourse(selectedOrder)) {
    missingFields = getElementInArrayMissingFields(
      selectedOrder.lectureTimes,
      requiredFieldsObject.onlineCourseLectureTime
    );
    if (missingFields.length > 0) {
      return missingFields;
    }
  }

  if (isPublicCourseOrder(selectedOrder)) {
    missingFields = getElementInArrayMissingFields(
      selectedOrder.publicCourseParticipants,
      requiredFieldsObject.publicCourse
    );
    if (missingFields.length > 0) {
      return missingFields;
    }
  }

  return [];
}

function getRequiredFieldsStateObject(
  state: IState,
  showRequiredFields: boolean
) {
  const selectedOrder = getSelectedOrder(state);
  const requiredFieldsByEntity = getRequiredFieldsByEntity(
    selectedOrder,
    showRequiredFields
  );

  return removeOrganizationBasedFields(
    requiredFieldsByEntity,
    getSelectedOrganization(state)
  );
}

function removeOrganizationBasedFields(
  requiredFieldsByEntity: IRequiredFields,
  selectedOrganization: IOrganization
) {
  let orderFields = requiredFieldsByEntity.order;
  if (!selectedOrganization.externalInvoiceHandler) {
    orderFields = _.without(orderFields, OrderField.externalInvoiceSent);
  }

  if (!selectedOrganization.internalOrderIdRequired) {
    orderFields = _.without(orderFields, OrderField.internalOrderNumber);
  }

  return {
    ...requiredFieldsByEntity,
    order: orderFields,
  };
}

function getRequiredFieldsByEntity(
  selectedOrder: IOrder,
  showRequiredFields: boolean
): IRequiredFields {
  if (!showRequiredFields)
    return {
      order: [],
      organization: [],
      internalOrderLectureTime: [],
      onlineCourseLectureTime: [],
      publicCourse: [],
      internalOrder: [],
    };

  if (!selectedOrder.status) return requiredFields.contact;

  const statusRequiredFields = requiredFields[selectedOrder.status];

  if (selectedOrder.followUpRequired)
    return mergerRequiredFields(
      statusRequiredFields,
      requiredFields.followUpRequired
    );

  return statusRequiredFields;
}

function getElementInArrayMissingFields(
  array: any,
  lectureTimesRequiredFields: Field[]
): Field[] {
  for (let i = 0; i < _.size(array); i++) {
    const missingFields = getMissingFields(
      array[i],
      lectureTimesRequiredFields
    );
    if (missingFields.length > 0) {
      return missingFields;
    }
  }
  return [];
}

function getMissingFields(object: any, required: Field[]) {
  const nonEmptyKeys = _.filter(_.keys(object), (key) => object[key] !== "");
  return _.difference(required, nonEmptyKeys) as Field[];
}
