import * as _ from "lodash";
import { existsAndNotEmpty } from "./OrderStatusUtils";
import {
  hasDatePassed,
  isToday,
  IOrder,
  IPublicCourse,
  progressiveStatuses,
  Status,
  terminatingStatuses,
  isPublicCourseOrder,
} from "../index";

export function calculateOrderStatus(
  order: IOrder,
  publicCourse?: IPublicCourse
) {
  for (let status in terminatingStatuses) {
    if (meetsRequirements(order, publicCourse, status as Status)) {
      return status as Status;
    }
  }

  let result = Status.contact;

  for (let possibleStatus in progressiveStatuses) {
    const status = possibleStatus as Status;
    if (meetsRequirements(order, publicCourse, status)) {
      result = status;
    } else {
      break;
    }
  }

  return result;
}

function meetsRequirements(
  order: IOrder,
  publicCourse: IPublicCourse | undefined,
  statusName: Status
) {
  switch (statusName) {
    case Status.contact:
      return isContact();

    case Status.offer:
      return isOffer(order);

    case Status.order:
      return isOrder(order);

    case Status.approvedOrder:
      return isApprovedOrder(order);

    case Status.isExecuting:
      return isExecuting(order, publicCourse);

    case Status.executed:
      return isExecuted(order, publicCourse);

    case Status.waitingPayment:
      return isWaitingPayment(order, publicCourse);

    case Status.payed:
      return isPayed(order);

    case Status.cancelled:
      return isCancelled(order);

    case Status.rejected:
      return isRejected(order);

    default:
      return false;
  }
}

function isContact() {
  // An order is always a contact
  return true;
}

function isOffer(order: IOrder) {
  if (isPublicCourseOrder(order)) {
    return existsAndNotEmpty(order, "publicCourseParticipants");
  }

  // Order must have at least one lecture time with a topic
  if (!existsAndNotEmpty(order, "lectureTimes")) return false;

  return _.some(order.lectureTimes, (x) => !!x);
}

function isOrder(order: IOrder) {
  if (isPublicCourseOrder(order)) {
    // Order must have at least one participant attending one lecture
    return _.some(
      order.publicCourseParticipants,
      (participant) =>
        _.isArray(participant.lecturesAttending) &&
        participant.lecturesAttending.length !== 0
    );
  }

  // Order must have at lease one lecture with date
  return _.some(order.lectureTimes, (lectureTime) => Boolean(lectureTime.date));
}

function isApprovedOrder(order: IOrder) {
  // Order must be approved
  return order.orderApproved;
}

function isExecuting(order: IOrder, publicCourse?: IPublicCourse) {
  // At least one lectures is done
  return _.some(getDatesToCheck(order, publicCourse), hasDatePassed);
}

function isExecuted(order: IOrder, publicCourse?: IPublicCourse) {
  // All lectures passed
  return _.every(getDatesToCheck(order, publicCourse), hasDatePassed);
}

function isWaitingPayment(order: IOrder, publicCourse?: IPublicCourse) {
  // If some order is today, don't mark as waiting for payment so order would
  // still appear in "Future lectures" page.
  if (_.some(getDatesToCheck(order, publicCourse), isToday)) {
    return false;
  }
  // Order has proforma or tax invoice number
  return (
    existsAndNotEmpty(order, "proformaInvoiceNumber") ||
    existsAndNotEmpty(order, "taxInvoiceNumber")
  );
}

function isPayed(order: IOrder) {
  return existsAndNotEmpty(order, "receiptNumber");
}

function isCancelled(order: IOrder) {
  return order.cancelled;
}

function isRejected(order: IOrder) {
  return order.rejected;
}

function getDatesToCheck(order: IOrder, publicCourse?: IPublicCourse) {
  if (isPublicCourseOrder(order) && publicCourse) {
    const activeLectures = _.filter(
      publicCourse.lectures,
      (lecture) => lecture.active
    );

    const lecturesThatAtLeastOneParticipantIsAttending = new Set(
      _.flatMap(
        order.publicCourseParticipants,
        (participant) => participant.lecturesAttending
      )
    );

    return _.chain(activeLectures)
      .filter((lecture) =>
        lecturesThatAtLeastOneParticipantIsAttending.has(lecture.id)
      )
      .map((lecture) => lecture.date)
      .value();
  } else {
    return _.map(order.lectureTimes, (lectureTime) => lectureTime.date);
  }
}
