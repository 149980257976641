import CustomSection from "../../../../Components/CustomComponents/CustomSection";
import ContactRow from "./ContactRow";

export default function ContactsSection() {
  return (
    <CustomSection title="איש קשר">
      <ContactRow isFinancialContacts={false} />
    </CustomSection>
  );
}
