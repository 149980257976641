import { OfferApprovalDetails } from "./Interfaces/ILectureTime";

export enum COLLECTION {
  ORDERS = "orders",
  ORGANIZATIONS = "organizations",
  PUBLIC_COURSES = "publicCourses",
  LISTS = "lists",
  USERS = "users",

  // Lists
  OFFER_TEMPLATES = "offerTemplates",
}

export enum StorageFolder {
  BACKUPS = "backups",
  CONTENT = "content",
  ORDERS = "orders",
}

export interface OfferDataRequest {
  offerId: string;
  lectureId: string;
  token: string;
}

export interface OfferDataResponse {
  orderId: number;
  lectureId: number;
  offerDate: string;
  subject: string;
  content: string;
  email: string;
  organizationName: string;
  contactName: string;
  contactPhone: string;
  paymentConditionInfo: string;
  offerCost: string;
  noTravelCost: boolean;
  approvalDate?: string;
  approvingContactName?: string;
}

export interface ApproveOfferRequest extends OfferApprovalDetails {
  offerId: string;
  lectureId: string;
  token: string;
}

export interface ApproveOfferResponse {}
