import classNames from "classnames";
import React, { useContext } from "react";
import MobileViewContext from "../../Navigation/MobileViewContext";
import CustomPaper from "./CustomPaper";
import styles from "./CustomSection.module.scss";

export default function CustomSection(props: CustomPaperProps) {
  const [mobile] = useContext(MobileViewContext);
  return (
    <CustomPaper className={styles.section}>
      {props.title && (
        <div
          className={classNames({
            [styles.title]: true,
            [styles.clickableTitle]: props.onClickTitle !== undefined,
          })}
          onClick={props.onClickTitle}
        >
          <div className={styles.titleText}>{props.title}</div>
        </div>
      )}
      <div
        className={classNames({
          [styles.paperContent]: true,
          [styles.mobilePaper]: mobile,
        })}
      >
        {props.children}
      </div>
    </CustomPaper>
  );
}

interface CustomPaperProps {
  title?: string;
  children?: React.ReactNode;
  onClickTitle?: () => void;
}
