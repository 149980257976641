import {
  applyMiddleware,
  combineReducers,
  createStore as reduxCreateStore,
} from "redux";
import thunkMiddleware from "redux-thunk";
import appearance from "./Appearance/Reducer";
import firebase from "./Firebase/Reducer";
import lists from "./Lists/Reducer";
import orders from "./Orders/Reducer";
import organizations from "./Organizations/Reducer";
import publicCourses from "./PublicCourses/Reducer";
import selectedOrder from "./SelectedOrder/Reducer";
import selectedOrganization from "./SelectedOrganization/Reducer";
import selectedPublicCourse from "./SelectedPublicCourse/Reducer";

const combinedReducers = combineReducers({
  appearance,
  firebase,
  lists,
  orders,
  organizations,
  publicCourses,
  selectedOrder,
  selectedOrganization,
  selectedPublicCourse,
});

const store = reduxCreateStore(
  combinedReducers,
  applyMiddleware(thunkMiddleware)
);

export default store;
