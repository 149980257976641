import OrganizationSectionContainer from "../OrderPage/Sections/OrganizationSection/OrganizationSectionContainer";
import OrganizationsOrdersTable from "./OrganizationsOrdersTable";
import Screen from "../../Components/Screen";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  clearSelectedOrganization,
  selectOrganization,
} from "../../Store/SelectedOrganization/Actions";
import { useEffect } from "react";
import SideMenu from "../../Components/SideMenu";
import OrganizationSaveIcon from "./OrganizationSaveIcon";
import { CustomRaisedButton } from "../../Components/CustomComponents/CustomButtons";
import { Path } from "../Path";
import {
  getSelectedOrganization,
  isSelectedOrganization,
} from "../../Store/SelectedOrganization/Selectors";

export default function OrganizationPage() {
  const { organizationId } = useParams<{ organizationId?: string }>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!organizationId) {
      dispatch(clearSelectedOrganization());
      return;
    }

    const organizationIdNumber = parseInt(organizationId);
    dispatch(selectOrganization(organizationIdNumber));
  }, [organizationId, dispatch]);

  const sideMenu = (
    <SideMenu title="עדכן פרטי ארגון">
      <OrganizationSaveIcon />
      <br />
      <br />
      <NewOrderButton />
    </SideMenu>
  );

  return (
    <Screen sideMenu={sideMenu}>
      <OrganizationSectionContainer organizationPage />

      <OrganizationsOrdersTable />
    </Screen>
  );
}

function NewOrderButton() {
  const navigate = useNavigate();
  const organizationSelected = useSelector(isSelectedOrganization);
  const selectedOrganization = useSelector(getSelectedOrganization);

  if (!organizationSelected) {
    return null;
  }
  return (
    <CustomRaisedButton
      label="הזמנה חדשה"
      onClick={() => {
        navigate(Path.Order + "/forOrg/" + selectedOrganization.id);
      }}
      variant={"outlined"}
      fullWidth
    />
  );
}
