import {
  purple,
  red,
  grey,
  indigo,
  orange,
  pink,
  lightBlue,
  green,
} from "@mui/material/colors";

export default {
  red: red[500],
  white: "white",
  black: "black",
  primary: "#7a2a8c",
  lightPurple: purple[600],
  veryLightPurple: purple[400],
  orderButtons: grey[600],

  organizationIconColor: indigo[600],
  orderIconColor: orange[500],
  publicCourseParticipantIconColor: red[800],

  infoBoxes: {
    orange: orange[600],
    pink: pink[600],
    lightBlue: lightBlue[700],
    green: green[500],
  },
};
