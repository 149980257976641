import HMLogo from "../Components/HMLogo";
import styles from "./LoadingPage.module.css";
import { CircularProgress } from "@mui/material";

export default function LoadingPage(props: { showSpinner?: boolean }) {
  return (
    <div className={styles.loadingScreenContainer}>
      <div>
        <HMLogo className={styles.iconImage} />

        {props.showSpinner && (
          <div className={styles.spinner}>
            <CircularProgress />
          </div>
        )}
      </div>
    </div>
  );
}
