import CustomSection from "../../../Components/CustomComponents/CustomSection";
import { Size } from "../../../Util/Constants/Size";
import { IPublicCourse } from "@violet/common";
import CustomTextField from "../../../Components/CustomComponents/CustomTextField";
import CustomCheckbox from "../../../Components/CustomComponents/CustomCheckbox";
import { useDispatch } from "react-redux";
import { updateSelectedPublicCourse } from "../../../Store/SelectedPublicCourse/Actions";
import styles from "./CourseDetailsSection.module.css";
import { PublicCourseField } from "../../../Store/Appearance/RequiredFields/FieldNames";

export default function CourseDetailsSection(props: CourseDetailsSectionProps) {
  const dispatch = useDispatch();

  const onChange = (key: PublicCourseField) => (value: string | boolean) =>
    dispatch(updateSelectedPublicCourse(key, value));

  return (
    <CustomSection title="פרטי הקורס">
      <div className={styles.section}>
        <div className={styles.row}>
          <CustomTextField
            field={PublicCourseField.courseName}
            valueObject={props.selectedPublicCourse}
            onChange={onChange(PublicCourseField.courseName)}
            size={Size.XL}
          />
          <CustomCheckbox
            field={PublicCourseField.onlineCourse}
            valueObject={props.selectedPublicCourse}
            onChange={onChange(PublicCourseField.onlineCourse)}
          />
          <CustomCheckbox
            field={PublicCourseField.isoPayed}
            valueObject={props.selectedPublicCourse}
            onChange={onChange(PublicCourseField.isoPayed)}
          />
        </div>

        {!props.selectedPublicCourse.onlineCourse && (
          <div className={styles.row}>
            <CustomTextField
              field={PublicCourseField.courseCity}
              valueObject={props.selectedPublicCourse}
              onChange={onChange(PublicCourseField.courseCity)}
            />
            <CustomTextField
              field={PublicCourseField.courseLocation}
              valueObject={props.selectedPublicCourse}
              onChange={onChange(PublicCourseField.courseLocation)}
            />
            <CustomTextField
              field={PublicCourseField.mealCost}
              valueObject={props.selectedPublicCourse}
              onChange={onChange(PublicCourseField.mealCost)}
            />
            <CustomTextField
              field={PublicCourseField.distanceCost}
              valueObject={props.selectedPublicCourse}
              onChange={onChange(PublicCourseField.distanceCost)}
            />
            <CustomCheckbox
              field={PublicCourseField.roomsApproved}
              valueObject={props.selectedPublicCourse}
              onChange={onChange(PublicCourseField.roomsApproved)}
            />

            <CustomCheckbox
              field={PublicCourseField.printedMaterials}
              valueObject={props.selectedPublicCourse}
              onChange={onChange(PublicCourseField.printedMaterials)}
            />
            <CustomCheckbox
              field={PublicCourseField.printedCertificates}
              valueObject={props.selectedPublicCourse}
              onChange={onChange(PublicCourseField.printedCertificates)}
            />
          </div>
        )}
      </div>
    </CustomSection>
  );
}

interface CourseDetailsSectionProps {
  selectedPublicCourse: IPublicCourse;
}
