import React from "react";

export default React.createContext<[boolean, (mobileView: boolean) => void]>([
  false,
  (mobileView: boolean) => {},
]);

export const StickMenuContext = React.createContext<
  [boolean, (isStickyMenu: boolean) => void]
>([false, (sticky: boolean) => {}]);

export function isMobile() {
  return window.innerWidth <= 700;
}

export function isMenuSticky() {
  return window.innerWidth < 1300;
}
