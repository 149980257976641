import { connect } from "react-redux";
import * as _ from "lodash";
import { getOrdersByOrganization } from "../../../../Store/Orders/Selectors";
import { getValueOrEmptyString } from "../../../../Util/StringUtil";
import ImportContactDialog from "./ImportContactDialog";
import { IDispatch, IState } from "../../../../Interfaces/ReduxInterfaces";
import { IOrder, IStringObject } from "@violet/common";
import { updateSelectedOrder } from "../../../../Store/SelectedOrder/Actions";
import {
  ContactField,
  getContactField,
  OrderField,
} from "../../../../Store/Appearance/RequiredFields/FieldNames";

export interface IContact extends IStringObject {
  contactFirstName: string;
  contactLastName: string;
  contactPhone1: string;
  contactPhone2: string;
  contactPhoneExtension: string;
  contactEmail: string;
  contactFax: string;
  contactJob: string;
}

function getContacts(state: IState) {
  const allContacts: IContact[] = [];
  const addedContacts: { [key: string]: boolean } = {};
  const orders = getOrdersByOrganization(state);
  if (!orders) return [];

  // Show contacts from recent orders first
  const sortedOrders = _.sortBy(orders, (x) => -x.id);

  for (let index = 0; index < sortedOrders.length; index++) {
    addSingleContact(sortedOrders[index], allContacts, addedContacts, true);
    addSingleContact(sortedOrders[index], allContacts, addedContacts, false);
  }
  return _.map(allContacts, (x) => x);
}

function addSingleContact(
  order: IOrder,
  allContacts: IContact[],
  addedContacts: { [key: string]: boolean },
  isFinancialContacts: boolean
) {
  const thisContact = getContactsFromOrder(order, isFinancialContacts);
  const thisContactKey =
    thisContact.contactFirstName.trim() + thisContact.contactLastName.trim();
  if (thisContactKey === "" || addedContacts.hasOwnProperty(thisContactKey))
    return;

  allContacts.push(thisContact);
  addedContacts[thisContactKey] = true;
}

function getContactsFromOrder(order: IOrder, isFinancial: boolean): IContact {
  return {
    contactFirstName: getValueOrEmptyString(
      order,
      getContactField(OrderField.contactFirstName, isFinancial)
    ),
    contactLastName: getValueOrEmptyString(
      order,
      getContactField(OrderField.contactLastName, isFinancial)
    ),
    contactPhone1: getValueOrEmptyString(
      order,
      getContactField(OrderField.contactPhone1, isFinancial)
    ),
    contactPhone2: getValueOrEmptyString(
      order,
      getContactField(OrderField.contactPhone2, isFinancial)
    ),
    contactPhoneExtension: getValueOrEmptyString(
      order,
      getContactField(OrderField.contactPhoneExtension, isFinancial)
    ),
    contactEmail: getValueOrEmptyString(
      order,
      getContactField(OrderField.contactEmail, isFinancial)
    ),
    contactFax: getValueOrEmptyString(
      order,
      getContactField(OrderField.contactFax, isFinancial)
    ),
    contactJob: getValueOrEmptyString(
      order,
      getContactField(OrderField.contactJob, isFinancial)
    ),
  };
}

// TODO add tests
function importContact(
  contact: IContact,
  updateSelectedOrder: (field: OrderField, value: string) => void,
  onRequestClose: () => void,
  isFinancial: boolean
) {
  onRequestClose();

  const fieldsToUpdate: ContactField[] = [
    OrderField.contactFirstName,
    OrderField.contactLastName,
    OrderField.contactPhone1,
    OrderField.contactPhone2,
    OrderField.contactPhoneExtension,
    OrderField.contactEmail,
    OrderField.contactFax,
    OrderField.contactJob,
  ];

  fieldsToUpdate.forEach((field) =>
    updateSelectedOrder(getContactField(field, isFinancial), contact[field])
  );
}

function mapStateToProps(
  state: IState,
  ownProps: ImportContactDialogContainerProps
) {
  return {
    onRequestClose: ownProps.onRequestClose,
    contacts: getContacts(state),
    tableHeaders: [
      { pick: "בחר" },
      { contactFirstName: "שם פרטי" },
      { contactLastName: "שם משפחה" },
      { contactJob: "תפקיד" },
      { contactPhone1: "טלפון" },
      { contactEmail: "דואר אלקטרוני" },
    ] as IStringObject[],
  };
}

function mapDispatchToProps(
  dispatch: IDispatch,
  ownProps: ImportContactDialogContainerProps
) {
  const updateOrder = (key: OrderField, value: string) => {
    dispatch(updateSelectedOrder(key, value));
  };
  return {
    importContact: (contact: IContact) =>
      importContact(
        contact,
        updateOrder,
        ownProps.onRequestClose,
        ownProps.isFinancialContacts
      ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImportContactDialog);

interface ImportContactDialogContainerProps {
  dialogOpen: boolean;
  isFinancialContacts: boolean;
  onRequestClose: () => void;
}
