import CustomPaperTable from "../Components/Table/CustomPaperTable";
import { EntityType } from "../Util/Constants/EntityType";
import { getOrderPath } from "./Path";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { getFutureLectureTimes } from "../Store/Orders/Selectors";

export default function PhonePage() {
  const navigate = useNavigate();
  const futureLectures = useSelector(getFutureLectureTimes).filter(
    (x) => x.entityType === EntityType.order
  );

  return (
    <CustomPaperTable
      title="הרצאות עתידיות"
      elements={futureLectures}
      tableHeaders={[
        { orderId: "מספר הזמנה" },
        { date: "תאריך הרצאה" },
        { topic: "נושא" },
        { organizationName: "שם הארגון" },
      ]}
      onEditButton={(summary) => {
        navigate(getOrderPath(summary.id));
      }}
    />
  );
}
