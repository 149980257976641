/*eslint no-console: ["error", { allow: ["error"] }] */

import * as actionTypes from "./ActionTypes";
import { receiveOrganizations } from "../Organizations/Actions";
import { receiveOrders } from "../Orders/Actions";
import { receiveLists } from "../Lists/Actions";
import { receivePublicCourses } from "../PublicCourses/Actions";
import { isLoggedIn } from "./Selectors";
import { IDispatch, IGetState } from "../../Interfaces/ReduxInterfaces";
import { AnyAction } from "redux";
import { COLLECTION } from "@violet/common";
import {
  getAuth,
  onAuthStateChanged,
  User,
  signOut,
  signInWithRedirect,
  GoogleAuthProvider,
} from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, onValue } from "firebase/database";

const isAlonEnvironment = process.env.REACT_APP_ALON_ENVIRONMENT;

const firebaseProductionConfig = {
  apiKey: "AIzaSyBYLZaVfwMoWhCBzvhO8qJjC-CzqRceR0c",
  authDomain: "violet-36bed.firebaseapp.com",
  databaseURL: "https://violet-36bed.firebaseio.com",
  projectId: "violet-36bed",
  storageBucket: "violet-36bed.appspot.com",
  messagingSenderId: "259015014878",
};

const firebaseDevelopmentConfig = {
  apiKey: "AIzaSyB0hDCH-PoE-nAXVoP4Ba6gNYAfMDufajE",
  authDomain: "violet-dev.firebaseapp.com",
  databaseURL: "https://violet-dev.firebaseio.com",
  projectId: "violet-dev",
  storageBucket: "violet-dev.appspot.com",
  messagingSenderId: "97871190058",
};

const firebaseAlonConfig = {
  apiKey: "AIzaSyBkmmazrxdhkbhu-M05IETloEzRJzfeIz4",
  authDomain: "violet-alon.firebaseapp.com",
  projectId: "violet-alon",
  storageBucket: "violet-alon.appspot.com",
  messagingSenderId: "762876257489",
  appId: "1:762876257489:web:b933d48a3a57a01aa8d1f0",
};

// const firebaseDemoConfig = {
//     apiKey: "AIzaSyAAHuLkm30DwFxBiFEvfO0ejiBEFAvfhTQ",
//     authDomain: "violet-demo.firebaseapp.com",
//     databaseURL: "https://violet-demo.firebaseio.com",
//     projectId: "violet-demo",
//     storageBucket: "violet-demo.appspot.com",
//     messagingSenderId: "425792520023"
// };

const firebaseConfig =
  process.env.NODE_ENV === "production"
    ? isAlonEnvironment
      ? firebaseAlonConfig
      : firebaseProductionConfig
    : firebaseDevelopmentConfig;

export function initFirebase() {
  return async function initFirebase(dispatch: IDispatch) {
    const promise = initializeApp(firebaseConfig);

    onAuthStateChanged(getAuth(), (user) => {
      if (user === null) {
        dispatch({ type: actionTypes.LOGGED_OUT });
      } else {
        dispatch(afterSignedIn(user));
      }
    });

    return promise;
  };
}

export function signInWithGoogle(errorCallback: (message: string) => void) {
  const provider = new GoogleAuthProvider();

  signInWithRedirect(getAuth(), provider).catch(function (error) {
    errorCallback(error.message);
    console.error(error);
  });
}

export function afterSignedIn(user: User) {
  return function afterSignedIn(dispatch: IDispatch) {
    dispatch({
      type: actionTypes.LOGGED_IN,
      userId: user.uid,
      displayName: user.displayName,
      photoURL: user.photoURL,
      isSuperUser: isSuperUser(user.email),
    });
    dispatch(fetchData(COLLECTION.ORGANIZATIONS, receiveOrganizations));
    dispatch(fetchData(COLLECTION.ORDERS, receiveOrders));
    dispatch(fetchData(COLLECTION.PUBLIC_COURSES, receivePublicCourses));
    dispatch(fetchData(COLLECTION.LISTS, receiveLists));
  };
}

function isSuperUser(email: string | null) {
  if (!email) {
    return false;
  }

  if (email.toLowerCase().includes("asaf")) {
    return false;
  }

  if (email.toLowerCase().includes("office")) {
    return false;
  }

  return true;
}

export function signOutRequest() {
  return async function signOutRequest(
    dispatch: IDispatch,
    getState: IGetState
  ) {
    if (!isLoggedIn(getState())) return;

    signOut(getAuth())
      .then(() => {
        dispatch({ type: actionTypes.LOGGED_OUT });
      })
      .catch(function (error) {
        console.error(error);
      });
  };
}

export function fetchData(
  collectionName: string,
  actionCallback: (val: any) => AnyAction
) {
  return function afterSignedIn(dispatch: IDispatch) {
    const db = getDatabase();
    onValue(
      ref(db, collectionName),
      (snapshot) => {
        if (snapshot !== null) dispatch(actionCallback(snapshot.val()));
      },
      (error: any) => {
        console.error(
          "The request for " + collectionName + " failed: " + error.code
        );
      }
    );
  };
}

export function sendDataToDatabase(collectionPath: string, value: any) {
  const db = getDatabase();
  return set(ref(db, collectionPath), value);
}
