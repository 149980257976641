import { useState } from "react";
import SignInWithGoogleButton from "./SignInWithGoogleButton";
import styles from "./LoginPage.module.css";

export default function LoginPage() {
  const [error, setError] = useState("");

  return (
    <div className={styles.container}>
      <div className={styles.title}>התחבר למערכת</div>

      <SignInWithGoogleButton errorCallback={setError} />

      <div>{error}</div>
    </div>
  );
}
