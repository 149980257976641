import { useDispatch, useSelector } from "react-redux";
import { OrderField } from "../../../../Store/Appearance/RequiredFields/FieldNames";
import { getOrderRequiredFields } from "../../../../Store/Appearance/RequiredFields/RequiredFieldsSelectors";
import { updateSelectedOrder } from "../../../../Store/SelectedOrder/Actions";
import { getSelectedOrder } from "../../../../Store/SelectedOrder/Selectors";
import { Size } from "../../../../Util/Constants/Size";
import CustomTextField from "../../../../Components/CustomComponents/CustomTextField";

interface OrderTextFieldProps {
  field: OrderField;
  fullWidth?: boolean;
  size?: Size;
  disabled?: boolean;
  type?: string;
  singelLine?: boolean;
}

export default function OrderTextField(props: OrderTextFieldProps) {
  const dispatch = useDispatch();
  const selectedOrder = useSelector(getSelectedOrder);
  const requiredFields = useSelector(getOrderRequiredFields);

  return (
    <CustomTextField
      field={props.field}
      valueObject={selectedOrder}
      requiredFields={requiredFields}
      onChange={(newValue) =>
        dispatch(updateSelectedOrder(props.field, newValue))
      }
      fullWidth={props.fullWidth}
      size={props.size}
      disabled={props.disabled}
      type={props.type}
      singelLine={props.singelLine}
    />
  );
}
