import _ from "lodash";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { getStatusLabel, progressiveStatuses, Status } from "@violet/common";
import { useSelector } from "react-redux";
import { getSelectedOrder } from "../../Store/SelectedOrder/Selectors";
import styles from "./OrderStatusStepper.module.css";

export default function OrderStatusStepper() {
  const selectedOrderStatus = getStatus(useSelector(getSelectedOrder).status);

  return (
    <div className={styles.stepperContainer}>
      <Stepper className={styles.stepper}>
        {_.map(progressiveStatuses, (status: string) => (
          <Step key={status} active={status === selectedOrderStatus}>
            <StepLabel>{getStatusLabel(status as Status)}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}

function getStatus(status: Status) {
  return status ? status : Status.contact;
}
