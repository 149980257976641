import { useDispatch, useSelector } from "react-redux";
import {
  selectOrganization,
  updateSelectedOrganization,
} from "../../../../Store/SelectedOrganization/Actions";
import { getOrganizations } from "../../../../Store/Organizations/Selectors";
import OrganizationSection, {
  IOrganizationSuggestion,
} from "./OrganizationSection";
import { getReferralWays } from "../../../../Store/Lists/Selectors";
import { createOptions } from "../../../../Components/CustomComponents/CustomSelectField";
import { getSelectedOrganization } from "../../../../Store/SelectedOrganization/Selectors";
import { getRequiredFieldsObject } from "../../../../Store/Appearance/RequiredFields/RequiredFieldsSelectors";
import { IOrganization } from "@violet/common";
import { isSelectedOrder } from "../../../../Store/SelectedOrder/Selectors";

export default function OrganizationSectionContainer(props: {
  organizationPage: boolean;
}) {
  const dispatch = useDispatch();

  const organization = useSelector(getSelectedOrganization);
  const organizationRequiredFields = useSelector(
    getRequiredFieldsObject
  ).organization;
  const referralWayOptions = createOptions(useSelector(getReferralWays));
  const allOrganizations = useSelector(getOrganizations);

  const organizationSuggestions = useSelector(getOrganizations).map(
    toOrganizationSuggestion
  );
  const isOrderSelected = useSelector(isSelectedOrder);

  const organizationIdAlreadyExists =
    !!organization.companyId &&
    allOrganizations.filter(
      (org) =>
        org.id !== organization.id && org.companyId === organization.companyId
    ).length > 0;

  return (
    <OrganizationSection
      organizationPage={props.organizationPage}
      organization={organization}
      requiredFields={organizationRequiredFields}
      referralWayOptions={referralWayOptions}
      organizationSuggestions={organizationSuggestions}
      isOrderSelected={isOrderSelected}
      organizationIdAlreadyExists={organizationIdAlreadyExists}
      selectOrganization={(organizationId) =>
        dispatch(selectOrganization(organizationId))
      }
      onOrganizationChange={(key) => (value) =>
        dispatch(updateSelectedOrganization(key, value))}
    />
  );
}

function toOrganizationSuggestion(
  organization: IOrganization
): IOrganizationSuggestion {
  return {
    key: "org." + organization.id,
    label: organization.organizationName,
    organizationId: organization.id,
  };
}
