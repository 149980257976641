import { useSelector } from "react-redux";
import CustomPaperTable from "../../../Components/Table/CustomPaperTable";
import { getSelectedPublicCourseParticipants } from "../../../Store/SelectedPublicCourse/Selectors";
import { getOrderPath } from "../../Path";
import { useNavigate } from "react-router";
import { IState } from "../../../Interfaces/ReduxInterfaces";

export default function CourseParticipantsTableContainer(props: {
  approved: boolean;
}) {
  const navigate = useNavigate();

  const elements = useSelector((state: IState) =>
    getSelectedPublicCourseParticipants(state, props.approved)
  );

  if (elements.length === 0) {
    return null;
  }

  return (
    <CustomPaperTable
      title={props.approved ? "משתתפים מאושרים" : "משתתפים שטרם אישרו"}
      elements={elements}
      tableHeaders={[
        { id: "מספר הזמנה" },
        { participantFirstName: "שם פרטי" },
        { participantLastName: "שם משפחה" },
        { organizationName: "ארגון" },
        { status: "סטאטוס" },
        { proformaInvoiceNumber: "חשבונית עסקה" },
        { numberOfLecturesAttending: "ימים" },
        { participantCost: "מחיר השתתפות" },
      ]}
      onEditButton={(summary) => {
        navigate(getOrderPath(summary.id));
      }}
    />
  );
}
