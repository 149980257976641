import Avatar from "@mui/material/Avatar";

export default function IndexAvatar(props: IndexAvatarProps) {
  return (
    <Avatar
      style={{
        margin: "10px 20px",
      }}
    >
      {props.index}
    </Avatar>
  );
}

interface IndexAvatarProps {
  index: number;
}
