import { IState } from "../../Interfaces/ReduxInterfaces";
import { toMutable } from "../../Util/ObjectUpdater";
import _ from "lodash";
import { OfferTemplate } from "../../Interfaces/ILists";

function getLists(state: IState) {
  return toMutable(state.lists);
}

export function getOfferTemplates(state: IState) {
  return getLists(state).offerTemplates;
}

export function getOfferedLectures(state: IState) {
  const templates = getOfferTemplates(state);
  return _.keys(templates);
}

export function getRejectionReasons(state: IState) {
  return _.values(getLists(state).rejectionReasons);
}

export function getCancellationReasons(state: IState) {
  return _.values(getLists(state).cancellationReasons);
}

export function getReferralWays(state: IState) {
  return _.values(getLists(state).referralWays).sort();
}

export function getTies(state: IState) {
  return _.keys(getLists(state).ties).sort();
}

export function getOfferTemplate(
  state: IState,
  topic: string
): OfferTemplate | undefined {
  return getLists(state).offerTemplates[topic] || undefined;
}
