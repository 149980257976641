import OrderPageSections from "./Sections";
import OrderPageSideMenu from "./OrderPageSideMenu";
import OrderStatusStepper from "./OrderStatusStepper";
import styles from "./OrderPage.module.css";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { useContext, useEffect } from "react";
import {
  clearSelectedOrder,
  selectOrder,
} from "../../Store/SelectedOrder/Actions";
import {
  clearSelectedOrganization,
  selectOrganization,
} from "../../Store/SelectedOrganization/Actions";
import Screen from "../../Components/Screen";
import MobileViewContext from "../../Navigation/MobileViewContext";

export default function OrderPage() {
  const { orderId, organizationId } = useParams();
  const dispatch = useDispatch();
  const [mobileView] = useContext(MobileViewContext);

  useEffect(() => {
    if (!orderId) {
      dispatch(clearSelectedOrder());
      if (organizationId) {
        // New order with a pre-set organization
        dispatch(selectOrganization(parseInt(organizationId)));
      } else {
        dispatch(clearSelectedOrganization());
      }

      return;
    }

    const orderIdNumber = parseInt(orderId);
    dispatch(selectOrder(orderIdNumber));
  }, [orderId, dispatch, organizationId]);

  return (
    <Screen sideMenu={<OrderPageSideMenu />}>
      <div className={mobileView ? "" : styles.content}>
        {!mobileView && <OrderStatusStepper />}

        <OrderPageSections />
      </div>
    </Screen>
  );
}
