import CustomSection from "../../../../Components/CustomComponents/CustomSection";
import { OrderField } from "../../../../Store/Appearance/RequiredFields/FieldNames";
import OrderTextField from "../ConnectedCustomComponents/OrderTextField";
import styles from "./NotesSection.module.css";
import UploadFileButton from "../../../../Components/CustomComponents/UploadFileButton";
import { useContext, useState } from "react";
import * as Storage from "../../../../firebase/Storage";
import {
  OrderFile,
  StorageFolder,
  toFullPrintableDateFormat,
} from "@violet/common";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedOrder } from "../../../../Store/SelectedOrder/Selectors";
import { updateSelectedOrder } from "../../../../Store/SelectedOrder/Actions";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  CustomFlatButton,
  CustomIconButton,
} from "../../../../Components/CustomComponents/CustomButtons";
import _ from "lodash";
import { closeDialog, openDialog } from "../../../../Store/Appearance/Actions";
import MobileViewContext from "../../../../Navigation/MobileViewContext";

const showFiles = false;

export default function NotesSection() {
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [mobile] = useContext(MobileViewContext);
  const selectedOrder = useSelector(getSelectedOrder);
  const files: OrderFile[] = selectedOrder.orderFiles || [];
  const dispatch = useDispatch();

  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const folder = StorageFolder.ORDERS + "/" + selectedOrder.id;

  const onUploadButtonClicked = (file: File) => {
    Storage.uploadFile(
      file,
      folder,
      file.name,
      setMessage,
      setError,
      async (downloadUrl) => {
        const newFiles: OrderFile[] = [
          ...files,
          {
            fileNmae: file.name,
            downloadUrl: downloadUrl,
            uploadTimeMillis: new Date().getTime(),
          },
        ];

        dispatch(updateSelectedOrder(OrderField.orderFiles, newFiles));
      }
    );
  };

  const deleteFile = (file: OrderFile) => {
    dispatch(closeDialog());
    const newFiles = [...files];
    _.remove(newFiles, (f) => f.downloadUrl === file.downloadUrl);
    Storage.deleteFile(folder, file.fileNmae).then(() =>
      dispatch(updateSelectedOrder(OrderField.orderFiles, newFiles))
    );
  };

  if (mobile) {
    return (
      <CustomSection title="פרטים נוספים">
        <OrderTextField field={OrderField.notes} />
      </CustomSection>
    );
  }

  if (!showFiles) {
    return (
      <CustomSection title="פרטים נוספים">
        <div className={styles.section}>
          <div className={styles.notes}>
            <OrderTextField field={OrderField.notes} fullWidth />
          </div>
        </div>
      </CustomSection>
    );
  }

  return (
    <CustomSection title="פרטים נוספים">
      <div className={styles.section}>
        <div className={styles.notes}>
          <OrderTextField field={OrderField.notes} fullWidth />
        </div>

        <UploadFileButton
          onUploadButtonClicked={onUploadButtonClicked}
          message={message}
          setMessage={setMessage}
          error={error}
          setError={setError}
        />

        <ul>
          {files.map((file) => (
            <li key={file.downloadUrl}>
              <CustomIconButton
                tooltip="מחק קובץ"
                onClick={() => {
                  dispatch(
                    openDialog("מחיקת " + file.fileNmae, "האם אתה בטוח?", [
                      <CustomFlatButton
                        key="מחק קובץ"
                        label="מחק קובץ"
                        onClick={() => deleteFile(file)}
                      />,
                      <CustomFlatButton
                        key="ביטול"
                        label="ביטול"
                        onClick={() => dispatch(closeDialog())}
                      />,
                    ])
                  );
                }}
              >
                <DeleteIcon />
              </CustomIconButton>
              <span
                onClick={() => openInNewTab(file.downloadUrl)}
                className={styles.fileName}
              >
                {file.fileNmae}
              </span>
              {" זמן העלאה: "}
              {toFullPrintableDateFormat(new Date(file.uploadTimeMillis))}
            </li>
          ))}
        </ul>
      </div>
    </CustomSection>
  );
}
