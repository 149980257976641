import {
  LectureTimeField,
  OrderField,
  OrganizationField,
  PublicCourseParticipantField,
} from "./FieldNames";
import { IRequiredFields, mergerRequiredFields } from "./Util";

const contact: IRequiredFields = {
  order: [
    OrderField.contactFirstName,
    OrderField.contactLastName,
    OrderField.contactPhone1,
    OrderField.contactEmail,
  ],
  organization: [OrganizationField.organizationName],
  internalOrderLectureTime: [],
  onlineCourseLectureTime: [],
  internalOrder: [],
  publicCourse: [],
};

const offer = mergerRequiredFields(contact, {
  internalOrderLectureTime: [LectureTimeField.topic],
  onlineCourseLectureTime: [LectureTimeField.topic],
});

const order = mergerRequiredFields(offer, {
  internalOrderLectureTime: [
    LectureTimeField.date,
    LectureTimeField.startTime,
    LectureTimeField.endTime,
    LectureTimeField.duration,
  ],
  onlineCourseLectureTime: [
    LectureTimeField.date,
    LectureTimeField.startTime,
    LectureTimeField.endTime,
    LectureTimeField.duration,
  ],
  publicCourse: [
    PublicCourseParticipantField.participantFirstName,
    PublicCourseParticipantField.participantLastName,
  ],
});

const approvedOrder = mergerRequiredFields(order, {
  organization: [
    OrganizationField.companyId,
    OrganizationField.paymentConditions,
  ],
  order: [
    OrderField.financialContactFirstName,
    OrderField.financialContactLastName,
    OrderField.financialContactPhone1,
    OrderField.financialContactEmail,
    OrderField.cost,
    OrderField.totalSum,
    OrderField.internalOrderNumber,
  ],
  internalOrder: [
    OrderField.street,
    OrderField.city,
    OrderField.parking,
    OrderField.projector,
    OrderField.soundSystem,
    OrderField.microphone,
  ],
  onlineCourseLectureTime: [LectureTimeField.videoCallLink],
  publicCourse: [
    PublicCourseParticipantField.idNumber,
    PublicCourseParticipantField.phone,
    PublicCourseParticipantField.email,
  ],
});

const isExecuting = mergerRequiredFields(approvedOrder);

const executed = mergerRequiredFields(isExecuting);

const waitingPayment = mergerRequiredFields(executed, {
  order: [OrderField.expectedPayDate, OrderField.externalInvoiceSent],
});

const payed = mergerRequiredFields(waitingPayment);

const cancelled: IRequiredFields = {
  order: [OrderField.cancellationReason],
  organization: [],
  internalOrderLectureTime: [],
  onlineCourseLectureTime: [],
  internalOrder: [],
  publicCourse: [],
};

const rejected: IRequiredFields = {
  order: [OrderField.rejectionReason],
  organization: [],
  internalOrderLectureTime: [],
  onlineCourseLectureTime: [],
  internalOrder: [],
  publicCourse: [],
};

const followUpRequired: IRequiredFields = {
  order: [OrderField.followUpDate, OrderField.followUpDetails],
  organization: [],
  internalOrderLectureTime: [],
  onlineCourseLectureTime: [],
  internalOrder: [],
  publicCourse: [],
};

export default {
  contact,
  offer,
  order,
  approvedOrder,
  isExecuting,
  executed,
  waitingPayment,
  payed,
  cancelled,
  rejected,
  followUpRequired,
};
