import React from "react";
import * as _ from "lodash";
import CustomTable from "./CustomTable";
import CustomTableRow from "./CustomTableRow";
import { IStringObject } from "@violet/common";
import CustomSection from "../CustomComponents/CustomSection";

interface CustomPaperTableProps<TElement extends object> {
  title?: string;
  elements: TElement[];
  limit?: number;
  tableHeaders: IStringObject[];
  onEditButton: (element: TElement) => void;
  beforeTable?: React.ReactNode;
}

export default function CustomPaperTable<TElement extends object>(
  props: CustomPaperTableProps<TElement>
) {
  let elements = props.elements;

  if (props.limit !== undefined && props.limit !== -1) {
    elements = _.slice(elements, 0, props.limit);
  }

  return (
    <CustomSection title={props.title}>
      {props.beforeTable}

      <CustomTable headers={props.tableHeaders}>
        {elements.map((element, index) => (
          <CustomTableRow
            key={index}
            index={index}
            headers={props.tableHeaders}
            element={element}
            onEditButton={props.onEditButton}
          />
        ))}
      </CustomTable>
    </CustomSection>
  );
}
