export enum OrganizationField {
  organizationName = "organizationName",
  organizationAddress = "organizationAddress",
  organizationCity = "organizationCity",
  organizationPostalCode = "organizationPostalCode",
  companyId = "companyId",
  paymentConditions = "paymentConditions",
  externalInvoiceHandler = "externalInvoiceHandler",
  referralWay = "referralWay",
  referralWayDetails = "referralWayDetails",
  internalOrderIdRequired = "internalOrderIdRequired",
  organizationNotes = "organizationNotes",
}

export enum OrderField {
  status = "status",
  contactFirstName = "contactFirstName",
  contactLastName = "contactLastName",
  contactPhone1 = "contactPhone1",
  contactPhone2 = "contactPhone2",
  contactEmail = "contactEmail",
  contactJob = "contactJob",
  contactPhoneExtension = "contactPhoneExtension",
  contactFax = "contactFax",

  street = "street",
  streetNumber = "streetNumber",
  city = "city",
  location = "location",
  audienceType = "audienceType",
  daySchedule = "daySchedule",

  projector = "projector",
  soundSystem = "soundSystem",
  microphone = "microphone",
  parking = "parking",
  orderApproved = "orderApproved",
  sameAudience = "sameAudience",
  rejected = "rejected",
  rejectionReason = "rejectionReason",
  rejectionDetails = "rejectionDetails",
  cancelled = "cancelled",
  cancellationReason = "cancellationReason",
  cancellationDetails = "cancellationDetails",

  // Public course participants
  publicCourseId = "publicCourseId",

  // Follow up
  followUpRequired = "followUpRequired",
  followUpDate = "followUpDate",
  followUpDetails = "followUpDetails",

  // Payment
  cost = "cost",
  oneWayDistance = "oneWayDistance",
  travelExpenses = "travelExpenses",
  extraCosts = "extraCosts",
  sum = "sum",
  vat = "vat",
  totalSum = "totalSum",

  // Financial Contact
  financialContactFirstName = "financialContactFirstName",
  financialContactLastName = "financialContactLastName",
  financialContactPhone1 = "financialContactPhone1",
  financialContactPhone2 = "financialContactPhone2",
  financialContactEmail = "financialContactEmail",
  financialContactJob = "financialContactJob",
  financialContactPhoneExtension = "financialContactPhoneExtension",
  financialContactFax = "financialContactFax",

  // Invoice
  proformaInvoiceNumber = "proformaInvoiceNumber",
  proformaInvoiceDate = "proformaInvoiceDate",
  expectedPayDate = "expectedPayDate",
  internalOrderNumber = "internalOrderNumber",
  externalInvoiceSent = "externalInvoiceSent",
  taxInvoiceNumber = "taxInvoiceNumber",
  taxInvoiceDate = "taxInvoiceDate",
  receiptNumber = "receiptNumber",

  // Notes
  notes = "notes",

  // Not editable fields
  id = "id",
  organizationId = "organizationId",
  lectureTimes = "lectureTimes",
  orderFiles = "orderFiles",
  changedDate = "changedDate",
  createdDate = "createdDate",
}

export enum LectureTimeField {
  topic = "topic",
  tie = "tie",
  date = "date",
  startTime = "startTime",
  endTime = "endTime",
  duration = "duration",
  audienceSize = "audienceSize",
  travelTime = "travelTime",
  videoCallLink = "videoCallLink",
  offerDate = "offerDate",
  subject = "subject",
  content = "content",
  offerCost = "offerCost",
  noTravelCost = "noTravelCost",
  paymentConditionInfo = "paymentConditionInfo",
  wakeUpTime = "wakeUpTime",
  leaveHomeTime = "leaveHomeTime",
  arriveTime = "arriveTime",
  lectureStartTime = "lectureStartTime",
}

export enum PublicCourseField {
  courseName = "courseName",
  onlineCourse = "onlineCourse",
  courseLocation = "courseLocation",
  courseCity = "courseCity",
  mealCost = "mealCost",
  distanceCost = "distanceCost",
  isoPayed = "isoPayed",
  roomsApproved = "roomsApproved",
  printedCertificates = "printedCertificates",
  printedMaterials = "printedMaterials",
}

export enum PublicCourseLectureField {
  topic = "topic",
  tie = "tie",
  date = "date",
  startTime = "startTime",
  endTime = "endTime",
  duration = "duration",
  price = "price",
  pages = "pages",
  roomCost = "roomCost",
  videoCallLink = "videoCallLink",
  active = "active",
  guestLecturer = "guestLecturer",
  guestLecturerName = "guestLecturerName",
  guestLecturerCost = "guestLecturerCost",
}

export enum PublicCourseParticipantField {
  participantFirstName = "participantFirstName",
  participantLastName = "participantLastName",
  participantEnglishFirstName = "participantEnglishFirstName",
  participantEnglishLastName = "participantEnglishLastName",
  idNumber = "idNumber",
  phone = "phone",
  email = "email",
  job = "job",
  participantCost = "participantCost",
  isqMember = "isqMember",
}

export enum CourseLecturesInstanceFiled {
  topic = "topic",
  tie = "tie",
  active = "active",
}

export function getFieldLabel(field: Field) {
  switch (field) {
    case OrderField.status:
      return "סטאטוס";
    case OrderField.contactFirstName:
      return "שם פרטי";
    case OrderField.contactLastName:
      return "שם משפחה";
    case OrderField.contactPhone1:
      return "טלפון";
    case OrderField.contactPhone2:
      return "טלפון נוסף";
    case OrderField.contactEmail:
      return "דואר אלקטרוני";
    case OrderField.contactJob:
      return "תפקיד";
    case OrderField.contactPhoneExtension:
      return "שלוחה";
    case OrderField.contactFax:
      return "פקס";
    case OrderField.street:
      return "רחוב";
    case OrderField.streetNumber:
      return "מספר";
    case OrderField.city:
      return "עיר";
    case OrderField.location:
      return "מיקום";
    case OrderField.audienceType:
      return "קהל יעד";
    case OrderField.daySchedule:
      return "מהות היום + לו״ז";
    case OrderField.projector:
      return "מקרן";
    case OrderField.soundSystem:
      return "מערכת הגברה";
    case OrderField.microphone:
      return "מיקרופון דש";
    case OrderField.parking:
      return "חניה";
    case OrderField.orderApproved:
      return "הזמנה אושרה";
    case OrderField.sameAudience:
      return "קהל יעד זהה";
    case OrderField.rejected:
      return "הזמנה לא יצאה לפועל";
    case OrderField.rejectionReason:
      return "סיבת דחיה";
    case OrderField.rejectionDetails:
      return "פרטי הדחיה";
    case OrderField.cancelled:
      return "הזמנה בוטלה";
    case OrderField.cancellationReason:
      return "סיבת ביטול";
    case OrderField.cancellationDetails:
      return "פרטי ביטול";
    case OrderField.followUpRequired:
      return "נדרש המשך טיפול";
    case OrderField.followUpDate:
      return "תאריך המשך טיפול";
    case OrderField.followUpDetails:
      return "פרטים";
    case OrderField.cost:
      return "מחיר הרצאות";
    case OrderField.oneWayDistance:
      return "מרחק כיוון אחד";
    case OrderField.travelExpenses:
      return "עלות נסיעות";
    case OrderField.extraCosts:
      return "עלויות נוספות";
    case OrderField.sum:
      return "סכום לפני מע״מ";
    case OrderField.vat:
      return "מע״מ";
    case OrderField.totalSum:
      return "סה״כ לתשלום";
    case OrderField.financialContactFirstName:
      return "שם פרטי";
    case OrderField.financialContactLastName:
      return "שם משפחה";
    case OrderField.financialContactPhone1:
      return "טלפון";
    case OrderField.financialContactPhone2:
      return "טלפון נוסף";
    case OrderField.financialContactEmail:
      return "דואר אלקטרוני";
    case OrderField.financialContactJob:
      return "תפקיד";
    case OrderField.financialContactPhoneExtension:
      return "שלוחה";
    case OrderField.financialContactFax:
      return "פקס";
    case OrderField.proformaInvoiceNumber:
      return "מספר חשבונית עסקה";
    case OrderField.proformaInvoiceDate:
      return "תאריך חשבונית עסקה";
    case OrderField.expectedPayDate:
      return "תאריך לתשלום";
    case OrderField.internalOrderNumber:
      return "מספר הזמנת רכש";
    case OrderField.externalInvoiceSent:
      return "נשלחה חשבונית עסקה לחברה חיצונית";
    case OrderField.taxInvoiceNumber:
      return "מספר חשבונית מס";
    case OrderField.taxInvoiceDate:
      return "תאריך חשבונית מס";
    case OrderField.receiptNumber:
      return "מספר קבלה";
    case OrderField.notes:
      return "הערות";
    case OrderField.id:
      return "הזמנה מספר";
    case OrderField.organizationId:
      return "ארגון";
    case OrderField.publicCourseId:
      return "בחר קורס";
    case OrderField.lectureTimes:
      return "הרצאות";
    case OrderField.orderFiles:
      return "קבצים";
    case OrderField.createdDate:
      return "תאריך יצירה";
    case OrderField.changedDate:
      return "תאריך שינוי";

    case OrganizationField.organizationName:
      return "שם הארגון";
    case OrganizationField.organizationAddress:
      return "כתובת הארגון";
    case OrganizationField.organizationCity:
      return "עיר";
    case OrganizationField.organizationPostalCode:
      return "מיקוד";
    case OrganizationField.companyId:
      return "ח.פ / ע.מ";
    case OrganizationField.paymentConditions:
      return "תנאי תשלום";
    case OrganizationField.externalInvoiceHandler:
      return "חברת חשבוניות חיצונית";
    case OrganizationField.referralWay:
      return "איך הגיעו אלינו";
    case OrganizationField.referralWayDetails:
      return "פרטי ההגעה";
    case OrganizationField.internalOrderIdRequired:
      return "נדרשת הזמנת רכש";
    case OrganizationField.organizationNotes:
      return "הערות לארגון";

    case LectureTimeField.topic:
      return "נושא";
    case LectureTimeField.tie:
      return "עניבה";
    case LectureTimeField.date:
      return "תאריך";
    case LectureTimeField.startTime:
      return "התחלה";
    case LectureTimeField.endTime:
      return "סיום";
    case LectureTimeField.duration:
      return "משך";
    case LectureTimeField.audienceSize:
      return "מס׳ משתתפים";
    case LectureTimeField.travelTime:
      return "זמן נסיעה";
    case LectureTimeField.videoCallLink:
      return "קישור לשיחה";
    case LectureTimeField.offerDate:
      return "תאריך הצעה";
    case LectureTimeField.subject:
      return "כותרת";
    case LectureTimeField.content:
      return "תוכן";
    case LectureTimeField.offerCost:
      return "עלות";
    case LectureTimeField.noTravelCost:
      return "ללא עלות נסיעות";
    case LectureTimeField.paymentConditionInfo:
      return "תנאי תשלום";
    case LectureTimeField.wakeUpTime:
      return "זמן לקום";
    case LectureTimeField.leaveHomeTime:
      return "יציאה מהבית";
    case LectureTimeField.arriveTime:
      return "הגעה למקום";
    case LectureTimeField.lectureStartTime:
      return "תחילת ההרצאה";

    case CourseLecturesInstanceFiled.active:
      return "מתקיים";

    case PublicCourseField.courseName:
      return "שם הקורס";
    case PublicCourseField.courseCity:
      return "עיר";
    case PublicCourseField.courseLocation:
      return "מיקום הקורס";
    case PublicCourseField.mealCost:
      return "עלות ארוחה";
    case PublicCourseField.distanceCost:
      return "עלות נסיעות";
    case PublicCourseField.onlineCourse:
      return "קורס מקוון";
    case PublicCourseField.isoPayed:
      return "שולם לאיגוד";
    case PublicCourseField.roomsApproved:
      return "חדרים אושרו";
    case PublicCourseField.printedMaterials:
      return "חוברות הודפסו";
    case PublicCourseField.printedCertificates:
      return "תעודות הודפסו";

    case PublicCourseParticipantField.participantFirstName:
      return "שם פרטי";
    case PublicCourseParticipantField.participantLastName:
      return "שם משפחה";
    case PublicCourseParticipantField.participantEnglishFirstName:
      return "שם פרטי באנגלית";
    case PublicCourseParticipantField.participantEnglishLastName:
      return "שם משפחה באנגלית";
    case PublicCourseParticipantField.idNumber:
      return "תעודת זהות";
    case PublicCourseParticipantField.phone:
      return "טלפון";
    case PublicCourseParticipantField.email:
      return "דואר אלקטרוני";
    case PublicCourseParticipantField.job:
      return "תפקיד";
    case PublicCourseParticipantField.participantCost:
      return "מחיר השתתפות";
    case PublicCourseParticipantField.isqMember:
      return "חבר איגוד";

    case PublicCourseLectureField.pages:
      return "עמודים בחוברת";
    case PublicCourseLectureField.roomCost:
      return "עלות החדר";
    case PublicCourseLectureField.price:
      return "מחיר ללקוח";
    case PublicCourseLectureField.guestLecturer:
      return "מרצה אורח";
    case PublicCourseLectureField.guestLecturerName:
      return "שם מרצה אורח";
    case PublicCourseLectureField.guestLecturerCost:
      return "עלות מרצה אורח";
    default:
      throw Error("Missing field labeld for: " + field);
  }
}

export type Field =
  | OrderField
  | OrganizationField
  | LectureTimeField
  | CourseLecturesInstanceFiled
  | PublicCourseField
  | PublicCourseParticipantField
  | PublicCourseLectureField;

export type ContactField =
  | OrderField.contactFirstName
  | OrderField.contactLastName
  | OrderField.contactPhone1
  | OrderField.contactPhone2
  | OrderField.contactPhoneExtension
  | OrderField.contactEmail
  | OrderField.contactFax
  | OrderField.contactJob;

export function getContactField(field: ContactField, isFinancial: boolean) {
  if (!isFinancial) {
    return field;
  }

  switch (field) {
    case OrderField.contactFirstName:
      return OrderField.financialContactFirstName;
    case OrderField.contactLastName:
      return OrderField.financialContactLastName;
    case OrderField.contactPhone1:
      return OrderField.financialContactPhone1;
    case OrderField.contactPhone2:
      return OrderField.financialContactPhone2;
    case OrderField.contactPhoneExtension:
      return OrderField.financialContactPhoneExtension;
    case OrderField.contactEmail:
      return OrderField.financialContactEmail;
    case OrderField.contactFax:
      return OrderField.financialContactFax;
    case OrderField.contactJob:
      return OrderField.financialContactJob;

    default:
      throw Error("Invalid order field - " + field);
  }
}
