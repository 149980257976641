import { OfferTemplate } from "../../Interfaces/ILists";
import AutoComplete from "../../Components/AutoComplete";
import { useEffect, useState } from "react";
import { toSuggestions } from "../../Components/AutoComplete";
import TextField from "../../Components/TextField";
import { CustomRaisedButton } from "../../Components/CustomComponents/CustomButtons";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { getOfferTemplates } from "../../Store/Lists/Selectors";
import { COLLECTION } from "@violet/common";
import { sendDataToDatabase } from "../../Store/Firebase/Actions";
import { openSnackbar } from "../../Store/Appearance/Actions";
import Screen from "../../Components/Screen";
import CustomSection from "../../Components/CustomComponents/CustomSection";
import SaveIcon from "@mui/icons-material/Save";

const emptyTemplate: OfferTemplate = {
  subject: "",
  content: "",
  cost: "",
};

export default function OfferTemplatesPage() {
  const [topic, setTopic] = useState("");
  const [template, setTemplate] = useState(emptyTemplate);
  const offerTemplates = useSelector(getOfferTemplates);
  const dispatch = useDispatch();

  const updateTemplate = async () => {
    await sendDataToDatabase(
      `/${COLLECTION.LISTS}/${COLLECTION.OFFER_TEMPLATES}/${topic.trim()}`,
      template
    );

    dispatch(openSnackbar("התבנית של " + topic.trim() + " נשמרה בהצלחה"));
  };

  useEffect(() => {
    const topicToShow = topic.trim();
    if (!offerTemplates || !offerTemplates.hasOwnProperty(topicToShow)) {
      setTemplate(emptyTemplate);
    } else {
      setTemplate(offerTemplates[topicToShow]);
    }
  }, [topic, setTemplate, offerTemplates]);

  if (!offerTemplates) {
    return null;
  }

  return (
    <Screen>
      <CustomSection title="תבניות הצעות מחיר">
        <AutoComplete
          title="נושא"
          textFieldValue={topic}
          onTextChange={setTopic}
          onSuggestionSelected={(suggestion) =>
            setTopic(suggestion?.label || "")
          }
          suggestions={toSuggestions(_.keys(offerTemplates))}
          fullWidth
        />

        <TextField
          title="כותרת"
          value={template.subject}
          onChange={(subject) =>
            setTemplate({
              ...template,
              subject,
            })
          }
          fullWidth
        />

        <TextField
          title="תוכן"
          value={template.content}
          onChange={(content) =>
            setTemplate({
              ...template,
              content,
            })
          }
          fullWidth
        />

        <TextField
          title="עלות"
          value={template.cost}
          onChange={(cost) =>
            setTemplate({
              ...template,
              cost,
            })
          }
          fullWidth
        />

        <CustomRaisedButton
          label="שמור"
          onClick={updateTemplate}
          variant="contained"
          icon={<SaveIcon />}
        />
      </CustomSection>
    </Screen>
  );
}
