import { OfferApprovalDetails, toPrintableDateFormat } from "@violet/common";
import CloseIcon from "@mui/icons-material/Close";
import { CustomIconButton } from "../../../../../../Components/CustomComponents/CustomButtons";
import { useDispatch } from "react-redux";
import { removeLectureTimeApproval } from "../../../../../../Store/SelectedOrder/Actions";
import { Alert } from "@mui/material";
import styles from "./OfferApprovalInformation.module.css";

interface OfferApprovalInformationProps {
  lectureTimeIndex: number;
  offerApprovalDetails?: OfferApprovalDetails;
}

export default function OfferApprovalInformation(
  props: OfferApprovalInformationProps
) {
  const dispatch = useDispatch();
  const onDeleteApproval = () =>
    dispatch(removeLectureTimeApproval(props.lectureTimeIndex));

  const details = props.offerApprovalDetails;
  if (!details) return null;

  const boldedText = `הזמנה זו אושרה על ידי ${details.approvingUserFirstName} ${
    details.approvingUserLastName
  } בתאריך ${toPrintableDateFormat(details.approvalTime)}. `;

  const text = `עבור הרצאה בתאריכים ${details.lectureDates}. פרטי המאשר: ${details.approvingUserRole}, ${details.approvingOrganization}, ${details.approvingUserPhone}, ${details.approvingUserEmail}`;
  return (
    <Alert
      severity="success"
      action={
        <CustomIconButton onClick={onDeleteApproval} tooltip={"מחק"}>
          <CloseIcon />
        </CustomIconButton>
      }
      className={styles.alert}
    >
      <div className={styles.text}>
        <div className={styles.bold}>{boldedText}</div>
        <div>{text}</div>
      </div>
    </Alert>
  );
}
