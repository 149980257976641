import { useNavigate } from "react-router";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import styles from "./AppMenu.module.scss";
import List from "@mui/material/List";
import { Path } from "../Pages/Path";
import MenuItems from "./MenuItems";
import { useContext, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Colors from "../Util/Constants/Colors";
import MobileViewContext, { StickMenuContext } from "./MobileViewContext";

const appVersion = process.env.REACT_APP_VERSION || "dev";

export default function AppMenu() {
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [mobile] = useContext(MobileViewContext);
  const [stickyMenu] = useContext(StickMenuContext);

  const appTitle = "שיא האיכות - Violet";

  if (stickyMenu && mobile) {
    return (
      <AppBar position="static" className={styles.red}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            sx={{ mr: 2 }}
            onClick={() => setDrawerOpen((open) => !open)}
          >
            <MenuIcon />
          </IconButton>
          <div className={styles.appNameButton}>{appTitle}</div>
        </Toolbar>

        <Drawer
          anchor={"left"}
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          PaperProps={{ sx: { backgroundColor: "red" } }}
        >
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="default"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => setDrawerOpen((open) => !open)}
            >
              <KeyboardArrowRightIcon className={styles.backIcon} />
            </IconButton>
          </Toolbar>
          <MenuItems onItemClick={() => setDrawerOpen(false)} />
        </Drawer>
      </AppBar>
    );
  }

  if (stickyMenu) {
    return (
      <AppBar className={styles.red}>
        <Toolbar>
          <IconButton
            size="small"
            edge="start"
            color="inherit"
            sx={{ mr: 2 }}
            onClick={() => setDrawerOpen((open) => !open)}
          >
            <MenuIcon />
          </IconButton>
          <div className={styles.appNameButton}>{appTitle}</div>
        </Toolbar>

        <Drawer
          anchor={"left"}
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          PaperProps={{ sx: { backgroundColor: "red" } }}
        >
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="default"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => setDrawerOpen((open) => !open)}
            >
              <KeyboardArrowRightIcon className={styles.backIcon} />
            </IconButton>
          </Toolbar>
          <MenuItems onItemClick={() => setDrawerOpen(false)} />
        </Drawer>
      </AppBar>
    );
  }

  return (
    <div className={styles.appMenu}>
      <List>
        <ListItem
          disablePadding
          onClick={() => navigate(Path.Home)}
          className={styles.appName}
        >
          <ListItemButton>
            <div className={styles.appNameButton}>{appTitle}</div>
          </ListItemButton>
        </ListItem>

        <MenuItems />
      </List>

      <div className={styles.appVersion}>{appVersion}</div>
    </div>
  );
}
