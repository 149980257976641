import { useState } from "react";
import Screen from "../../Components/Screen";
import SideMenu from "../../Components/SideMenu";
import SelectField from "../../Components/SelectField";
import _ from "lodash";
import { getStatusOptions, Status } from "@violet/common";
import { getOrders, getOrdersSummary } from "../../Store/Orders/Selectors";
import CustomPaperTable from "../../Components/Table/CustomPaperTable";
import { IState } from "../../Interfaces/ReduxInterfaces";
import { getOrderPath } from "../Path";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Checkbox from "../../Components/Checkbox";

export default function AllOrdersPage() {
  const navigate = useNavigate();
  const [filterStatus, setFilterStatus] = useState<Status>();
  const [showOnlyFirstBatch, setShowOnlyFirstBatch] = useState(true);

  const filterStatusSelectField = (
    <SideMenu title="סינון הזמנות לפי סטאטוס:">
      <SelectField
        label="סטאטוס"
        value={filterStatus}
        onChange={(newStatusString) =>
          setFilterStatus(newStatusString as Status)
        }
        options={getStatusOptions()}
        allowEmpty
        fullWidth
      />
      <Checkbox
        label="הצג 30 הזמנות ראשונות"
        value={showOnlyFirstBatch}
        onChange={setShowOnlyFirstBatch}
      />
    </SideMenu>
  );

  const elements = useSelector((state: IState) =>
    getElements(state, filterStatus)
  );

  return (
    <Screen sideMenu={filterStatusSelectField}>
      <CustomPaperTable
        title="כל ההזמנות"
        elements={elements}
        limit={getLimit(showOnlyFirstBatch)}
        tableHeaders={[
          { id: "מספר הזמנה" },
          { status: "סטאטוס" },
          { organizationName: "שם הארגון" },
          { topic: "נושא" },
          { date: "תאריך הרצאה" },
          { lecturesCount: "מספר הרצאות" },
        ]}
        onEditButton={(summary) => {
          navigate(getOrderPath(summary.id));
        }}
      />
    </Screen>
  );
}

function getLimit(showOnlyFirstBatch: boolean) {
  const limit = 30;
  return showOnlyFirstBatch ? limit : -1;
}

// Visible for testing
export function getElements(state: IState, filterStatus: Status | undefined) {
  return _.reverse(
    getOrdersSummary(state, (state: IState) =>
      getOrders(state, filterStatus || undefined)
    )
  );
}
