import classNames from "classnames";
import React, { useContext } from "react";
import MobileViewContext from "../Navigation/MobileViewContext";
import styles from "./Screen.module.css";

interface ScreenProps {
  sideMenu?: React.ReactNode;
  children: React.ReactNode;
}

export default function Screen(props: ScreenProps) {
  const [mobile] = useContext(MobileViewContext);

  return (
    <div className={styles.screen}>
      <div
        className={classNames({
          [styles.screenContent]: true,
          [styles.padding]: !mobile,
        })}
      >
        {props.children}
      </div>

      {!mobile && styles.sideMenu && (
        <div className={styles.sideMenu}>{props.sideMenu}</div>
      )}
    </div>
  );
}
