import { getSelectedOrder } from "../../../../Store/SelectedOrder/Selectors";
import { useSelector } from "react-redux";
import OrderCheckbox from "../ConnectedCustomComponents/OrderCheckbox";
import { OrderField } from "../../../../Store/Appearance/RequiredFields/FieldNames";
import OrderTextField from "../ConnectedCustomComponents/OrderTextField";
import OrderDatePicker from "../ConnectedCustomComponents/OrderDatePicker";

export default function FollowUpSection() {
  const followUpRequired = useSelector(getSelectedOrder).followUpRequired;

  return (
    <>
      <OrderCheckbox field={OrderField.followUpRequired} />

      {followUpRequired && (
        <>
          <OrderDatePicker field={OrderField.followUpDate} fullWidth />

          <OrderTextField field={OrderField.followUpDetails} fullWidth />
        </>
      )}
    </>
  );
}
