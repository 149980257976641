import Snackbar from "@mui/material/Snackbar";

export default function CustomSnackbar(props: CustomSnackbarProps) {
  return (
    <Snackbar
      open={props.open}
      message={props.message}
      autoHideDuration={4000}
      onClose={props.onRequestClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    />
  );
}

interface CustomSnackbarProps {
  open: boolean;
  message: string;
  onRequestClose: () => void;
}
