import MuiTextField from "@mui/material/TextField";
import { getFieldWidth, Size } from "../Util/Constants/Size";

export const textFieldStyles = {
  marginLeft: 20,
  marginBottom: 10,
  verticalAlign: "bottom",
};

export interface TextFieldProps {
  title: string;
  value: string;
  error?: boolean;
  fullWidth?: boolean;
  size?: Size;
  onChange: (newValue: string) => void;
  disabled?: boolean;
  type?: string;
  singelLine?: boolean;
}

export default function TextField(props: TextFieldProps) {
  const style = {
    width: getFieldWidth(props.fullWidth, props.size),
    textAlign: "right" as "right",
  };

  return (
    <MuiTextField
      variant="standard"
      style={textFieldStyles}
      helperText={props.title}
      value={props.value || ""} // A controlled element should not have null or undefined as value
      onChange={(event) => props.onChange(event.target.value)}
      fullWidth={props.fullWidth}
      disabled={props.disabled}
      error={props.error}
      type={props.type}
      multiline={
        !props.singelLine &&
        props.type !== "date" &&
        props.type !== "email" &&
        props.type !== "password"
      }
      inputProps={{ style }}
    />
  );
}
