import CustomSection from "../../../../../Components/CustomComponents/CustomSection";
import LectureTimesDetails from "./LectureTimes/LectureTimesDetails";

export default function OnlineCourseLectureDetails() {
  return (
    <CustomSection title="פרטי ההרצאות המקוונות">
      <LectureTimesDetails />
    </CustomSection>
  );
}
