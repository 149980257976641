import { Size } from "../../../../../../Util/Constants/Size";
import IndexAvatar from "../../../../../../Components/IndexAvatar";
import { toSuggestions } from "../../../../../../Components/AutoComplete";
import { ILectureTime, IOrder, isOnlineCourse } from "@violet/common";
import CustomAutoComplete from "../../../../../../Components/CustomComponents/CustomAutoComplete";
import PreparationTimes from "./PreparationTimes";
import { useContext, useState } from "react";
import { CustomIconButton } from "../../../../../../Components/CustomComponents/CustomButtons";
import OfferDetailsContainer from "./OfferDetailsContainer";
import OfferApprovalInformation from "./OfferApprovalInformation";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedOrder } from "../../../../../../Store/SelectedOrder/Selectors";
import {
  deleteLectureTime,
  updateLectureTime,
} from "../../../../../../Store/SelectedOrder/Actions";
import { getRequiredFieldsObject } from "../../../../../../Store/Appearance/RequiredFields/RequiredFieldsSelectors";
import {
  getOfferedLectures,
  getTies,
} from "../../../../../../Store/Lists/Selectors";
import styles from "./SingleLectureTime.module.css";
import DeleteIcon from "@mui/icons-material/Delete";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import { LectureTimeField } from "../../../../../../Store/Appearance/RequiredFields/FieldNames";
import CustomDatePicker from "../../../../../../Components/CustomComponents/CustomDatePicker";
import CustomTextField from "../../../../../../Components/CustomComponents/CustomTextField";
import MobileViewContext from "../../../../../../Navigation/MobileViewContext";

interface SingleLectureTimeProps {
  lectureTimeIndex: number;
  visualIndex: number;
}

export default function SingleLectureTime(props: SingleLectureTimeProps) {
  const dispatch = useDispatch();
  const [mobile] = useContext(MobileViewContext);
  const selectedOrder = useSelector(getSelectedOrder);
  const lectureTimeIndex = props.lectureTimeIndex;
  const lectureTime = getLectureTime(selectedOrder, lectureTimeIndex);
  const isOnlineCourseORder = isOnlineCourse(selectedOrder);

  const allRequiredFields = useSelector(getRequiredFieldsObject);
  const requiredFields = allRequiredFields.internalOrderLectureTime;

  const offeredLectures = toSuggestions(useSelector(getOfferedLectures));
  const ties = toSuggestions(useSelector(getTies));

  const [showOfferDetails, setShowOfferDetails] = useState(false);

  const onChange = (key: LectureTimeField) => (newValue: string) =>
    dispatch(updateLectureTime(key, newValue, lectureTimeIndex));

  return (
    <div>
      <div className={styles.lectureTime}>
        <div className={styles.lectureTimeFields}>
          <div className={mobile ? "" : styles.lectureTimeCommonFields}>
            {!mobile && <IndexAvatar index={props.visualIndex + 1} />}
            <CustomDatePicker
              field={LectureTimeField.date}
              valueObject={lectureTime}
              onChange={onChange(LectureTimeField.date)}
              requiredFields={requiredFields}
            />
            <CustomAutoComplete
              field={LectureTimeField.topic}
              valueObject={lectureTime}
              onTextChange={onChange(LectureTimeField.topic)}
              suggestions={offeredLectures}
              requiredFields={requiredFields}
              size={Size.XXL}
            />
            <CustomTextField
              field={LectureTimeField.startTime}
              valueObject={lectureTime}
              onChange={onChange(LectureTimeField.startTime)}
              requiredFields={requiredFields}
              size={Size.S}
            />
            <CustomTextField
              field={LectureTimeField.endTime}
              valueObject={lectureTime}
              onChange={onChange(LectureTimeField.endTime)}
              requiredFields={requiredFields}
              size={Size.S}
            />
            <CustomTextField
              field={LectureTimeField.duration}
              valueObject={lectureTime}
              onChange={onChange(LectureTimeField.duration)}
              requiredFields={requiredFields}
              size={Size.S}
            />
            <CustomTextField
              field={LectureTimeField.audienceSize}
              valueObject={lectureTime}
              onChange={onChange(LectureTimeField.audienceSize)}
              requiredFields={requiredFields}
              size={Size.S}
            />
            <CustomAutoComplete
              field={LectureTimeField.tie}
              valueObject={lectureTime}
              suggestions={ties}
              onTextChange={onChange(LectureTimeField.tie)}
              requiredFields={requiredFields}
              size={Size.M}
            />
            {!isOnlineCourseORder && (
              <CustomTextField
                field={LectureTimeField.travelTime}
                valueObject={lectureTime}
                onChange={onChange(LectureTimeField.travelTime)}
                requiredFields={requiredFields}
                size={Size.S}
              />
            )}
          </div>
          {isOnlineCourseORder && (
            <div className={styles.videoLink}>
              <CustomTextField
                field={LectureTimeField.videoCallLink}
                valueObject={lectureTime}
                onChange={onChange(LectureTimeField.videoCallLink)}
                requiredFields={allRequiredFields.onlineCourseLectureTime}
                fullWidth
                singelLine
              />
            </div>
          )}

          {!mobile && (
            <div className={styles.lectureTimeActionButtons}>
              {!isOnlineCourseORder && (
                <PreparationTimes lectureTime={lectureTime} />
              )}

              <CustomIconButton
                tooltip="הצג פרטי הצעת מחיר"
                onClick={() => setShowOfferDetails((open) => !open)}
              >
                <HowToRegIcon />
              </CustomIconButton>

              <CustomIconButton
                tooltip="מחק הרצאה"
                onClick={() => dispatch(deleteLectureTime(lectureTimeIndex))}
              >
                <DeleteIcon />
              </CustomIconButton>
            </div>
          )}
        </div>

        <OfferDetailsContainer
          show={showOfferDetails}
          index={props.visualIndex}
          lectureTimeIndex={props.lectureTimeIndex}
          topic={lectureTime.topic}
        />

        <OfferApprovalInformation
          lectureTimeIndex={lectureTimeIndex}
          offerApprovalDetails={lectureTime.offerApprovalDetails}
        />
      </div>
    </div>
  );
}

function getLectureTime(
  selectedOrder: IOrder,
  lectureTimeIndex: number
): ILectureTime {
  if (lectureTimeIndex === null || selectedOrder.lectureTimes === undefined)
    return {} as ILectureTime;

  return selectedOrder.lectureTimes[lectureTimeIndex];
}
