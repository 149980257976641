import CustomCheckbox from "../../../Components/CustomComponents/CustomCheckbox";
import { IPublicCourseLecture } from "@violet/common";
import { useDispatch } from "react-redux";
import { updatePublicCourseLecture } from "../../../Store/SelectedPublicCourse/Actions";
import styles from "./CourseLecturesInstance.module.css";
import { PublicCourseLectureField } from "../../../Store/Appearance/RequiredFields/FieldNames";
import CustomTextField from "../../../Components/CustomComponents/CustomTextField";

export default function GuestLecturerFields(
  props: CourseLecturesInstanceProps
) {
  const dispatch = useDispatch();

  const onChange =
    (key: PublicCourseLectureField) => (newValue: string | boolean) =>
      dispatch(updatePublicCourseLecture(key, newValue, props.lectureId));

  if (!props.publicCourseLecture.guestLecturer) {
    return (
      <CustomCheckbox
        field={PublicCourseLectureField.guestLecturer}
        valueObject={props.publicCourseLecture}
        onChange={onChange(PublicCourseLectureField.guestLecturer)}
      />
    );
  }
  return (
    <div className={styles.fields}>
      <CustomCheckbox
        field={PublicCourseLectureField.guestLecturer}
        valueObject={props.publicCourseLecture}
        onChange={onChange(PublicCourseLectureField.guestLecturer)}
      />
      <CustomTextField
        field={PublicCourseLectureField.guestLecturerName}
        valueObject={props.publicCourseLecture}
        onChange={onChange(PublicCourseLectureField.guestLecturerName)}
      />
      <CustomTextField
        field={PublicCourseLectureField.guestLecturerCost}
        valueObject={props.publicCourseLecture}
        onChange={onChange(PublicCourseLectureField.guestLecturerCost)}
      />
    </div>
  );
}

interface CourseLecturesInstanceProps {
  publicCourseLecture: IPublicCourseLecture;
  lectureId: number;
}
