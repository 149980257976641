import { StorageFolder } from "@violet/common";
import { doc, setDoc, getFirestore } from "firebase/firestore";

export function saveContentUrlToDb(key: string, downloadUrl: string) {
  const firestore = getFirestore();
  setDoc(doc(firestore, StorageFolder.CONTENT, key), {
    downloadUrl,
    uploadTime: new Date(),
  });
}
