import CustomPaperTable from "../../Components/Table/CustomPaperTable";
import { IOrderSummary } from "../../Store/Orders/Selectors";
import {
  BiPageContentContainerProps,
  IReferralWayCounters,
} from "./BiPageContentContainer";
import DatePicker from "../../Components/DatePicker";
import { getStatusOptions, IStringObject, Status } from "@violet/common";
import SelectField from "../../Components/SelectField";
import { Size } from "../../Util/Constants/Size";
import ReferralWayChart from "./ReferralWayChart";

export default function (props: BiPageContentProps) {
  const filters = (
    <div
      style={{
        marginTop: 20,
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
      }}
    >
      <div>
        <DatePicker
          title="תאריך התחלה"
          value={props.startDate}
          onChange={props.setStartDate}
        />

        <DatePicker
          title="תאריך סיום"
          value={props.endDate}
          onChange={props.setEndDate}
        />

        <SelectField
          label="סנן לפי סטאטוס"
          value={props.filterStatus}
          onChange={(value: string) => props.setFilterStatus(value as Status)}
          options={getStatusOptions()}
          size={Size.XL}
          allowEmpty
        />
      </div>

      <ReferralWayChart referralWayCounters={props.referralWayCounters} />
    </div>
  );

  return (
    <CustomPaperTable
      title="איך הגיעו אלינו"
      elements={props.ordersMatchingFilters}
      onEditButton={props.onTableRowEdit}
      beforeTable={filters}
      tableHeaders={
        [
          { id: "מספר הזמנה" },
          { organizationName: "שם הארגון" },
          { date: "תאריך הרצאה" },
          { topic: "נושא" },
          { status: "סטאטוס" },
          { orgReferralWay: "איך הגיע אלינו" },
        ] as IStringObject[]
      }
    />
  );
}

export interface BiPageContentProps extends BiPageContentContainerProps {
  ordersMatchingFilters: IOrderSummary[];
  referralWayCounters: IReferralWayCounters;
  onTableRowEdit: (orderSummary: IOrderSummary) => void;
}
