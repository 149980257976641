import AutoComplete, { ISuggestion } from "../AutoComplete";
import { getFieldWidth, Size } from "../../Util/Constants/Size";
import {
  Field,
  getFieldLabel,
} from "../../Store/Appearance/RequiredFields/FieldNames";
import { shouldMarkWithError } from "../../Store/Appearance/RequiredFields/RequiredFieldsUtil";
import MobileViewContext from "../../Navigation/MobileViewContext";
import { useContext } from "react";
import PhoneField from "./PhoneField";

export default function CustomAutoComplete(props: CustomAutoCompleteProps) {
  // A controlled element should not have null or undefined as value
  const value = props.valueObject[props.field] || "";
  const error = shouldMarkWithError(props.field, props.requiredFields, value);
  const [mobile] = useContext(MobileViewContext);

  if (mobile) {
    return <PhoneField field={props.field} valueObject={props.valueObject} />;
  }

  return (
    <AutoComplete
      suggestions={props.suggestions || []}
      title={getFieldLabel(props.field)}
      textFieldValue={value}
      onTextChange={props.onTextChange}
      onSuggestionSelected={props.onSuggestionSelected || function () {}}
      disabled={props.disabled}
      fullWidth={props.fullWidth}
      error={error}
      width={getFieldWidth(props.fullWidth, props.size)}
    />
  );
}

interface CustomAutoCompleteProps {
  field: Field;
  valueObject: any;
  requiredFields?: Field[];
  suggestions?: ISuggestion[];
  size?: Size;
  fullWidth?: boolean;
  disabled?: boolean;
  onTextChange: (newValue: string) => void;
  onSuggestionSelected?: (suggestion: ISuggestion | null) => void;
}
