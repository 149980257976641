import _ from "lodash";
import { getOrganizationById } from "../Organizations/Selectors";
import {
  getOrderStatusLabel,
  IOrder,
  Status,
  toPrintableDateFormat,
  isPublicCourseOrder,
  IPublicCourse,
  isMatchingStatus,
} from "@violet/common";
import { isEmptyValue } from "../../Util/StringUtil";
import { getPublicCourseByOrder } from "../PublicCourses/Selectors";
import { IState } from "../../Interfaces/ReduxInterfaces";
import { getOrders } from "./Selectors";
import { OrderField } from "../Appearance/RequiredFields/FieldNames";

export interface IActionRequiredOrder {
  id: number;
  createdDate: string;
  organizationName: string;
  status: string;
  issue: string;
  issueCase: ActionRequiredCase;
  lectureDate: string;
}

export enum ActionRequiredCase {
  EXECUTED_AND_NO_INVOICE = "1",
  FOLLOW_UP_REQUIRED = "2",
  NOT_PAID_ON_TIME = "3",
  APPROVED_BY_CUSTOMER = "4",
  NO_ORDER_APPROVAL = "5",
  TWO_WEEKS_PASSED_FROM_CREATION = "6",
}

export const ALL_ACTION_REQUIRED_CASES = [
  ActionRequiredCase.EXECUTED_AND_NO_INVOICE,
  ActionRequiredCase.FOLLOW_UP_REQUIRED,
  ActionRequiredCase.NOT_PAID_ON_TIME,
  ActionRequiredCase.APPROVED_BY_CUSTOMER,
  ActionRequiredCase.NO_ORDER_APPROVAL,
  ActionRequiredCase.TWO_WEEKS_PASSED_FROM_CREATION,
];

export function getActionRequiredCaseLabel(actionCase: ActionRequiredCase) {
  switch (actionCase) {
    case ActionRequiredCase.EXECUTED_AND_NO_INVOICE:
      return "לא הונפקה חשבונית";
    case ActionRequiredCase.FOLLOW_UP_REQUIRED:
      return "נדרש המשך טיפול";
    case ActionRequiredCase.NOT_PAID_ON_TIME:
      return "תאריך לתשלום עבר";
    case ActionRequiredCase.APPROVED_BY_CUSTOMER:
      return "ההזמנה אושרה על ידי הלקוח";
    case ActionRequiredCase.NO_ORDER_APPROVAL:
      return "ההזמנה לא אושרה, ההרצאה בקרוב";
    case ActionRequiredCase.TWO_WEEKS_PASSED_FROM_CREATION:
      return "חלפו שבועיים מיצירת ההזמנה";
    default:
      throw Error("Invalid action case to string: " + actionCase);
  }
}

export function isCriticalCase(actionCase: ActionRequiredCase) {
  switch (actionCase) {
    case ActionRequiredCase.EXECUTED_AND_NO_INVOICE:
    case ActionRequiredCase.FOLLOW_UP_REQUIRED:
    case ActionRequiredCase.NOT_PAID_ON_TIME:
    case ActionRequiredCase.APPROVED_BY_CUSTOMER:
      return true;
    case ActionRequiredCase.NO_ORDER_APPROVAL:
    case ActionRequiredCase.TWO_WEEKS_PASSED_FROM_CREATION:
      return false;
    default:
      throw Error("Invalid action case " + actionCase);
  }
}

export function getActionRequiredCase(
  order: IOrder,
  publicCourse?: IPublicCourse
): ActionRequiredCase | undefined {
  const now = new Date();

  if (order.followUpRequired) {
    if (new Date(order.followUpDate) < now) {
      return ActionRequiredCase.FOLLOW_UP_REQUIRED;
    }
  }

  if (
    order.lectureTimes &&
    isMatchingStatus(order, [Status.contact, Status.offer, Status.order]) &&
    order.lectureTimes.filter(
      (lectureTime) => lectureTime.offerApprovalDetails !== undefined
    ).length > 0
  ) {
    return ActionRequiredCase.APPROVED_BY_CUSTOMER;
  }

  switch (order.status) {
    case Status.contact:
    case Status.offer:
      if (addTwoWeeks(order.createdDate) < now) {
        return ActionRequiredCase.TWO_WEEKS_PASSED_FROM_CREATION;
      }
      return;

    case Status.order:
      let firstLectureTimeDate;
      if (isPublicCourseOrder(order)) {
        if (!publicCourse) {
          // Data did not load yet
          return;
        }
        firstLectureTimeDate = _.sortBy(
          publicCourse.lectures,
          (lecture) => new Date(lecture.date)
        )[0].date;
      } else {
        firstLectureTimeDate = _.sortBy(
          order.lectureTimes,
          (time) => new Date(time.date)
        )[0].date;
      }

      if (new Date(firstLectureTimeDate) < addTwoWeeks(now.toJSON())) {
        return ActionRequiredCase.NO_ORDER_APPROVAL;
      }

      if (addTwoWeeks(order.createdDate) < now) {
        return ActionRequiredCase.TWO_WEEKS_PASSED_FROM_CREATION;
      }
      return;

    case Status.approvedOrder:
    case Status.isExecuting:
    case Status.executed: {
      let lastLectureTimeDate;
      if (isPublicCourseOrder(order)) {
        if (!publicCourse) {
          // Data did not load yet
          return;
        }
        lastLectureTimeDate = _.sortBy(
          publicCourse.lectures,
          (lecture) => -new Date(lecture.date)
        )[0].date;
      } else {
        lastLectureTimeDate = _.sortBy(
          order.lectureTimes,
          (time) => -new Date(time.date)
        )[0].date;
      }

      if (
        isEmptyValue(order, OrderField.proformaInvoiceNumber) &&
        new Date(lastLectureTimeDate) < now
      ) {
        return ActionRequiredCase.EXECUTED_AND_NO_INVOICE;
      }
      return;
    }

    case Status.waitingPayment:
      if (new Date(order.expectedPayDate) < now) {
        return ActionRequiredCase.NOT_PAID_ON_TIME;
      }
      return;
  }

  return;
}

export default function getActionRequiredOrdersArray(state: IState) {
  const orders = getOrders(state);

  const result = _.chain(orders)
    .map((order) => {
      const publicCourse = getPublicCourseByOrder(state)(order);
      const actionRequiredCase = getActionRequiredCase(order, publicCourse);
      return { order, actionRequiredCase };
    })
    .filter(({ actionRequiredCase }) => actionRequiredCase !== undefined)
    .map<IActionRequiredOrder>(({ order, actionRequiredCase }) => {
      let issue: string = getActionRequiredCaseLabel(actionRequiredCase!);
      if (actionRequiredCase === ActionRequiredCase.FOLLOW_UP_REQUIRED) {
        issue =
          getActionRequiredCaseLabel(ActionRequiredCase.FOLLOW_UP_REQUIRED) +
          " - " +
          toPrintableDateFormat(order.followUpDate);
      } else if (actionRequiredCase === ActionRequiredCase.NOT_PAID_ON_TIME) {
        issue =
          getActionRequiredCaseLabel(ActionRequiredCase.NOT_PAID_ON_TIME) +
          " - " +
          toPrintableDateFormat(order.expectedPayDate);
      }

      const result = {
        id: order.id,
        createdDate: order.createdDate,
        issue,
        issueCase: actionRequiredCase!,
        organizationName: getOrganizationById(
          state,
          order.organizationId.toString()
        ).organizationName,
        status: getOrderStatusLabel(order),
        lectureDate: "",
      };

      if (!_.isEmpty(order.lectureTimes)) {
        result.lectureDate = order.lectureTimes[0].date;
      }

      return result;
    })
    .value();

  return _.reverse(result);
}

function addTwoWeeks(dateString: string) {
  const twoWeeks = 12096e5;
  return new Date(new Date(dateString).valueOf() + twoWeeks);
}
