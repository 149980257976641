import {
  getOrders,
  getOrdersSummary,
  IOrderSummary,
} from "../../Store/Orders/Selectors";
import * as _ from "lodash";
import { IState } from "../../Interfaces/ReduxInterfaces";
import { Status } from "@violet/common";
import { getOrderPath } from "../Path";
import BiPageContent from "./BiPageContent";
import { isEmpty } from "../../Util/StringUtil";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

export default function BiPageContentContainer(
  props: BiPageContentContainerProps
) {
  const navigate = useNavigate();

  const status = isEmpty(props.filterStatus) ? undefined : props.filterStatus;
  const orders = useSelector((state: IState) =>
    getOrdersSummary(state, (state: IState) => getOrders(state, status))
  );

  const [ordersMatchingFilters, referralWayCounters] = getData(orders, props);

  return (
    <BiPageContent
      {...props}
      ordersMatchingFilters={ordersMatchingFilters}
      referralWayCounters={referralWayCounters}
      onTableRowEdit={(summary) => {
        navigate(getOrderPath(summary.id));
      }}
    />
  );
}

export function getData(
  orders: IOrderSummary[],
  props: BiPageContentContainerProps
): [IOrderSummary[], IReferralWayCounters] {
  const startDate = new Date(props.startDate);
  const endDate = new Date(props.endDate);

  const referralWayCounters: IReferralWayCounters = {};

  const filteredOffers = _.filter(orders, (order) => {
    if (isEmpty(order.date)) {
      return false;
    }

    const orderDate = new Date(order.date);
    const shouldShow = startDate <= orderDate && orderDate <= endDate;
    if (shouldShow) {
      const referralWay = order.orgReferralWay || "ללא";
      if (!(referralWay in referralWayCounters)) {
        referralWayCounters[referralWay] = 0;
      }
      referralWayCounters[referralWay]++;
    }

    return shouldShow;
  });

  const ordersMatchingFilters = _.reverse(filteredOffers);
  return [ordersMatchingFilters, referralWayCounters];
}

export interface BiPageContentContainerProps {
  startDate: string;
  setStartDate: (date: string) => void;
  endDate: string;
  setEndDate: (date: string) => void;
  filterStatus?: Status;
  setFilterStatus: (status: Status) => void;
}

export type IReferralWayCounters = { [referralWay: string]: number };
