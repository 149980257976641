import { getExpectedIncomeOrders } from "../Store/Orders/Selectors";
import CustomPaperTable from "../Components/Table/CustomPaperTable";
import { Status } from "@violet/common";
import { getOrderPath } from "./Path";
import { IState } from "../Interfaces/ReduxInterfaces";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import * as _ from "lodash";
import SideMenu from "../Components/SideMenu";
import { moneyFormat } from "../Util/StringUtil";
import Screen from "../Components/Screen";

export const StatusesConsideredAsWaitingPayment = [
  Status.waitingPayment,
  Status.executed,
  Status.isExecuting,
];

export default function WaitingPaymentPage() {
  const navigate = useNavigate();

  const elements = useSelector((state: IState) =>
    getExpectedIncomeOrders(state, StatusesConsideredAsWaitingPayment)
  );

  const sum = _.sumBy(elements, (x) => _.parseInt(x.totalSum));

  const sideMenu = (
    <SideMenu title={"סה״כ ממתין לתשלום: " + moneyFormat(sum.toString())} />
  );

  return (
    <Screen sideMenu={sideMenu}>
      <CustomPaperTable
        title="הזמנות ממתינות לתשלום"
        elements={elements}
        tableHeaders={[
          { id: "מספר הזמנה" },
          { status: "סטאטוס" },
          { organizationName: "שם הארגון" },
          { lectureDate: "תאריך הרצאה" },
          { proformaInvoiceNumber: "חשבונית עסקה" },
          { expectedPayDate: "תאריך תשלום" },
          { totalSum: "סכום לתשלום" },
        ]}
        onEditButton={(summary) => {
          navigate(getOrderPath(summary.id));
        }}
      />
    </Screen>
  );
}
