import { IState } from "../../Interfaces/ReduxInterfaces";
import { toMutable } from "../../Util/ObjectUpdater";
import { getOrders } from "../Orders/Selectors";
import { getPublicCourses } from "../PublicCourses/Selectors";
import _ from "lodash";
import { getOfferedLectures } from "../Lists/Selectors";
import { getOrganizations } from "../Organizations/Selectors";

function getAppearance(state: IState) {
  return toMutable(state.appearance);
}

export function isRTL(state: IState) {
  return getAppearance(state).rtl;
}

export function isDialogOpen(state: IState) {
  return getAppearance(state).dialog.isOpen;
}

export function getDialogTitle(state: IState) {
  return getAppearance(state).dialog.title;
}

export function getDialogContent(state: IState) {
  return getAppearance(state).dialog.content;
}

export function getDialogActions(state: IState) {
  return getAppearance(state).dialog.actions;
}

export function isSnackbarOpen(state: IState) {
  return getAppearance(state).snackbar.isOpen;
}

export function getSnackbarMessage(state: IState) {
  return getAppearance(state).snackbar.message;
}

export function shouldShowRequiredFields(state: IState) {
  return getAppearance(state).showRequiredFields;
}

export function isFinishedLoading(state: IState) {
  return (
    !_.isEmpty(getOrders(state)) &&
    !_.isEmpty(getOrganizations(state)) &&
    !_.isEmpty(getPublicCourses(state)) &&
    !_.isEmpty(getOfferedLectures(state))
  );
}
