import * as _ from "lodash";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import { getFieldWidth, Size } from "../Util/Constants/Size";
import { IOption } from "./CustomComponents/CustomSelectField";

interface SelectFieldProps {
  label: string;
  value?: string;
  error?: boolean;
  fullWidth?: boolean;
  size?: Size;
  onChange: (newValue: string) => void;
  options?: IOption[];
  allowEmpty?: true;
  disabled?: boolean;
}

export default function SelectField(props: SelectFieldProps) {
  const formStyle = {
    minWidth: getFieldWidth(props.fullWidth, props.size),
    marginBottom: 10,
    marginLeft: 20,
    verticalAlign: "bottom",
  };

  return (
    <FormControl style={formStyle} error={props.error}>
      <Select
        variant="standard"
        value={props.value || ""}
        disabled={props.disabled}
        onChange={(event) => props.onChange(event.target.value as string)}
      >
        {props.allowEmpty ? (
          <MenuItem value="">
            <em>-</em>
          </MenuItem>
        ) : null}

        {_.map(props.options, (option) => (
          <MenuItem
            value={option.key}
            key={option.key}
            disabled={option.disabled}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{props.label}</FormHelperText>
    </FormControl>
  );
}
