import { getStatusLabel } from "@violet/common";
import {
  Field,
  getFieldLabel,
  OrderField,
} from "../../Store/Appearance/RequiredFields/FieldNames";
import styles from "./PhoneField.module.css";

export default function PhoneField(props: { valueObject: any; field: Field }) {
  let value = props.valueObject[props.field];
  if (!value) {
    return null;
  }
  if (props.field === OrderField.status) {
    value = getStatusLabel(value);
  }

  if (
    props.field === OrderField.contactPhone1 ||
    props.field === OrderField.contactPhone2
  ) {
    const phoneLink = getPhoneLink(value);
    return (
      <div className={styles.field}>
        <div className={styles.label}>{getFieldLabel(props.field)}:</div>
        <a className={styles.value} href={phoneLink}>
          {value}
        </a>
      </div>
    );
  }

  return (
    <div className={styles.field}>
      <div className={styles.label}>{getFieldLabel(props.field)}:</div>
      <div className={styles.value}>{value}</div>
    </div>
  );
}

function getPhoneLink(phone: string) {
  return "tel:+972" + phone.replace("-", "").slice(1);
}
