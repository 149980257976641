import CustomPaperTable from "../Components/Table/CustomPaperTable";
import Screen from "../Components/Screen";
import { getFutureLectureTimes } from "../Store/Orders/Selectors";
import { EntityType } from "../Util/Constants/EntityType";
import { getOrderPath, getPublicCoursePath } from "./Path";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import SideMenu from "../Components/SideMenu";

export default function FutureLecturesPage() {
  const navigate = useNavigate();
  const futureLectures = useSelector(getFutureLectureTimes);
  const internalCoursesLectureCount = futureLectures.filter(
    (x) => x.entityType === EntityType.order
  ).length;
  const publicCourseLecturesCount = futureLectures.filter(
    (x) => x.entityType === EntityType.publicCourse
  ).length;

  const sideMenu = (
    <SideMenu title="סיכום הרצאות עתידיות">
      <div>הרצאות מתוכננות: {futureLectures.length}</div>
      <br />
      <div>הרצאות בארגונים: {internalCoursesLectureCount}</div>
      <br />
      <div>הרצאות בקורסים ציבוריים: {publicCourseLecturesCount}</div>
    </SideMenu>
  );

  return (
    <Screen sideMenu={sideMenu}>
      <CustomPaperTable
        title="הרצאות עתידיות"
        elements={futureLectures}
        tableHeaders={[
          { orderId: "מספר הזמנה" },
          { date: "תאריך הרצאה" },
          { topic: "נושא" },
          { organizationName: "שם הארגון" },
        ]}
        onEditButton={(summary) => {
          switch (summary.entityType) {
            case EntityType.order:
              navigate(getOrderPath(summary.id));
              return;

            case EntityType.publicCourse:
              navigate(getPublicCoursePath(summary.id));
              return;

            default:
              return;
          }
        }}
      />
    </Screen>
  );
}
