import _ from "lodash";
import {
  hasDatePassed,
  IOrder,
  IPublicCourse,
  isPublicCourseOrder,
} from "@violet/common";
import { getOrders } from "../Orders/Selectors";
import { moneyFormat } from "../../Util/StringUtil";
import { IState } from "../../Interfaces/ReduxInterfaces";
import { toMutable } from "../../Util/ObjectUpdater";
import { calculateMathValue } from "../../Pages/OrderPage/Sections/Payment/CalculateSum";

export function getPublicCourses(state: IState) {
  return toMutable(state.publicCourses);
}

export function getActivePublicCourses(state: IState) {
  return _.filter(getPublicCourses(state), isPublicCourseActive);
}

export function isPublicCourseActive(publicCourse?: IPublicCourse) {
  if (!publicCourse) {
    return false;
  }
  const activeLectures = _.filter(
    publicCourse.lectures,
    (lecture) => lecture.active
  );
  return _.some(activeLectures, (lecture) => !hasDatePassed(lecture.date));
}

export function getNextPublicCourseId(state: IState) {
  const courses = getPublicCourses(state);
  const keys = _.keys(courses);
  if (!courses || keys.length === 0) return 100;

  const maxId = _.max(_.map(_.keys(courses), _.parseInt));
  if (!maxId) return 100;

  return maxId + 1;
}

export const getPublicCourseById = (state: IState) => (id: string) => {
  return getPublicCourses(state)[id];
};

export function getPublicCourseByOrder(
  state: IState
): (order: IOrder) => IPublicCourse | undefined {
  return (order) => {
    if (isPublicCourseOrder(order)) {
      return getPublicCourses(state)[order.publicCourseId];
    }
    return;
  };
}

export type IPublicCourseSummary = {
  id: number;
  courseName: string;
  courseLocation: string;
  participatnsCount: number;
  startDate: string;
  endDate: string;
  courseIncome: string;
};

export function getPublicCoursesSummary(state: IState) {
  const courses = getPublicCourses(state);

  function map(course: IPublicCourse) {
    const result: IPublicCourseSummary = {
      id: course.id,
      courseName: course.courseName,
      courseLocation: course.onlineCourse ? "מקוון" : course.courseLocation,
      participatnsCount: 0,
      startDate: "",
      endDate: "",
      courseIncome: "",
    };
    const activeLectures = _.filter(course.lectures, (x) => x.active);
    if (!_.isEmpty(activeLectures)) {
      const dates = _.map(activeLectures, (x) => x.date).sort();
      result.startDate = dates[0];
      result.endDate = dates[dates.length - 1];
    }

    const orders = getOrders(state);
    const publicCourseOrders = _.filter(
      orders,
      (order) =>
        isPublicCourseOrder(order) && order.publicCourseId === course.id
    );
    result.courseIncome = moneyFormat(
      _.sumBy(publicCourseOrders, (x) => calculateMathValue(x.cost)).toString()
    );
    result.participatnsCount = _.sumBy(publicCourseOrders, (order) =>
      order.publicCourseParticipants ? order.publicCourseParticipants.length : 0
    );

    return result;
  }

  return _.reverse(_.map(courses, map));
}
