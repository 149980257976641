import { useDispatch, useSelector } from "react-redux";
import {
  sendSelectedPublicCourseToDatabase,
  updateSelectedPublicCourse,
} from "../../Store/SelectedPublicCourse/Actions";
import { openSnackbar } from "../../Store/Appearance/Actions";
import { getNextPublicCourseId } from "../../Store/PublicCourses/Selectors";
import { IPublicCourse } from "@violet/common";
import SaveIcon from "@mui/icons-material/Save";
import { CustomRaisedButton } from "../../Components/CustomComponents/CustomButtons";

interface SavePublicCourseButtonProps {
  selectedPublicCourse: IPublicCourse;
}

export default function SavePublicCourseButton(
  props: SavePublicCourseButtonProps
) {
  const dispatch = useDispatch();
  const nextPublicCourseId = useSelector(getNextPublicCourseId);

  const onClick = async () => {
    if (!props.selectedPublicCourse.hasOwnProperty("id")) {
      await dispatch(
        updateSelectedPublicCourse("id", nextPublicCourseId.toString())
      );
      await dispatch(
        updateSelectedPublicCourse("createdDate", new Date().toJSON())
      );
    }

    dispatch(sendSelectedPublicCourseToDatabase());
    const snackbarMessage = "קורס ציבורי {0} נשמר בהצלחה".replace(
      "{0}",
      props.selectedPublicCourse.courseName
    );
    dispatch(openSnackbar(snackbarMessage));
  };

  return (
    <CustomRaisedButton
      onClick={onClick}
      label="שמור"
      icon={<SaveIcon />}
      variant="contained"
      fullWidth
    />
  );
}
