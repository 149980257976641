import React, { useContext } from "react";
import SingleLectureTime from "./SingleLectureTime";
import CustomDivider from "../../../../../../Components/CustomComponents/CustomDivider";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedOrder } from "../../../../../../Store/SelectedOrder/Selectors";
import { ILectureTime } from "@violet/common";
import _ from "lodash";
import styles from "./LectureTimesDetails.module.css";
import { CustomRaisedButton } from "../../../../../../Components/CustomComponents/CustomButtons";
import { addNewLectureTime } from "../../../../../../Store/SelectedOrder/Actions";
import AddIcon from "@mui/icons-material/Add";
import MobileViewContext from "../../../../../../Navigation/MobileViewContext";

export default function LectureTimesDetails() {
  const selectedOrder = useSelector(getSelectedOrder);
  const lectureTimesIndexes = getLectureTimeIndexes(selectedOrder.lectureTimes);
  const [mobile] = useContext(MobileViewContext);

  const dispatch = useDispatch();

  return (
    <div className={styles.container}>
      {!mobile && (
        <div className={styles.addButton}>
          <CustomRaisedButton
            label="הוסף הרצאה חדשה"
            onClick={() => dispatch(addNewLectureTime())}
            icon={<AddIcon />}
            variant="outlined"
          />
        </div>
      )}
      <div>
        {lectureTimesIndexes.map((lectureTimeIndex, orderIndex) => (
          <React.Fragment key={lectureTimeIndex}>
            <SingleLectureTime
              key={lectureTimeIndex}
              visualIndex={orderIndex}
              lectureTimeIndex={lectureTimeIndex}
            />

            {orderIndex < lectureTimesIndexes.length - 1 && <CustomDivider />}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}

function getLectureTimeIndexes(lectureTimes: ILectureTime[]) {
  if (!lectureTimes) return [];

  // Add index to every lecture time
  const indexedLectureTimes = _.map(
    lectureTimes,
    function (lectureTime, index): ILectureTime & { index: number } {
      return {
        ...lectureTime,
        index: index,
      };
    }
  );

  const sortedLectureTimes = _.sortBy(indexedLectureTimes, (x) => x.date);

  return _.map(sortedLectureTimes, (lectureTime) => lectureTime.index);
}
