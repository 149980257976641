import { Size } from "../../../../Util/Constants/Size";
import { ISuggestion } from "../../../../Components/AutoComplete";
import { IOption } from "../../../../Components/CustomComponents/CustomSelectField";
import { IOrganization } from "@violet/common";
import _ from "lodash";
import { paymentConditionLabels } from "../../../../Util/Constants/PaymentCondition";
import CustomAutoComplete from "../../../../Components/CustomComponents/CustomAutoComplete";
import CustomSection from "../../../../Components/CustomComponents/CustomSection";
import CustomCheckbox from "../../../../Components/CustomComponents/CustomCheckbox";
import {
  getFieldLabel,
  OrganizationField,
} from "../../../../Store/Appearance/RequiredFields/FieldNames";
import SelectField from "../../../../Components/SelectField";
import CustomTextField from "../../../../Components/CustomComponents/CustomTextField";
import { isEmpty } from "../../../../Util/StringUtil";
import styles from "./OrganizationSection.module.css";
import { useNavigate } from "react-router";
import { getOrganizationPath } from "../../../Path";

interface OrganizationSectionProps {
  organizationPage: boolean;
  organization: IOrganization;
  requiredFields: OrganizationField[];
  referralWayOptions: IOption[];
  isOrderSelected: boolean;
  organizationIdAlreadyExists: boolean;
  organizationSuggestions: IOrganizationSuggestion[];
  onOrganizationChange: (
    field: OrganizationField
  ) => (value: string | boolean) => void;
  selectOrganization: (organizationId: number) => void;
}

export default function OrganizationSection(props: OrganizationSectionProps) {
  const org = props.organization;
  const navigate = useNavigate();

  const paymentConditionsSuggestions = _.values(
    paymentConditionLabels
  ).map<ISuggestion>((condition) => ({
    label: condition,
    key: condition,
  }));

  const showOrganizationNotes =
    props.organizationPage || !isEmpty(org.organizationNotes);

  return (
    <CustomSection
      title="פרטי הארגון"
      onClickTitle={
        props.organizationPage
          ? undefined
          : () => navigate(getOrganizationPath(org.id))
      }
    >
      <div className={styles.section}>
        <CustomAutoComplete
          field={OrganizationField.organizationName}
          valueObject={org}
          requiredFields={props.requiredFields}
          onTextChange={props.onOrganizationChange(
            OrganizationField.organizationName
          )}
          suggestions={props.organizationSuggestions}
          onSuggestionSelected={(suggestion: ISuggestion | null) => {
            if (!suggestion) {
              return;
            }
            const organizationSuggestion =
              suggestion as IOrganizationSuggestion;
            props.selectOrganization(organizationSuggestion.organizationId);
          }}
          size={Size.XXXL}
          disabled={!props.organizationPage && props.isOrderSelected}
        />
        <CustomTextField
          field={OrganizationField.organizationAddress}
          valueObject={org}
          requiredFields={props.requiredFields}
          onChange={props.onOrganizationChange(
            OrganizationField.organizationAddress
          )}
        />
        <CustomTextField
          field={OrganizationField.organizationCity}
          valueObject={org}
          requiredFields={props.requiredFields}
          onChange={props.onOrganizationChange(
            OrganizationField.organizationCity
          )}
          size={Size.M}
        />
        <CustomTextField
          field={OrganizationField.organizationPostalCode}
          valueObject={org}
          requiredFields={props.requiredFields}
          onChange={props.onOrganizationChange(
            OrganizationField.organizationPostalCode
          )}
          size={Size.M}
        />
        <CustomTextField
          field={OrganizationField.companyId}
          valueObject={org}
          requiredFields={props.requiredFields}
          onChange={props.onOrganizationChange(OrganizationField.companyId)}
          size={Size.M}
          error={props.organizationIdAlreadyExists}
        />
        <CustomAutoComplete
          field={OrganizationField.paymentConditions}
          valueObject={org}
          requiredFields={props.requiredFields}
          onTextChange={props.onOrganizationChange(
            OrganizationField.paymentConditions
          )}
          suggestions={paymentConditionsSuggestions}
          size={Size.XL}
        />
        <CustomTextField
          field={OrganizationField.externalInvoiceHandler}
          valueObject={org}
          requiredFields={props.requiredFields}
          onChange={props.onOrganizationChange(
            OrganizationField.externalInvoiceHandler
          )}
        />

        <SelectField
          label={getFieldLabel(OrganizationField.referralWay)}
          value={org.referralWay}
          onChange={props.onOrganizationChange(OrganizationField.referralWay)}
          error={
            !org.referralWay &&
            props.requiredFields &&
            _.includes(props.requiredFields, OrganizationField.referralWay)
          }
          options={props.referralWayOptions}
          size={Size.XL}
        />

        <CustomTextField
          field={OrganizationField.referralWayDetails}
          valueObject={org}
          requiredFields={props.requiredFields}
          onChange={props.onOrganizationChange(
            OrganizationField.referralWayDetails
          )}
          size={Size.XXL}
        />

        {props.organizationPage && (
          <CustomCheckbox
            field={OrganizationField.internalOrderIdRequired}
            valueObject={org}
            requiredFields={props.requiredFields}
            onChange={props.onOrganizationChange(
              OrganizationField.internalOrderIdRequired
            )}
          />
        )}

        {showOrganizationNotes && (
          <CustomTextField
            field={OrganizationField.organizationNotes}
            valueObject={org}
            requiredFields={props.requiredFields}
            onChange={props.onOrganizationChange(
              OrganizationField.organizationNotes
            )}
            size={Size.XXXL}
          />
        )}
      </div>
    </CustomSection>
  );
}

export interface IOrganizationSuggestion extends ISuggestion {
  organizationId: number;
}
