import { Size } from "../../../../Util/Constants/Size";
import CustomSection from "../../../../Components/CustomComponents/CustomSection";
import ProformaInvoiceDateContainer from "./ProformaInvoiceDateContainer";
import OrderCheckbox from "../ConnectedCustomComponents/OrderCheckbox";
import { OrderField } from "../../../../Store/Appearance/RequiredFields/FieldNames";
import OrderTextField from "../ConnectedCustomComponents/OrderTextField";
import OrderDatePicker from "../ConnectedCustomComponents/OrderDatePicker";

export default function InvoiceSection() {
  return (
    <CustomSection title="חשבונאות">
      <OrderTextField field={OrderField.proformaInvoiceNumber} size={Size.M} />
      <ProformaInvoiceDateContainer />
      <OrderDatePicker field={OrderField.expectedPayDate} />
      <OrderTextField field={OrderField.internalOrderNumber} />
      <OrderTextField field={OrderField.receiptNumber} size={Size.M} />
      <OrderDatePicker field={OrderField.taxInvoiceDate} />
      <OrderTextField field={OrderField.taxInvoiceNumber} size={Size.M} />
      <OrderCheckbox field={OrderField.externalInvoiceSent} />
    </CustomSection>
  );
}
