import React from "react";
import { isEmptyValue } from "../../../../Util/StringUtil";
import { CustomFlatButton } from "../../../../Components/CustomComponents/CustomButtons";
import { IOrganization } from "@violet/common";
import {
  Field,
  getFieldLabel,
  OrganizationField,
} from "../../../../Store/Appearance/RequiredFields/FieldNames";

export function shouldSaveNewOrder(
  isSelectedOrganization: boolean,
  selectedOrganization: IOrganization,
  openDialog: (
    title: string,
    content: string,
    actions?: React.ReactNode[]
  ) => void,
  closeDialog: () => void,
  orderMissingFields: Field[],
  showRequiredFields: () => void,
  saveNewOrganization: () => void,
  saveNewOrder: () => void
) {
  if (orderMissingFields.length > 0) {
    // Not ready for saving - there are missing fields
    showRequiredFields();
    const content =
      "שדות חסרים:\n\n" +
      orderMissingFields.map((x) => getFieldLabel(x)).join("\n");
    openDialog("נא למלא את כל שדות החובה", content);
    return false;
  }

  if (!isSelectedOrganization) {
    if (
      isEmptyValue(selectedOrganization, OrganizationField.organizationName)
    ) {
      openDialog("לא נבחר ארגון", "לשמירת הזמנה יש לבחור ארגון");
      return;
    }

    const dialogContent = `ארגון "${selectedOrganization.organizationName}" אינו מזוהה. האם זהו ארגון חדש או קיים?`;
    openDialog("לא נבחר ארגון", dialogContent, [
      <CustomFlatButton
        key="ארגון חדש"
        label="ארגון חדש"
        onClick={async () => {
          await saveNewOrganization();
          saveNewOrder();
        }}
      />,
      <CustomFlatButton
        key="ארגון קיים"
        label="ארגון קיים"
        onClick={closeDialog}
      />,
    ]);
    return false;
  }

  return true;
}
