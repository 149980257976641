import { useDispatch, useSelector } from "react-redux";
import { OrderField } from "../../../../Store/Appearance/RequiredFields/FieldNames";
import { getOrderRequiredFields } from "../../../../Store/Appearance/RequiredFields/RequiredFieldsSelectors";
import { updateSelectedOrder } from "../../../../Store/SelectedOrder/Actions";
import { getSelectedOrder } from "../../../../Store/SelectedOrder/Selectors";
import CustomDatePicker from "../../../../Components/CustomComponents/CustomDatePicker";

interface OrderDatePickerProps {
  field: OrderField;
  fullWidth?: boolean;
}

export default function OrderDatePicker(props: OrderDatePickerProps) {
  const dispatch = useDispatch();
  const selectedOrder = useSelector(getSelectedOrder);
  const requiredFields = useSelector(getOrderRequiredFields);

  return (
    <CustomDatePicker
      field={props.field}
      valueObject={selectedOrder}
      requiredFields={requiredFields}
      onChange={(newValue) =>
        dispatch(updateSelectedOrder(props.field, newValue))
      }
      fullWidth={props.fullWidth}
    />
  );
}
