import React, { useEffect, useState } from "react";
import LoginPage from "./Pages/LoginPage/LoginPage";
import AppDialog from "./Containers/Messages/AppDialogContainer";
import AppSnackbar from "./Containers/Messages/AppSnackBarContainer";
import WithGlobalTheme from "./ThemeDefault";
import { isLoggedIn } from "./Store/Firebase/Selectors";
import { useDispatch, useSelector } from "react-redux";
import styles from "./App.module.scss";
import "./variables.scss";
import AppMenu from "./Navigation/AppMenu";
import { useLocation } from "react-router";
import { isFinishedLoading } from "./Store/Appearance/Selectors";
import { initFirebase } from "./Store/Firebase/Actions";
import LoadingPage from "./Pages/LoadingPage";
import { Path } from "./Pages/Path";
import MobileViewContext, {
  isMenuSticky,
  isMobile,
  StickMenuContext,
} from "./Navigation/MobileViewContext";

const SCROLL_TO_ID = "scroll-to";
const RESIZE_ID = "resize";

interface AppProps {
  children: React.ReactNode;
}

export default function App(props: AppProps) {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [mobileView, setMobileView] = useState(isMobile());
  const [stickyMeny, setIsStickyMenu] = useState(isMenuSticky());

  useEffect(() => {
    dispatch(initFirebase());
  }, [dispatch]);

  useEffect(() => {
    // Scroll to top when page changes
    document.getElementById(SCROLL_TO_ID)!.scrollIntoView();
    document.title = getAppTitle(pathname);
  }, [pathname]);

  function handleWindowSizeChange() {
    setMobileView(isMobile());
    setIsStickyMenu(isMenuSticky());
  }
  useEffect(() => {
    window.addEventListener(RESIZE_ID, handleWindowSizeChange);
    return () => {
      window.removeEventListener(RESIZE_ID, handleWindowSizeChange);
    };
  }, []);

  return (
    <WithGlobalTheme>
      <MobileViewContext.Provider value={[mobileView, setMobileView]}>
        <StickMenuContext.Provider value={[stickyMeny, setIsStickyMenu]}>
          <div className={styles.app}>
            <AppMenu />

            <div className={styles.appContent}>
              <div id={SCROLL_TO_ID} />
              <Content {...props} />
            </div>

            <AppDialog />
            <AppSnackbar />
          </div>
        </StickMenuContext.Provider>
      </MobileViewContext.Provider>
    </WithGlobalTheme>
  );
}

function Content(props: AppProps) {
  const loggedIn = useSelector(isLoggedIn);
  const finishedLoading = useSelector(isFinishedLoading);

  if (loggedIn === false) {
    return <LoginPage />;
  }

  if (loggedIn === undefined) {
    return <LoadingPage />;
  }

  // Logged in
  if (!finishedLoading) {
    return <LoadingPage showSpinner />;
  }

  return <>{props.children}</>;
}

function getAppTitle(pathname: string) {
  switch (pathname) {
    case Path.Order:
    case Path.PublicCourse:
      return "קורס ציבורי";
    case Path.Organization:
      return "ארגון";
    case Path.FutureLectures:
      return "הרצאות עתידיות";
    case Path.ExpectedIncome:
      return "צבר הזמנות";
    case Path.WaitingPayment:
      return "ממתינות לתשלום";
    case Path.AllOrders:
      return "כל ההזמנות";
    case Path.AllPublicCourses:
      return "כל הקורסים הציבוריים";
    case Path.ActionRequired:
      return "התראות";
    case Path.Yaron:
      return "הרצאות של ירון";
    case Path.Bi:
      return "איך הגיעו אלינו";
    case Path.OfferTemplates:
      return "תבניות הצעות מחיר";
    case Path.UploadFiles:
      return "העלאת קבצים";
    case Path.Search:
      return "חיפוש";
    case Path.Phone:
    case Path.Home:
      return "Violet";

    default:
      if (pathname.startsWith(Path.Order) || pathname.startsWith(Path.Phone)) {
        const parts = pathname.split("/");
        const id = parts.length < 3 ? "" : parts[2];
        return "הזמנה " + id;
      }
      if (pathname.startsWith(Path.PublicCourse)) {
        return "קורס ציבורי";
      }
      if (pathname.startsWith(Path.Organization)) {
        return "ארגון";
      }
      if (pathname.startsWith(Path.ActionRequired)) {
        return "התראות";
      }
      if (pathname.startsWith(Path.Search)) {
        return "חיפוש";
      }
      console.warn("No page title for path: " + pathname);
      return "Violet";
  }
}
