import React, { useContext } from "react";
import InternalLectureDetails from "./InternalCourse/InternalLectureDetails";
import PublicCourseLectureDetails from "./PublicCourse/PublicCourseLectureDetails";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TabKey } from "@violet/common";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedTab } from "../../../../Store/SelectedOrder/Selectors";
import { updateSelectedOrder } from "../../../../Store/SelectedOrder/Actions";
import OnlineCourseLectureDetails from "./InternalCourse/OnlineCourseLectureDetails";
import MobileViewContext from "../../../../Navigation/MobileViewContext";

export default function LectureDetailsSection() {
  const selectedTabKey = useSelector(getSelectedTab);
  const dispatch = useDispatch();
  const [mobile] = useContext(MobileViewContext);

  const onTabClick = (_: React.ChangeEvent<{}>, value: any) =>
    dispatch(updateSelectedOrder("lectureDetailsTabKey", value));

  return (
    <>
      {!mobile && (
        <Tabs
          value={selectedTabKey}
          onChange={onTabClick}
          indicatorColor="primary"
        >
          <Tab label="קורס פנים ארגוני" value={TabKey.internalTabKey} />
          <Tab label="קורס מקוון" value={TabKey.onlineCourseTabKey} />
          <Tab label="קורס ציבורי" value={TabKey.publicCourseTabKey} />
        </Tabs>
      )}

      {selectedTabKey === TabKey.internalTabKey && <InternalLectureDetails />}
      {selectedTabKey === TabKey.onlineCourseTabKey && (
        <OnlineCourseLectureDetails />
      )}
      {selectedTabKey === TabKey.publicCourseTabKey && (
        <PublicCourseLectureDetails />
      )}
    </>
  );
}
