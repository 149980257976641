import { useDispatch, useSelector } from "react-redux";
import { IOption } from "../../../../Components/CustomComponents/CustomSelectField";
import SelectField from "../../../../Components/SelectField";
import {
  getFieldLabel,
  OrderField,
} from "../../../../Store/Appearance/RequiredFields/FieldNames";
import { getOrderRequiredFields } from "../../../../Store/Appearance/RequiredFields/RequiredFieldsSelectors";
import { updateSelectedOrder } from "../../../../Store/SelectedOrder/Actions";
import { getSelectedOrder } from "../../../../Store/SelectedOrder/Selectors";
import _ from "lodash";
import { Size } from "../../../../Util/Constants/Size";

interface OrderSelectFieldProps {
  field: OrderField;
  forceErrorColor?: boolean;
  fullWidth?: boolean;
  options: IOption[];
  size?: Size;
}

export default function OrderSelectField(props: OrderSelectFieldProps) {
  const dispatch = useDispatch();
  const selectedOrder = useSelector(getSelectedOrder);
  const requiredFields = useSelector(getOrderRequiredFields);

  const value = selectedOrder[props.field];
  const requiredAndMissing =
    requiredFields &&
    !value &&
    _.includes(requiredFields, props.field as string);

  return (
    <SelectField
      label={getFieldLabel(props.field)}
      value={selectedOrder[props.field] as string}
      onChange={(newValue) =>
        dispatch(updateSelectedOrder(props.field, newValue))
      }
      error={requiredAndMissing}
      fullWidth={props.fullWidth}
      options={props.options}
      size={props.size}
    />
  );
}
