import {
  CLEAR_SELECTED_ORDER,
  SELECT_ORDER,
  DUPLICATE_ORDER,
  ORDER_SAVED,
  UPDATE_SELECTED_ORDER,
} from "./ActionTypes";
import { getSelectedOrder } from "./Selectors";
import { getNextOrderId, getOrderById } from "../Orders/Selectors";
import { isEmptyValue } from "../../Util/StringUtil";
import {
  clearSelectedPublicCourse,
  selectPublicCourse,
} from "../SelectedPublicCourse/Actions";
import * as _ from "lodash";
import { sendDataToDatabase } from "../Firebase/Actions";
import {
  selectOrganization,
  sendSelectedOrganizationToDatabase,
} from "../SelectedOrganization/Actions";
import {
  hideRequiredFields,
  openDialog,
  openSnackbar,
} from "../Appearance/Actions";
import { getOrganizationById } from "../Organizations/Selectors";
import { getSelectedOrganization } from "../SelectedOrganization/Selectors";
import { getSelectedPublicCourse } from "../SelectedPublicCourse/Selectors";
import { IDispatch, IGetState, IState } from "../../Interfaces/ReduxInterfaces";
import {
  calculateDuration,
  calculateOrderStatus,
  COLLECTION,
  ILectureTime,
  IPublicCourseParticipant,
  OrderFile,
  TabKey,
} from "@violet/common";
import { updateObject } from "../../Util/ObjectUpdater";
import uuid from "uuid-js";
import {
  LectureTimeField,
  OrderField,
  PublicCourseParticipantField,
} from "../Appearance/RequiredFields/FieldNames";

export function selectOrder(orderId: number) {
  return function (dispatch: IDispatch, getState: IGetState) {
    const order = getOrderById(getState(), orderId.toString());
    dispatch(selectOrganization(order.organizationId));

    if (!isEmptyValue(order, OrderField.publicCourseId)) {
      dispatch(selectPublicCourse(order.publicCourseId.toString()));
    }

    dispatch({
      type: SELECT_ORDER,
      payload: order,
    });
  };
}

export function updateSelectedOrder(
  key: OrderField | "lectureDetailsTabKey" | "publicCourseParticipants",
  value:
    | string
    | boolean
    | number
    | TabKey
    | ILectureTime[]
    | IPublicCourseParticipant[]
    | OrderFile[]
) {
  return function updateSelectedOrder(
    dispatch: IDispatch,
    getState: IGetState
  ) {
    let order = updateObject(getSelectedOrder(getState()), {
      [key]: value,
    });

    order.status = calculateOrderStatus(
      order,
      getSelectedPublicCourse(getState())
    );

    dispatch({
      type: UPDATE_SELECTED_ORDER,
      payload: order,
    });

    if (key === OrderField.orderFiles) {
      // When updating order files, immidetly update db upon upload / delete.
      sendDataToDatabase(`/${COLLECTION.ORDERS}/${order.id}/orderFiles`, value);
    }
  };
}

function getSelectedOrderLectureTimes(state: IState) {
  return _.cloneDeep(getSelectedOrder(state).lectureTimes);
}

function getSelectedOrderPublicCourseParticipants(state: IState) {
  return _.cloneDeep(getSelectedOrder(state).publicCourseParticipants);
}

export function updateLectureTime(
  key: string,
  value: string | boolean,
  lectureTimeIndex: number
) {
  return function updateLectureTime(dispatch: IDispatch, getState: IGetState) {
    const allLectureTimes = getSelectedOrderLectureTimes(getState());
    const lectureTime = allLectureTimes[lectureTimeIndex];
    // @ts-ignore
    lectureTime[key] = value;
    lectureTime.duration = calculateDuration(allLectureTimes[lectureTimeIndex]);

    dispatch(updateSelectedOrder(OrderField.lectureTimes, allLectureTimes));
  };
}

export function removeLectureTimeApproval(lectureTimeIndex: number) {
  return function removeLectureTimeApproval(
    dispatch: IDispatch,
    getState: IGetState
  ) {
    const allLectureTimes = getSelectedOrderLectureTimes(getState());
    const lectureTime = allLectureTimes[lectureTimeIndex];

    allLectureTimes[lectureTimeIndex] = _.omit(
      lectureTime,
      "offerApprovalDetails"
    );

    dispatch(updateSelectedOrder(OrderField.lectureTimes, allLectureTimes));
  };
}

export function addNewLectureTime() {
  return function addNewLectureTime(dispatch: IDispatch, getState: IGetState) {
    const thisSelectedOrder = getSelectedOrder(getState());
    const lectureTimes = _.hasIn(thisSelectedOrder, OrderField.lectureTimes)
      ? getSelectedOrderLectureTimes(getState())
      : [];
    lectureTimes.push({
      offerToken: uuid.create(4).toString(),
    } as ILectureTime);

    dispatch(updateSelectedOrder(OrderField.lectureTimes, lectureTimes));
  };
}

export function deleteLectureTime(lectureTimeIndex: number) {
  return function addNewLectureTime(dispatch: IDispatch, getState: IGetState) {
    const lectureTimes = getSelectedOrderLectureTimes(getState());
    lectureTimes.splice(lectureTimeIndex, 1);

    dispatch(updateSelectedOrder(OrderField.lectureTimes, lectureTimes));
  };
}

export function updatePublicCourseParticipant(
  key: string,
  value: string | number[] | boolean,
  participantIndex: number
) {
  return function updatePublicCourseParticipant(
    dispatch: IDispatch,
    getState: IGetState
  ) {
    const publicCourseParticipants = getSelectedOrderPublicCourseParticipants(
      getState()
    );
    // @ts-ignore
    publicCourseParticipants[participantIndex][key] = value;
    dispatch(
      updateSelectedOrder("publicCourseParticipants", publicCourseParticipants)
    );
  };
}

export function updatePublicCourseLectureParticipating(
  lectureId: number,
  isAttending: boolean,
  participantIndex: number
) {
  return function updatePublicCourseLectureParticipating(
    dispatch: IDispatch,
    getState: IGetState
  ) {
    const publicCourseParticipants = getSelectedOrderPublicCourseParticipants(
      getState()
    );
    const participant = publicCourseParticipants[participantIndex];
    let lecturesAttending = _.hasIn(participant, "lecturesAttending")
      ? participant.lecturesAttending
      : [];
    if (isAttending) {
      lecturesAttending.push(lectureId);
      lecturesAttending.sort((a, b) => a - b);
    } else {
      lecturesAttending = _.without(lecturesAttending, lectureId);
    }

    publicCourseParticipants[participantIndex].lecturesAttending =
      lecturesAttending;
    dispatch(
      updateSelectedOrder("publicCourseParticipants", publicCourseParticipants)
    );
  };
}

export function removeParticipantsFromAllLectures() {
  return function removeParticipantsFromAllLectures(
    dispatch: IDispatch,
    getState: IGetState
  ) {
    if (isEmptyValue(getSelectedOrder(getState()), "publicCourseParticipants"))
      return;

    const publicCourseParticipants = getSelectedOrderPublicCourseParticipants(
      getState()
    );
    for (const participant in publicCourseParticipants) {
      if (publicCourseParticipants[participant].lecturesAttending)
        publicCourseParticipants[participant].lecturesAttending = [];
    }
    dispatch(
      updateSelectedOrder("publicCourseParticipants", publicCourseParticipants)
    );
  };
}

export function calculateCostOfParitipant(participantId: number) {
  return function calculateCostOfParitipant(
    dispatch: IDispatch,
    getState: IGetState
  ) {
    const publicCourse = getSelectedPublicCourse(getState());
    const participant = getSelectedOrderPublicCourseParticipants(getState())[
      participantId
    ];

    const attendedLectures = _.filter(publicCourse.lectures, (lecture) =>
      _.includes(participant.lecturesAttending, lecture.id)
    );
    const cost = _.sum(
      _.map(attendedLectures, (lecture) => _.parseInt(lecture.price))
    );

    if (isNaN(cost)) {
      // Probably some lecture is missing a price.
      return;
    }

    dispatch(
      updatePublicCourseParticipant(
        PublicCourseParticipantField.participantCost,
        cost + "",
        participantId
      )
    );
  };
}

export function removeParticipant(participantId: number) {
  return function removeParticipant(dispatch: IDispatch, getState: IGetState) {
    const publicCourseParticipants = getSelectedOrderPublicCourseParticipants(
      getState()
    );
    publicCourseParticipants.splice(participantId, 1);
    dispatch(
      updateSelectedOrder("publicCourseParticipants", publicCourseParticipants)
    );
  };
}

export function markOrderAsSaved() {
  return {
    type: ORDER_SAVED,
  };
}

export function sendSelectedOrderToDatabase() {
  return async function sendSelectedOrderToDatabase(
    dispatch: IDispatch,
    getState: IGetState
  ) {
    await dispatch(
      updateSelectedOrder(OrderField.changedDate, new Date().toJSON())
    );
    const selectedOrder = getSelectedOrder(getState());

    return sendDataToDatabase(
      "/" + COLLECTION.ORDERS + "/" + selectedOrder.id,
      selectedOrder
    );
  };
}

export function clearSelectedOrder() {
  return {
    type: CLEAR_SELECTED_ORDER,
  };
}

export function fillNewOrderMissingFields() {
  return function fillNewOrderMissingFields(
    dispatch: IDispatch,
    getState: IGetState
  ) {
    let idPromise;
    let createdPromise;
    let organizationIdPromise;
    if (!getSelectedOrder(getState()).hasOwnProperty(OrderField.id)) {
      idPromise = dispatch(
        updateSelectedOrder(OrderField.id, getNextOrderId(getState()))
      );
      createdPromise = dispatch(
        updateSelectedOrder(OrderField.createdDate, new Date().toJSON())
      );
    }

    if (!getSelectedOrder(getState()).hasOwnProperty(OrderField.organizationId))
      organizationIdPromise = dispatch(
        updateSelectedOrder(
          OrderField.organizationId,
          getSelectedOrganization(getState()).id
        )
      );

    return Promise.all([idPromise, organizationIdPromise, createdPromise]);
  };
}

export function saveNewOrder(navigateToNewOrder: (orderId: number) => void) {
  return async function saveNewOrder(dispatch: IDispatch, getState: IGetState) {
    try {
      await dispatch(fillNewOrderMissingFields());
      await dispatch(sendSelectedOrderToDatabase());
      const newOrderId = getSelectedOrder(getState()).id;
      const snackbarMessage = "הזמנה מספר {0} נשמרה בהצלחה".replace(
        "{0}",
        newOrderId.toString()
      );
      dispatch(openSnackbar(snackbarMessage));
      dispatch(markOrderAsSaved());
      navigateToNewOrder(newOrderId);

      dispatch(hideRequiredFields());

      // Check if there are changes in organization
      const selectedOrganization = getSelectedOrganization(getState());
      const savedOrganization = getOrganizationById(
        getState(),
        getSelectedOrder(getState()).organizationId.toString()
      );
      if (!_.isEqual(selectedOrganization, savedOrganization)) {
        dispatch(sendSelectedOrganizationToDatabase());
      }
    } catch (error) {
      dispatch(
        openDialog("שגיאה בשמירת הזמנה", "חלה שגיאה בשמירת ההזמנה בשרת")
      );
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };
}

export function duplicateOrder() {
  return async function duplicateOrder(
    dispatch: IDispatch,
    getState: IGetState
  ) {
    const order = getSelectedOrder(getState());
    const fieldsToRemove = [
      OrderField.id,
      OrderField.orderApproved,
      OrderField.publicCourseId,
      OrderField.proformaInvoiceNumber,
      OrderField.proformaInvoiceDate,
      OrderField.expectedPayDate,
      OrderField.internalOrderNumber,
      OrderField.receiptNumber,
      OrderField.taxInvoiceDate,
      OrderField.taxInvoiceNumber,
      OrderField.externalInvoiceSent,
      OrderField.orderFiles,
      OrderField.createdDate,
      OrderField.changedDate,
    ];
    const duplicatedOrder = _.omit(order, fieldsToRemove);
    if (!duplicatedOrder.notes) duplicatedOrder.notes = "";
    duplicatedOrder.notes =
      "הזמנה זו שוכפלה מהזמנה מספר " + order.id + ".\n" + duplicatedOrder.notes;

    const lectureFieldsToRemove = [
      LectureTimeField.date,
      LectureTimeField.offerDate,
      LectureTimeField.tie,
      LectureTimeField.videoCallLink,
    ];
    if (duplicatedOrder.lectureTimes) {
      duplicatedOrder.lectureTimes = duplicatedOrder.lectureTimes.map(
        (lecture) => {
          const res = _.omit(lecture, lectureFieldsToRemove) as ILectureTime;
          res.offerToken = uuid.create(4).toString();
          return res;
        }
      );
    }

    if (duplicatedOrder.publicCourseParticipants) {
      duplicatedOrder.publicCourseParticipants = [];
    }
    duplicatedOrder.status = calculateOrderStatus(
      duplicatedOrder,
      getSelectedPublicCourse(getState())
    );

    dispatch(clearSelectedPublicCourse());
    dispatch({
      type: DUPLICATE_ORDER,
      payload: duplicatedOrder,
    });
  };
}
