import Button from "@mui/material/Button";
import { useRef, useState } from "react";
import styles from "./UploadFileButton.module.css";
import UploadIcon from "@mui/icons-material/Upload";
import AttachmentIcon from "@mui/icons-material/Attachment";

interface UploadFileButtonProps {
  onUploadButtonClicked: (file: File) => void;
  message?: string;
  setMessage: (message: string) => void;
  error?: string;
  setError: (message: string) => void;
}

export default function UploadFileButton(props: UploadFileButtonProps) {
  const [file, setFile] = useState<File | null>(null);
  const uploadInputRef = useRef<HTMLInputElement | null>(null);

  return (
    <div className={styles.field}>
      <input
        ref={uploadInputRef}
        type="file"
        style={{ display: "none" }}
        onChange={(e) => {
          props.setError("");
          props.setMessage("");
          if (e.target.files?.length !== 1) {
            props.setError("מספר קבצים לא תקין");
            setFile(null);
            return;
          }
          setFile(e.target.files[0]);
        }}
      />
      <Button
        onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
        variant={"outlined"}
        startIcon={<AttachmentIcon />}
      >
        בחר קובץ
      </Button>

      {props.error ? (
        <div className={styles.error}>{props.error}</div>
      ) : props.message ? (
        <div className={styles.fileName}>{props.message}</div>
      ) : (
        <div className={styles.fileName}>{file?.name}</div>
      )}

      {file && (
        <Button
          onClick={async () => {
            await props.onUploadButtonClicked(file);
            setFile(null);
          }}
          startIcon={<UploadIcon />}
        >
          העלה קובץ
        </Button>
      )}
    </div>
  );
}
