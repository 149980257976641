import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as _ from "lodash";
import { IStringObject } from "@violet/common";

interface CustomTableProps {
  headers: IStringObject[];
  children?: React.ReactNode;
}

export default function CustomTable(props: CustomTableProps) {
  let headerValues = props.headers.map((header) => Object.values(header)[0]);

  return (
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          {_.map(headerValues, (title, index) => (
            <TableCell key={index}>{title}</TableCell>
          ))}
        </TableRow>
      </TableHead>

      <TableBody>{props.children}</TableBody>
    </Table>
  );
}
