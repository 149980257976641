import Checkbox from "../Checkbox";
import {
  Field,
  getFieldLabel,
} from "../../Store/Appearance/RequiredFields/FieldNames";
import { shouldMarkWithError } from "../../Store/Appearance/RequiredFields/RequiredFieldsUtil";

interface CustomCheckboxProps {
  field: Field;
  valueObject: any;
  requiredFields?: Field[];
  onChange: (newValue: boolean) => void;
  forceErrorColor?: boolean;
}

export default function CustomCheckbox(props: CustomCheckboxProps) {
  const value = props.valueObject[props.field];
  const error =
    (props.forceErrorColor && value === true) ||
    (value === undefined &&
      shouldMarkWithError(props.field, props.requiredFields, value));

  return (
    <Checkbox
      label={getFieldLabel(props.field)}
      value={value === true}
      onChange={props.onChange}
      error={error}
    />
  );
}
