import { Size } from "../../../Util/Constants/Size";
import GuestLecturerFields from "./GuestLecturerFields";
import CustomDivider from "../../../Components/CustomComponents/CustomDivider";
import IndexAvatar from "../../../Components/IndexAvatar";
import { toSuggestions } from "../../../Components/AutoComplete";
import { IPublicCourse } from "@violet/common";
import CustomCheckbox from "../../../Components/CustomComponents/CustomCheckbox";
import CustomAutoComplete from "../../../Components/CustomComponents/CustomAutoComplete";
import { useDispatch, useSelector } from "react-redux";
import { updatePublicCourseLecture } from "../../../Store/SelectedPublicCourse/Actions";
import { getOfferedLectures, getTies } from "../../../Store/Lists/Selectors";
import styles from "./CourseLecturesInstance.module.css";
import classNames from "classnames";
import { PublicCourseLectureField } from "../../../Store/Appearance/RequiredFields/FieldNames";
import CustomDatePicker from "../../../Components/CustomComponents/CustomDatePicker";
import CustomTextField from "../../../Components/CustomComponents/CustomTextField";

export default function CourseLecturesInstance(
  props: CourseLecturesInstanceProps
) {
  const index = props.lectureId;
  const isOnline = props.selectedPublicCourse.onlineCourse;
  const publicCourseLecture =
    props.selectedPublicCourse.lectures[props.lectureId];
  const offeredLectures = toSuggestions(useSelector(getOfferedLectures));
  const ties = toSuggestions(useSelector(getTies));

  const dispatch = useDispatch();
  const onChange =
    (key: PublicCourseLectureField) => (newValue: string | boolean) =>
      dispatch(updatePublicCourseLecture(key, newValue, index));

  // TODO implement required fields here
  return (
    <div
      className={classNames({
        [styles.instance]: true,
        [styles.evenBackground]: props.index % 2 === 1,
        [styles.borderTop]: index > 0,
      })}
    >
      <IndexAvatar index={props.index} />
      <div className={styles.fields}>
        <CustomDatePicker
          field={PublicCourseLectureField.date}
          valueObject={publicCourseLecture}
          onChange={onChange(PublicCourseLectureField.date)}
        />
        <CustomAutoComplete
          field={PublicCourseLectureField.topic}
          valueObject={publicCourseLecture}
          onTextChange={onChange(PublicCourseLectureField.topic)}
          suggestions={offeredLectures}
          size={Size.XXL}
        />
        <CustomTextField
          field={PublicCourseLectureField.startTime}
          valueObject={publicCourseLecture}
          onChange={onChange(PublicCourseLectureField.startTime)}
          size={Size.M}
        />
        <CustomTextField
          field={PublicCourseLectureField.endTime}
          valueObject={publicCourseLecture}
          onChange={onChange(PublicCourseLectureField.endTime)}
          size={Size.M}
        />
        <CustomTextField
          field={PublicCourseLectureField.duration}
          valueObject={publicCourseLecture}
          onChange={onChange(PublicCourseLectureField.duration)}
          size={Size.S}
        />
        <CustomAutoComplete
          field={PublicCourseLectureField.tie}
          valueObject={publicCourseLecture}
          onTextChange={onChange(PublicCourseLectureField.tie)}
          suggestions={ties}
          size={Size.M}
        />
        <CustomTextField
          field={PublicCourseLectureField.price}
          valueObject={publicCourseLecture}
          onChange={onChange(PublicCourseLectureField.price)}
          size={Size.M}
        />
        {isOnline ? (
          <CustomTextField
            field={PublicCourseLectureField.videoCallLink}
            valueObject={publicCourseLecture}
            onChange={onChange(PublicCourseLectureField.videoCallLink)}
            size={Size.XXXL}
          />
        ) : (
          <>
            <CustomTextField
              field={PublicCourseLectureField.roomCost}
              valueObject={publicCourseLecture}
              onChange={onChange(PublicCourseLectureField.roomCost)}
              size={Size.M}
            />
            <CustomTextField
              field={PublicCourseLectureField.pages}
              valueObject={publicCourseLecture}
              onChange={onChange(PublicCourseLectureField.pages)}
              size={Size.M}
            />
          </>
        )}
        <CustomCheckbox
          field={PublicCourseLectureField.active}
          valueObject={publicCourseLecture}
          onChange={onChange(PublicCourseLectureField.active)}
        />
        <GuestLecturerFields
          publicCourseLecture={publicCourseLecture}
          lectureId={index}
        />
      </div>
      <CustomDivider />
    </div>
  );
}

interface CourseLecturesInstanceProps {
  selectedPublicCourse: IPublicCourse;
  lectureId: number;
  index: number;
}
