import { ILectureTime, calculatePreparationTimes } from "@violet/common";
import CustomDialog from "../../../../../../Components/CustomComponents/CustomDialog";
import { useState } from "react";
import { CustomIconButton } from "../../../../../../Components/CustomComponents/CustomButtons";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {
  getFieldLabel,
  LectureTimeField,
} from "../../../../../../Store/Appearance/RequiredFields/FieldNames";

export default function PreparationTimes(props: PreparationTimesProps) {
  const [open, setOpen] = useState(false);
  const preparationTimes = calculatePreparationTimes(props.lectureTime);

  return (
    <>
      <CustomIconButton onClick={() => setOpen(true)} tooltip="זמני התארגנות">
        <AccessTimeIcon />
      </CustomIconButton>
      <CustomDialog
        open={open}
        onRequestClose={() => setOpen(false)}
        title="זמני התארגנות"
      >
        {preparationTimes ? (
          <div style={styles.container}>
            <Field
              valueObject={preparationTimes}
              field={LectureTimeField.wakeUpTime}
            />
            <Field
              valueObject={preparationTimes}
              field={LectureTimeField.leaveHomeTime}
            />
            <Field
              valueObject={preparationTimes}
              field={LectureTimeField.arriveTime}
            />
            <Field
              valueObject={preparationTimes}
              field={LectureTimeField.lectureStartTime}
            />
          </div>
        ) : (
          <div>חסרים פרטים לחישוב זמני התארגנות</div>
        )}
      </CustomDialog>
    </>
  );
}

const styles = {
  container: {
    display: "flex" as "flex",
    flexDirection: "column" as "column",
    paddingLeft: 45,
  },
  field: {
    marginBottom: 10,
  },
  bold: {
    fontWeight: "bold" as "bold",
  },
};

function Field(props: { valueObject: any; field: LectureTimeField }) {
  const value = props.valueObject[props.field];
  return (
    <div style={styles.field}>
      <span style={styles.bold}>{getFieldLabel(props.field)}: </span>
      <span>{value}</span>
    </div>
  );
}

interface PreparationTimesProps {
  lectureTime: ILectureTime;
}
