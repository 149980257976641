import CustomPaperTable from "../../../Components/Table/CustomPaperTable";
import { getLecturesDetails } from "../../../Store/SelectedPublicCourse/Selectors";
import { IStringObject } from "@violet/common";
import { useSelector } from "react-redux";

export default function CourseLectureDetailsTable() {
  const elements = useSelector(getLecturesDetails);
  return (
    <CustomPaperTable
      title="פרטי ההרצאות"
      elements={elements}
      onEditButton={() => {}}
      tableHeaders={
        [
          { date: "תאריך" },
          { topic: "נושא" },
          { participantsCount: "מספר משתתפים" },
          { income: "הכנסות" },
        ] as IStringObject[]
      }
    />
  );
}
