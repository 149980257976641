import _ from "lodash";
import { IOrder, TRAVEL_COST_PRICE } from "@violet/common";
import { OrderField } from "../../../../Store/Appearance/RequiredFields/FieldNames";

const vatRate = 0.17;
export default function calculateSum(
  selectedOrder: IOrder,
  updateSelectedOrder: (key: OrderField, value: any) => void
) {
  try {
    const cost = calculateMathValue(selectedOrder.cost);
    if (cost === 0) return;

    const travelExpenses = _.round(
      TRAVEL_COST_PRICE * 2 * calculateMathValue(selectedOrder.oneWayDistance),
      2
    );
    const sum = _.round(
      cost + travelExpenses + calculateMathValue(selectedOrder.extraCosts),
      2
    );
    const vat = _.round(vatRate * sum, 2);
    const totalSum = _.round(sum + vat);

    updateOrder(
      updateSelectedOrder,
      OrderField.travelExpenses,
      travelExpenses.toFixed(2)
    );
    updateOrder(updateSelectedOrder, OrderField.sum, sum.toFixed(2));
    updateOrder(updateSelectedOrder, OrderField.vat, vat.toFixed(2));
    updateOrder(updateSelectedOrder, OrderField.totalSum, "" + totalSum);
  } catch (e) {
    return;
  }
}

function updateOrder(
  updateSelectedOrder: (key: OrderField, value: any) => void,
  key: OrderField,
  value: string
) {
  updateSelectedOrder(key, parseFloat(value) === 0 ? "" : value.toString());
}

export function calculateMathValue(value: string) {
  if (!value || value === "") return 0;

  const regexGroups = /^(\d+\.?\d*)\*?(\d*\.?\d*)?$/g.exec(value);
  if (regexGroups === null) throw new Error("Invalid format");

  if (regexGroups[2] === undefined) return parseFloat(regexGroups[1]);

  return parseFloat(regexGroups[1]) * parseFloat(regexGroups[2]);
}
