import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import CheckIcon from "@mui/icons-material/AddCircleOutline";
import * as _ from "lodash";
import { isEmptyValue } from "../../Util/StringUtil";
import { CustomIconButton } from "../CustomComponents/CustomButtons";
import { IStringObject, toPrintableDateFormat } from "@violet/common";
import styles from "./CustomTableRow.module.css";

interface CustomTableRowProps<TElement extends object> {
  element: TElement;
  headers: IStringObject[];
  onEditButton: (element: TElement) => void;
  onPickButton?: (element: TElement) => void;
  error?: boolean;
  index?: number;
}

export default function CustomTableRow<TElement extends object>(
  props: CustomTableRowProps<TElement>
) {
  const onClickCell = (headerKey: string) => () => {
    if (!props.onEditButton) return;

    if (headerKey === "pick") return;

    props.onEditButton(props.element);
  };

  const getCellContent = (headerKey: string) => {
    if (headerKey.toLowerCase().includes("date"))
      return isEmptyValue(props.element, headerKey)
        ? ""
        : //@ts-ignore
          toPrintableDateFormat(props.element[headerKey]);

    switch (headerKey) {
      case "pick":
        return (
          <CustomIconButton
            onClick={() =>
              props.onPickButton && props.onPickButton(props.element)
            }
          >
            <CheckIcon />
          </CustomIconButton>
        );
      default:
        //@ts-ignore
        return props.element[headerKey];
    }
  };

  const headerKeys = props.headers.map((header) => Object.keys(header)[0]);

  const rowClass =
    props.index !== undefined && props.index % 2 === 0
      ? styles.evenBackground
      : undefined;

  return (
    <TableRow className={rowClass}>
      {_.map(headerKeys, (headerKey: string) => (
        <TableCell key={headerKey}>
          <div style={{ cursor: "pointer" }} onClick={onClickCell(headerKey)}>
            {getCellContent(headerKey)}
          </div>
        </TableCell>
      ))}
    </TableRow>
  );
}
