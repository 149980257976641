import App from "./App";
import OrderPage from "./Pages/OrderPage/OrderPage";
import PublicCourse from "./Pages/PublicCoursePage/PublicCoursePage";
import OrganizationPage from "./Pages/OrganizationPage/OrganizationPage";
import WaitingPaymentPage from "./Pages/WaitingPaymentPage";
import ExpectedIncomePage from "./Pages/ExpectedIncomePage";
import FutureLecturesPage from "./Pages/FutureLecturesPage";
import AllOrdersPage from "./Pages/AllOrdersPage/AllOrdersPage";
import BiPage from "./Pages/BiPage";
import AllPublicCoursesPage from "./Pages/AllPublicCoursesPage";
import ActionRequiredPage from "./Pages/ActionRequiredPage";
import { Path } from "./Pages/Path";
import OfferTemplatesPage from "./Pages/OfferTemplatesPage/OfferTemplatesPage";
import UploadFilesPage from "./Pages/UploadContentPage";
import {
  HashRouter as Router,
  Route,
  Routes as RouterRoutes,
} from "react-router-dom";
import SearchPage from "./Pages/Search/SearchPage";
import { Navigate } from "react-router";
import PhonePage from "./Pages/PhonePage";
import YaronLecturesPage from "./Pages/YaronLecturesPage";

export default function Routes() {
  return (
    <Router>
      <App>
        <RouterRoutes>
          <Route path={Path.Search} element={<SearchPage />} />
          <Route path={Path.Search + "/:q"} element={<SearchPage />} />
          <Route path={Path.Order} element={<OrderPage />} />
          <Route path={Path.Order + "/:orderId"} element={<OrderPage />} />
          <Route
            path={Path.Order + "/forOrg/:organizationId"}
            element={<OrderPage />}
          />
          <Route path={Path.Phone} element={<PhonePage />} />
          <Route path={Path.PublicCourse} element={<PublicCourse />} />
          <Route
            path={Path.PublicCourse + "/:courseId"}
            element={<PublicCourse />}
          />
          <Route path={Path.Organization} element={<OrganizationPage />} />
          <Route
            path={Path.Organization + "/:organizationId"}
            element={<OrganizationPage />}
          />
          <Route path={Path.ActionRequired} element={<ActionRequiredPage />} />
          <Route
            path={Path.ActionRequired + "/:filters"}
            element={<ActionRequiredPage />}
          />
          <Route path={Path.WaitingPayment} element={<WaitingPaymentPage />} />
          <Route path={Path.ExpectedIncome} element={<ExpectedIncomePage />} />
          <Route path={Path.FutureLectures} element={<FutureLecturesPage />} />
          <Route path={Path.AllOrders} element={<AllOrdersPage />} />
          <Route
            path={Path.AllPublicCourses}
            element={<AllPublicCoursesPage />}
          />
          <Route path={Path.OfferTemplates} element={<OfferTemplatesPage />} />
          <Route path={Path.UploadFiles} element={<UploadFilesPage />} />
          <Route path={Path.Bi} element={<BiPage />} />
          <Route path={Path.Yaron} element={<YaronLecturesPage />} />

          <Route path="*" element={<Navigate to={Path.Search} />} />
        </RouterRoutes>
      </App>
    </Router>
  );
}
