import {
  Field,
  getFieldLabel,
} from "../../Store/Appearance/RequiredFields/FieldNames";
import DatePicker from "../DatePicker";
import { shouldMarkWithError } from "../../Store/Appearance/RequiredFields/RequiredFieldsUtil";
import { useContext } from "react";
import MobileViewContext from "../../Navigation/MobileViewContext";
import PhoneField from "./PhoneField";

interface CustomDatePickerProps {
  field: Field;
  valueObject: any;
  requiredFields?: Field[];
  onChange: (newValue: string) => void;
  fullWidth?: boolean;
}

export default function CustomDatePicker(props: CustomDatePickerProps) {
  const value = props.valueObject[props.field];
  const error = shouldMarkWithError(props.field, props.requiredFields, value);
  const [mobile] = useContext(MobileViewContext);

  if (mobile) {
    return <PhoneField field={props.field} valueObject={props.valueObject} />;
  }

  return (
    <DatePicker
      title={getFieldLabel(props.field)}
      value={value}
      onChange={props.onChange}
      error={error}
      fullWidth={props.fullWidth}
    />
  );
}
