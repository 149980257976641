import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { textFieldStyles } from "./TextField";
import Box from "@mui/material/Box";

export interface ISuggestion {
  key: string;
  label: string;
  icon?: React.ReactNode;
}

interface AutoCompleteProps<Suggestion extends ISuggestion> {
  suggestions: Suggestion[];
  textFieldValue: string;
  onTextChange: (newText: string) => void;
  onSuggestionSelected: (selected: Suggestion | null) => void; // Returns the suggestion selected (object)
  title?: string;
  hintText?: string;
  error?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  maxSearchResults?: number;
  width?: number | string;
}

export default function AutoSuggest<Suggestion extends ISuggestion>(
  props: AutoCompleteProps<Suggestion>
) {
  const onSuggestionSelected = (suggestion: Suggestion) => {
    props.onSuggestionSelected(suggestion);
    props.onTextChange(suggestion.label);
  };

  return (
    <Autocomplete
      style={{
        marginLeft: 20,
      }}
      fullWidth={props.fullWidth}
      options={props.suggestions}
      sx={{ width: props.width }}
      onChange={(_, newValue) => onSuggestionSelected(newValue)}
      isOptionEqualToValue={(option, value) => option.key === value.key}
      renderOption={(props, suggestion) => (
        <Box component="li" {...props} key={suggestion.key}>
          {suggestion.icon}
          {suggestion.label}
        </Box>
      )}
      inputValue={props.textFieldValue}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={(e) => props.onTextChange(e.target.value)}
          variant="standard"
          style={textFieldStyles}
          label={props.hintText}
          helperText={props.title}
          error={props.error}
        />
      )}
      disabled={props.disabled}
      disableClearable
    />
  );
}

export function toSuggestions(suggestions: string[]): ISuggestion[] {
  return suggestions.map((label) => ({
    label,
    key: label,
  }));
}
