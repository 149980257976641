import * as actionTypes from "./ActionTypes";
import { LOGGED_OUT } from "../Firebase/ActionTypes";
import { createImmutable } from "../../Util/ObjectUpdater";
import ILists from "../../Interfaces/ILists";

const initialState: ILists = {
  offeredLectures: {},
  cancellationReasons: {},
  rejectionReasons: {},
  offerTemplates: {},
  referralWays: {},
  ties: {},
};

export default (state = createImmutable(initialState), action: any = {}) => {
  switch (action.type) {
    case actionTypes.RECEIVE_LISTS:
      return createImmutable(action.payload);

    case LOGGED_OUT:
      return createImmutable(initialState);

    default:
      return state;
  }
};
